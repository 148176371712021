import fontSizes from './fontSizes';
import spaces from './spaces';
import colors from './colors';

const theme = {
  breakpoints: ['768px', '1024px', '1200px'],
  fontSizes,
  space: spaces,
  colors,
};

export default theme;
