import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import { COLOR, QUESTION_STATUS } from '../../common/const'
import { hackTestName } from '../../common/util'
import DifficultyTag from '../common/DifficultyTag'
import DoModal from '../practice/DoModal'
import Flex from '../base/styled/Flex'
import Bookmark from '../common/Bookmark'
import Table from './Table'

class ProblemTable extends Component {
  static propTypes = {
    problems: PropTypes.array.isRequired,
    topicLevel: PropTypes.number
  }

  static defaultProps = {
    topicLevel: 1
  }

  state = {
    selectedQuestion: null,
  }

  columns = [
    {
      header: 'สถานะ',
      accessor: 'status',
      width: 0.5,
      render: status => <Flex center alignCenter >
        <StatusIcon status={status} />
      </Flex>
    },
    {
      header: 'บุ๊คมาร์ก',
      accessor: 'isBookmarked',
      width: 0.5,
      render: (_, row) => <Flex center alignCenter >
          <Bookmark questionId={row.id} compact />
      </Flex>
    },
    {
      header: '#',
      accessor: 'mergedIndex',
      width: 0.5,
      center: true
    },
    {
      header: 'ชุดแนวข้อสอบ',
      accessor: 'exam',
      width: 2,
      render: (testName, _) => hackTestName(testName[0])
    },
    {
      header: 'เนื้อหาที่เกี่ยวข้อง',
      accessor: 'category',
      render: topics => <TopicsString topics={topics} level={this.props.topicLevel} />,
      width: 2.5,
    },
    {
      header: 'ความยาก',
      accessor: 'difficulty',
      render: difficultyId => <DifficultyTag difficultyId={difficultyId} />,
      width: 1.5
    },
  ]

  handleSelectProblem = selectedQuestion => {
    this.setState({ selectedQuestion })
    this.refs.contentModal.getWrappedInstance().openModal()
  }

  render() {
    const { problems, itemsPerPage } = this.props
    
    // TODO: too hacky.
    const selectedQuestion = this.state.selectedQuestion || { exam: [], index: 0 }     
    return <div>
      <Table 
        columns={this.columns}
        values={problems}
        rowClickCallback={q => this.handleSelectProblem(q)}
        itemsPerPage={itemsPerPage}
      />
      <DoModal
        ref="contentModal"
        questionId={selectedQuestion.id}
        questionIndex={selectedQuestion.index - 1}
        testName={hackTestName(selectedQuestion.exam[0])}
      />
    </div>
  }
}

export default ProblemTable

const StatusIcon = ({ status }) => {
  const icon = status === QUESTION_STATUS.SOLVED ? 'fa fa-check-circle'
    : status === QUESTION_STATUS.ATTEMPTED ? 'fa fa-times-circle'
    : 'fa circle-o'

  const color = status === QUESTION_STATUS.SOLVED ? COLOR.GREEN
    : status === QUESTION_STATUS.ATTEMPTED ? COLOR.RED
    : COLOR.LIGHT_GREY

  return <i 
    className={icon}
    style={{ 
      color,
      fontSize: '1.125rem',
      margin: 0,
      border: 0
    }}
  />
}

const TopicsString = ({ topics, level }) => {
  return <div 
    style={{ 
      whiteSpace: 'nowrap', 
      overflow: 'hidden', 
      textOverflow: 'ellipsis' 
    }}
  >
    {topics
      .filter(t => t.level === level)
      .map((t, index) => {
        const url = `/select-topics/${t.id}`
        return <span key={index}>
          {index === 0 ? '' : <br />}{level === 1 ? <Link to={url}>{t.name}</Link> : t.name}
        </span>
      })
    }
  </div>
}
