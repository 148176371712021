import React, { Component } from 'react';

import { inModal } from '../../hoc/inModal';
import { ModalTitle } from './Modal';
import { Title } from '../base/styled/Text';
import { Button, Flex } from '../base';

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.onConfirm = this.onConfirm.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  onConfirm() {
    const { onConfirm, closeModal } = this.props;
    if (onConfirm) {
      onConfirm();
    }

    closeModal();
  }

  onCancel() {
    const { onCancel, closeModal } = this.props;
    if (onCancel) {
      onCancel();
    }

    closeModal();
  }

  render() {
    const { children, closeModal, onConfirm, onCancel, title, height } = this.props;
    const innerHeight = height ? height : '350px';
    return (
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        style={{ minHeight: innerHeight }}
      >
        <ModalTitle title={title} closeButton={() => closeModal()} />
        <Title className="h-100 hp-mt-1" as={'div'}>
          {children}
        </Title>
        <Flex justifyContent={'flex-end'} alignItems={'center'} className="hp-mt-a">
          <Button.Secondary.Flat className="hp-mr-05" onClick={this.onCancel}>
            ยกเลิก
          </Button.Secondary.Flat>
          <Button.Primary.Default onClick={this.onConfirm}>ยืนยัน</Button.Primary.Default>
        </Flex>
      </Flex>
    );
  }
}

ConfirmModal.propTypes = {};

export default inModal(ConfirmModal);
