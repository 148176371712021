import React from 'react';
import styled from 'styled-components';

import { Button, Icon, Modal, Text, Flex } from './base';
import apiClient from '../api';
import { AnalyticContext } from '../contexts/AnalyticContext';

const FeedbackArea = styled('textarea')`
  border: 1px solid ${({ theme }) => theme.colors.greys.grey4};
  border-radius: 8px;
  min-height: 120px;
  box-shadow: none;
  padding: 24px;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 32px;
  transition: border-color 0.5s;

  &::placeholder {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.greys.grey2};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.greys.grey1} !important;
  }
`;

const QuestionFeedbackModal = ({ open, onClose, onSubmitFeedback, questionName, location }) => {
  const [message, setMessage] = React.useState('');

  const { logAction } = React.useContext(AnalyticContext);

  const handleChange = (e) => {
    logAction({
      category: 'Feedback',
      action: 'Type',
      label: `location: ${location === 'MODAL' ? 'Single Question' : 'Session'}`,
    });
    setMessage(e.target.value);
  };

  const handleSubmitFeeback = () => {
    logAction({
      category: 'Feedback',
      action: 'Submit',
      label: `location: ${location === 'MODAL' ? 'Single Question' : 'Session'}`,
    });
    onSubmitFeedback(message);
  };

  return (
    <Modal open={open} onClose={onClose} size={'xs'}>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Text.Title.Md width={'100%'} mb={1}>
          รายงานปัญหา
        </Text.Title.Md>
        <Text.Subtitle.Md mb={4} width={'100%'}>
          {questionName}
        </Text.Subtitle.Md>
        <FeedbackArea onChange={handleChange} placeholder="รายละเอียดปัญหา" value={message} />
        <Button.Primary.Main width={'120px'} onClick={handleSubmitFeeback}>
          ส่ง
        </Button.Primary.Main>
      </Flex>
    </Modal>
  );
};
const QuestionFeedbackButton = ({ questionId, questionName, text, location }) => {
  const [modalOpen, setModalOpen] = React.useState(false);

  const { logAction } = React.useContext(AnalyticContext);

  const handleOpenModal = () => {
    setModalOpen(true);
    logAction({
      category: 'Feedback',
      action: 'Open Modal',
      label: `location: ${location === 'MODAL' ? 'Single Question' : 'Session'}`,
    });
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    logAction({
      category: 'Feedback',
      action: 'Close Modal',
      label: `location: ${location === 'MODAL' ? 'Single Question' : 'Session'}`,
    });
  };

  const handleSubmitFeeback = async (message) => {
    await apiClient.other.postQuestionFeedback(questionId, message);
    handleCloseModal();
  };

  return (
    <>
      <Button.Secondary.Text onClick={handleOpenModal}>
        <Icon name={Icon.ICON_LIST.QUESTION_CIRCLE} mr={1} />
        {text}
      </Button.Secondary.Text>
      <QuestionFeedbackModal
        open={modalOpen}
        onClose={handleCloseModal}
        onSubmitFeedback={handleSubmitFeeback}
        questionName={questionName}
        location={location}
      />
    </>
  );
};

QuestionFeedbackButton.defaultProps = {
  text: 'รายงานปัญหาโจทย์ข้อนี้',
};
export default QuestionFeedbackButton;
