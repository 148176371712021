import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import _ from 'lodash';

import Loading from '../../common/Loading';
import ProblemTextInput from '../../practice/ProblemTextInput';
import { Flex, Box, Tabs, Button, Icon } from '../../base';

import { getOnlyNumeric } from '../../../common/util';
import config from '../../../common/config';
import { AnalyticContext } from '../../../contexts/AnalyticContext';

import Header from './components/Header';
import Solution from './components/Solution';
import ProblemBox from './components/ProblemBox';
import ChoiceBox from './components/ChoiceBox';

const ChoiceContainer = styled(Flex)`
  @media (min-width: 992px) {
    min-width: 65%;
    max-width: 80%;
    padding: 0;
  }
  @media (max-width: 991px) {
    min-width: 80% !important;
    max-width: 100% !important;
    padding: 0;
  }
  @media (max-width: 767px) {
    min-width: 80% !important;
    max-width: 100% !important;
    padding: 0;
  }
  @media (max-width: 575px) {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 0;
  }
  margin: 2rem 0 1rem;
`;

const TABS_OPTIONS = {
  PROBLEM: 'PROBLEM',
  SOLUTION: 'SOLUTION',
};
const SingleProblem = ({
  questionId,
  questionText,
  choices,
  index,
  selectChoice,
  selectedChoiceId,
  completed,
  isCorrect,
  answerKey,
  mainButton,
  type,
  alwaysShowSolution,
  user,
  questionName,
  onBookmark,
  locationType,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(TABS_OPTIONS.PROBLEM);

  const { logAction } = React.useContext(AnalyticContext);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
    logAction({
      category: locationType === 'MODAL' ? 'Single Question' : 'Session',
      action: 'Change Tab',
      label: `Tab: ${tab}`,
    });
  };

  const handleSelectChoice = (index, id) => {
    selectChoice(index, id);
    logAction({
      category: locationType === 'MODAL' ? 'Single Question' : 'Session',
      action: !selectedChoiceId ? 'Select Choice' : 'Change Choice',
      label: `Question: ${questionName}`,
    });
  };

  const handleChangeInputValue = (index, value) => {
    selectChoice(index, value);
    if (!selectedChoiceId) {
      logAction({
        category: locationType === 'MODAL' ? 'Single Question' : 'Session',
        action: 'Type Answer',
        label: `Question: ${questionName}`,
      });
    }
  };

  const handleClickSeeSolutionButton = (tab) => {
    handleSelectTab(tab);
    logAction({
      category: 'Single Question',
      action: 'See Solution',
      label: `Question: ${questionName}`,
    });
  };

  const handleBookmark = () => {
    logAction({
      category: locationType === 'MODAL' ? 'Single Question' : 'Session',
      action: 'Bookmark',
      label: `Question: ${questionName}`,
    });
  };

  const handleUnbookmark = () => {
    logAction({
      category: locationType === 'MODAL' ? 'Single Question' : 'Session',
      action: 'Unbookmark',
      label: `Question: ${questionName}`,
    });
  };

  if (!questionText || !choices) {
    return <Loading />;
  }
  const disabledSolution = !completed && !alwaysShowSolution;
  const showSolutionButton = completed && alwaysShowSolution;
  return (
    <Box width={'100%'}>
      <Header
        questionName={questionName}
        questionId={questionId}
        onBookmark={handleBookmark}
        onUnbookmark={handleUnbookmark}
      />

      <Tabs my={4}>
        <Tabs.Item
          selected={selectedTab === TABS_OPTIONS.PROBLEM}
          onClick={() => handleSelectTab(TABS_OPTIONS.PROBLEM)}
        >
          <Icon name={Icon.ICON_LIST.DO_PROBLEM} size={20} mr={1} />
          โจทย์
        </Tabs.Item>
        <Tabs.Item
          selected={selectedTab === TABS_OPTIONS.SOLUTION}
          onClick={() => handleSelectTab(TABS_OPTIONS.SOLUTION)}
          disabled={disabledSolution}
        >
          <Icon name={Icon.ICON_LIST.SEE_SOLUTION} size={20} mr={1} />
          ดูเฉลย/จดโน้ต
          {disabledSolution ? (
            <ReactTooltip id="solution" place="top" type="error">
              ส่งคำตอบก่อนนะ
            </ReactTooltip>
          ) : null}
        </Tabs.Item>
      </Tabs>

      <Flex
        flexDirection={'column'}
        alignItems={'center'}
        display={selectedTab === TABS_OPTIONS.PROBLEM ? 'flex' : 'none'}
      >
        <ProblemBox content={questionText} />
        <ChoiceContainer flexDirection={'column'} widthAuto>
          {type === 0 ? (
            choices.map((c, choiceIndex) => {
              return (
                <ChoiceBox
                  key={choiceIndex}
                  index={`${choiceIndex + 1}.`}
                  choiceId={c.id}
                  content={c.text}
                  onClick={() => handleSelectChoice(index, c.id)}
                  selectedChoiceId={selectedChoiceId}
                  completed={completed}
                  isCorrect={isCorrect}
                  answerKey={answerKey}
                />
              );
            })
          ) : (
            <ProblemTextInput
              answer={selectedChoiceId ? selectedChoiceId : ''}
              onChange={(e) => {
                const value = getOnlyNumeric(e.target.value);
                handleChangeInputValue(index, value);
              }}
              isCorrect={isCorrect}
              answerKey={answerKey}
            />
          )}
        </ChoiceContainer>
        <Flex justifyContent={'center'} width={'100%'}>
          {showSolutionButton && (
            <Button.Primary.Main
              onClick={() => handleClickSeeSolutionButton(TABS_OPTIONS.SOLUTION)}
              mr={2}
            >
              ดูเฉลย
            </Button.Primary.Main>
          )}
          {mainButton}
        </Flex>
      </Flex>

      <Box display={selectedTab === TABS_OPTIONS.SOLUTION ? 'block' : 'none'}>
        <Solution isPremiumUser={user.is_premium} questionId={questionId} config={config} />
      </Box>
    </Box>
  );
};

SingleProblem.propTypes = {
  questionId: PropTypes.string,
  questionText: PropTypes.string,
  choices: PropTypes.array,
  index: PropTypes.number,
  selectedChoiceId: PropTypes.string,
  completed: PropTypes.bool,
  // If completed.
  isCorrect: PropTypes.bool,
  answerKey: PropTypes.string,
  solution: PropTypes.object,
  selectChoice: PropTypes.func,
  mainButton: PropTypes.node,
  type: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    questionStat: state.stat.userQuestionStat,
    user: state.user.userInfo,
  };
}

export default connect(mapStateToProps, null, null, { withRef: true })(SingleProblem);
