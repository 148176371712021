import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';

import { startTest, setSessionInfo } from '../../../actions/solve';
import { SESSION_TYPE_TEST } from '../../../common/const';
import apiClient from '../../../api';

const StartSessionContainer = ({
  children,
  testId,
  setSessionInfo,
  startTest,
  sessionInfo,
  onStartSession,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [testInfo, setTestInfo] = React.useState(null);

  React.useEffect(() => {
    const getTestInfo = async () => {
      const [testInfo, testResult] = await Promise.all([
        apiClient.test.getTestInfoById(testId),
        apiClient.stat.getTestResultById(testId),
      ]);

      const highScore = testResult ? `${testResult.highscore}/${testResult.fullScore}` : null;
      testInfo.highScore = highScore;
      const { name, duration, questionCount } = testInfo;
      setSessionInfo({
        type: SESSION_TYPE_TEST,
        testId: testId,
        title: name,
        questionCount,
        duration,
      });
      setTestInfo(testInfo);
    };
    if (testId) {
      getTestInfo();
    }
  }, [testId]);

  React.useEffect(() => {
    if (sessionInfo && testInfo) {
      setLoading(false);
    }
  });

  const handleStartSession = async (id) => {
    await startTest(testId, testInfo.name);
    onStartSession(id, testInfo.name);
    setLoading(false);
    browserHistory.push('/session');
  };

  return children({ handleStartSession, loading, testInfo });
};

function mapStateToProps(state) {
  return {
    session: state.data.session,
    sessionInfo: state.data.sessionInfo,
  };
}

export default connect(mapStateToProps, { startTest, setSessionInfo })(StartSessionContainer);
