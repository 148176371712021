import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../common/const'
import Text from '../../base/styled/Text'
import Flex from '../../base/styled/Flex'
import Bubble from '../../common/Bubble'

const ProblemBubble = ({ status, index, current, setProblemIndex }) => {
    const style = {
        marginTop: "0.5rem", 
        cursor: "pointer"
    }

    if (current) {
        style.backgroundColor = COLOR.LIGHT_GREY
    }

    return (
        <_Flex 
            col 
            center
            alignCenter 
            style={ style }
            onClick={ () => setProblemIndex(index) }
        >
            <Text small bold>{ index + 1 }</Text>
            <Bubble status={ status } size="18px" />
        </_Flex>
    )
}

ProblemBubble.propTypes = {
    status: PropTypes.number,
    index: PropTypes.number,
    setProblemIndex: PropTypes.func
}

export default ProblemBubble

const _Flex = styled(Flex)`
    @media (max-width: 991px) {
        min-width: 48px;
        min-height: 48px;
    }
`