import React from 'react';

import apiClient from '../../api';
import { store } from '../../index';

const SubjectTestDetailContainer = ({ children, router, ...restProps }) => {
  const [testInfo, setTestInfo] = React.useState(null);
  const [questions, setQuestions] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { testId } = router.params;

  React.useEffect(() => {
    const getData = async () => {
      const [testInfo, questions, bookmarks] = await Promise.all([
        apiClient.test.getTestInfoById(testId),
        apiClient.test.getTestQuestionsById(testId),
        apiClient.other.getBookmarks(),
      ]);
      const formattedQuestions = questions.map((question) => {
        const { topics, id, ...questionInfo } = question;
        const { name } = topics[0] || {};
        const bookmarkInfo = bookmarks.find((bookmark) => bookmark.question === id);
        const isBookmarked = bookmarkInfo !== undefined;
        return { topic: name, id, bookmarked: isBookmarked, ...questionInfo };
      });
      setTestInfo(testInfo);
      setQuestions(formattedQuestions);
      setLoading(false);
    };
    getData();
  }, []);

  const updateBookmarks = () => {
    let bookmarks = store.getState().bookmark.bookmarks
    bookmarks = bookmarks.reduce((agg, b) => {
      agg[b] = true;
      return agg;
    }, {})
    const updatedQuestions = questions.map(q => {
      q.bookmarked = (q.id in bookmarks)
      return q
    })
    setQuestions(updatedQuestions)
  }

  return children({
    loading,
    testInfo,
    questions,
    handleBookmark: updateBookmarks
    // handleSelectQuestion,
    // handleStartSession,
  });
};

export default SubjectTestDetailContainer;
