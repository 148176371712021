import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import List from '../../common/list'
import TableBase, { NO_FILTER_OPTION } from './TableBase'

export default class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
        origValues: new List(),
        page: 0,
        sortKey: null,
        sortOrder: false, // true, ascending, false = descending
        filters: [],
        itemsPerPage: 50
    }

    this.setFilter = this.setFilter.bind(this)
    this.selectPage = this.selectPage.bind(this)
    this.sortColumn = this.sortColumn.bind(this)
    this.setItemsPerPage = this.setItemsPerPage.bind(this)
    this.getFiltersWithOptions = this.getFiltersWithOptions.bind(this)
  }

  componentWillMount() {
      this.setState({
          origValues: new List(this.props.values),
          filtersWithOptions: this.getFiltersWithOptions(this.props.columns, this.props.values),
          itemsPerPage: this.props.itemsPerPage || this.state.itemsPerPage
      })
  }

  componentWillReceiveProps(nextProps) {
      this.setState({
          origValues: new List(nextProps.values),
          filtersWithOptions: this.getFiltersWithOptions(nextProps.columns, nextProps.values),
          itemsPerPage: this.props.itemsPerPage || this.state.itemsPerPage
      })
  }

  sortColumn(key) {
      this.setState({
          sortKey: key,
          sortOrder: key !== this.state.sortKey ? true : !this.state.sortOrder,
          page: 0
      })
  }

  setFilter(key) {
      const filters = this.state.filters
      const value = this.refs.tableBase.refs[`filter-${ key }`].value
      _.remove(filters, f => {
          return f.key === key
      })

      // Value got changed to string with <input>, so we need to stringify it.
      if (value !== `${NO_FILTER_OPTION.value}`) {
          filters.push({
              key: key,
              value: value
          })
      }

      this.setState({
          filters: filters
      })
  }

selectPage(page) {
  this.setState({
    page: page
  })
}

setItemsPerPage(itemsPerPage) {
  this.setState({
    itemsPerPage: parseInt(itemsPerPage, 10),
    page: 0
  })
}

  getFiltersWithOptions(columns, values) {
      // Create a dict of uniqe options.
      const filterableColumns = _.filter(columns, { filterable: true })
      let options = filterableColumns.reduce((acc, c) => {
          acc[c.accessor] = new Set()
          return acc
      }, {})

      values.map(v => {
          return filterableColumns.map(c => {
              const key = c.accessor
              options[key].add(v[key])

              return key
          })
      })

      const filtersWithOptions = filterableColumns.map(c => {
          const columnOptions = _.sortBy([...options[c.accessor]]).map(value => {
              return {
                  label: value,
                  value: value
              }
          })

          return {
              key: c.accessor,
              options: [NO_FILTER_OPTION, ...columnOptions]
          }
      })

      return filtersWithOptions
  }

  render() {
      const { 
          columns, 
          rowClickCallback, 
          noColumnHeader, 
          noHeader, 
          itemsPerPage,
          noItemsPerPageSelector
        } = this.props

      // Get filtered and sorted values.
      let values = this.state.origValues
      if (!_.isEmpty(this.state.filters)) {
          values = values.filter(this.state.filters)
      }

      if (this.state.sortKey) {
          values = values.sort(this.state.sortKey, this.state.sortOrder)
      }

      // Total length should be after filtered, but before paginated.
      const length = values.items.length
      values = values.paginate(this.state.itemsPerPage, this.state.page)

      return <TableBase 
          ref="tableBase"
          columns={ columns }
          values={ values }
          page={ this.state.page }
          length={ length }
          sortColumn={ this.sortColumn }
          setFilter={ this.setFilter }
          setItemsPerPage={ this.setItemsPerPage } 
          selectPage={ this.selectPage }
          rowClickCallback={ rowClickCallback }
          itemsPerPage={ itemsPerPage || this.state.itemsPerPage }
          filtersWithOptions={ this.state.filtersWithOptions }
          noColumnHeader={ noColumnHeader }
          noHeader={ noHeader }
          sortKey={ this.state.sortKey }
          sortOrder={ this.state.sortOrder }
          noItemsPerPageSelector={ noItemsPerPageSelector }
      />
  }
}

Table.propTypes = {
    columns: PropTypes.array.isRequired,
    values: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number,
    rowClickCallback: PropTypes.func,
    noColumnHeader: PropTypes.bool,
    noHeader: PropTypes.bool,
}
