import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../../../common/const';
import IconBase from './IconBase';

const ActionIcon = styled(IconBase)`
  color: ${props => (props.active ? COLOR.ORANGE : '')};
  transition: ${props => (props.noTransition ? '0s' : '0.2s')};
  cursor: pointer;

  &:hover {
    color: ${props => (props.active ? '' : props.hippoColor ? COLOR.LIGHT_HIPPO : COLOR.GREY)};
  }

  &:active {
    color: ${props => (props.active ? '' : props.hippoColor ? COLOR.DARK_HIPPO : COLOR.DARK_GREY)};
  }
`;

export default ActionIcon;

ActionIcon.propTypes = {
  hippoColor: PropTypes.string, // Change Icon effect into hippo color theme
};
