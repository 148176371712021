import styled from 'styled-components';

import Box from './Box';

const Divider = styled(Box)``;

Divider.defaultProps = {
  borderBottom: '1px solid',
  borderColor: 'black',
  my: 4,
};

export default Divider;
