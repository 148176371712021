import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, MathJaxBox } from '../../../base';
import Choice from './Choice';

const ChoiceText = styled(Text)`
  font-size: 16px;
  font-weight: 400;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 18px;
  }
`;
ChoiceText.defaultProps = {
  readable: true,
};
const MathJaxChoice = ({ content }) => {
  return (
    <ChoiceText>
      <MathJaxBox content={content} />
    </ChoiceText>
  );
};

const ChoiceBox = ({
  choiceId,
  selectedChoiceId,
  completed,
  isCorrect,
  answerKey,
  content,
  ...others
}) => {
  if (completed) {
    return isCorrect && choiceId === selectedChoiceId ? (
      <Choice correct content={<MathJaxChoice content={content} />} {...others} />
    ) : !isCorrect && choiceId === selectedChoiceId ? (
      <Choice wrong content={<MathJaxChoice content={content} />} {...others} />
    ) : !isCorrect && choiceId === answerKey ? (
      <Choice rightanswer content={<MathJaxChoice content={content} />} {...others} />
    ) : (
      <Choice disabled content={<MathJaxChoice content={content} />} {...others} />
    );
  }

  return choiceId === selectedChoiceId ? (
    <Choice selected content={<MathJaxChoice content={content} />} {...others} />
  ) : (
    <Choice content={<MathJaxChoice content={content} />} {...others} />
  );
};

ChoiceBox.propTypes = {
  choiceId: PropTypes.string,
  selectedChoiceId: PropTypes.string,
  completed: PropTypes.bool,
  isCorrect: PropTypes.bool,
  answerKey: PropTypes.string,
};

export default ChoiceBox;
