import React from 'react'
import styled from 'styled-components'

import ActionIcon from './ActionIcon'

const CloseIcon = styled(ActionIcon)`
    font-family: FontAwesome;
    content: \f00d;
`

export default CloseIcon