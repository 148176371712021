import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

import apiClient from '../api';
import { LOGIN_MODE } from '../common/const';
import MESSAGES from '../common/messages';

import { Button, Card, Flex, Box, Text, AppContainer, Logo } from '../components/base';
import { setToken } from '../reducers/auth';

const InputSpacer = () => {
  return <Box pt={'24px'} />;
};
const userTypeOptions = [
  { value: '0', label: 'นักเรียน' },
  { value: '1', label: 'ครู' },
  { value: '2', label: 'ติวเตอร์' },
  { value: '3', label: 'ผู้ปกครอง' },
  { value: '4', label: 'อื่นๆ' },
];

const gradeOptions = [
  { value: '12', label: 'ม.6' },
  { value: '11', label: 'ม.5' },
  { value: '10', label: 'ม.4' },
  { value: '9', label: 'ม.3' },
  { value: '8', label: 'ม.2' },
  { value: '7', label: 'ม.1' },
  { value: '0', label: 'อื่นๆ' },
];

const translateError = (error) => {
  switch (error) {
    case 'student with this email already exists.':
      return MESSAGES.EXISTING_EMAIL;
    case 'This password is too short. It must contain at least 8 characters.':
      return MESSAGES.PASSWORD_TOO_SHORT;
    default:
      return error;
  }
};

const SignUpView = ({ setToken, router }) => {
  const [firstname, setFirstname] = React.useState('');
  const [lastname, setLastname] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [userType, setUserType] = React.useState('');
  const [grade, setGrade] = React.useState('');

  const [activateGradeField, setActivateGradeField] = React.useState(false);

  const [formActive, setFormActive] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [errors, setErrors] = React.useState(null);
  const [readyToSubmit, setReadyToSubmit] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);

  React.useEffect(() => {
    if (userType === '0' && !activateGradeField) {
      setActivateGradeField(true);
    } else if (userType !== '0') {
      setActivateGradeField(false);
      setGrade('');
    }

    const arr = activateGradeField
      ? [firstname, lastname, email, password, userType, grade]
      : [firstname, lastname, email, password, userType];

    setErrors(null);
    if (formActive && arr.some((e) => e === '')) {
      setError('กรุณากรอกข้อมูลให้ครบ');
    } else {
      setError(null);
      setReadyToSubmit(true);
    }
  }, [firstname, email, password, userType, lastname, grade]);

  const handleChange = (e) => {
    const { value, name } = e.target;
    switch (name) {
      case 'firstname':
        setFirstname(value);
        break;
      case 'lastname':
        setLastname(value);
        break;
      case 'email':
        setEmail(value);
        break;
      case 'password':
        setPassword(value);
        break;
      case 'userType':
        setUserType(value);
        break;
      case 'grade':
        setGrade(value);
      default:
    }

    if (!formActive) {
      setFormActive(true);
    }
  };

  const handleSubmit = () => {
    setSubmitting(true);
    setErrors(null);

    apiClient.student
      .createStudent(email, password, firstname, lastname, userType, grade)
      .then((res) => {
        if (res.success) {
          // TODO: We should not have to call getToken again after create student.
          // The API should return the token with the original request.
          apiClient.auth.getToken({ email, password }, LOGIN_MODE.EMAIL).then((res) => {
            if (res.errorCode) {
              setError(MESSAGES.INTERNAL_SERVER_ERROR);
              setSubmitting(false);
            } else {
              setToken(res.token);
              _.delay(() => router.push('/'), 500);
              setSubmitting(false);
            }
          });
        } else {
          setErrors(res);
          setReadyToSubmit(false);
          setSubmitting(false);
        }
      });
  };

  return (
    <AppContainer>
      <Card maxWidth={'480px'} mx={'auto'}>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <Logo variant={Logo.VARIANTS.SQUARE} width={'72px'} mb={4} />
          <Text.Title.Md alignItems={'center'} mb={4}>
            ลงทะเบียน
          </Text.Title.Md>
          {error && (
            <Text.Paragraph
              light={true}
              color={'danger'}
              width={'100%'}
              textAlign={'center'}
              height={'0px'}
              style={{ transform: 'translateY(-12px)' }}
            >
              {error}
            </Text.Paragraph>
          )}
          <InputSpacer />
          <form noValidate autoComplete="off" style={{ width: '100%' }}>
            <Flex flexDirection={'column'} alignItems={'stretch'} width={'100%'} px={3}>
              <TextField
                name="email"
                label="อีเมล"
                type="email"
                autoComplete="email"
                required
                variant={'outlined'}
                onChange={handleChange}
                value={email}
                error={errors && Boolean(errors.email)}
                helperText={errors && errors.email && translateError(errors.email[0])}
              />
              <InputSpacer />
              <TextField
                name="password"
                label="รหัสผ่าน"
                type="password"
                autoComplete="current-password"
                required
                variant={'outlined'}
                onChange={handleChange}
                value={password}
                error={errors && Boolean(errors.password)}
                helperText={errors && errors.password && translateError(errors.password[0])}
              />
              <InputSpacer />
              <InputSpacer />
              <TextField
                name="firstname"
                label="ชื่อ"
                autoComplete="given-name"
                required
                variant={'outlined'}
                onChange={handleChange}
                value={firstname}
                error={errors && Boolean(errors.firstname)}
                helperText={errors && errors.firstname && translateError(errors.firstname[0])}
              />
              <InputSpacer />
              <TextField
                name="lastname"
                label="นามสกุล"
                autoComplete="family-name"
                required
                variant={'outlined'}
                onChange={handleChange}
                value={lastname}
                error={errors && Boolean(errors.firstname)}
                helperText={errors && errors.firstname && translateError(errors.firstname[0])}
              />
              <InputSpacer />
              <TextField
                name="userType"
                select
                label="อาชีพ"
                value={userType}
                onChange={handleChange}
                helperText="เลือกอาชีพที่ตรงกับคุณมากที่สุด"
                required
                variant={'outlined'}
                error={errors && Boolean(errors.userType)}
                helperText={errors && errors.firstname && translateError(errors.userType[0])}
              >
                {userTypeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <InputSpacer />
              {activateGradeField ? (
                <>
                  <TextField
                    name="grade"
                    select
                    label="ระดับชั้น"
                    value={grade}
                    onChange={handleChange}
                    helperText={''}
                    required
                    variant={'outlined'}
                    error={errors && Boolean(errors.userType)}
                    helperText={errors && errors.firstname && translateError(errors.userType[0])}
                  >
                    {gradeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <InputSpacer />
                </>
              ) : null}

              <Flex flexDirection={'column'} alignItems={'center'} mt={4}>
                <Button.Primary.Main
                  disabled={!readyToSubmit}
                  loading={submitting}
                  onClick={handleSubmit}
                >
                  ลงทะเบียน
                </Button.Primary.Main>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Card>
    </AppContainer>
  );
};

export default connect(null, { setToken })(SignUpView);
