export const BASE_API_URL = process.env.REACT_APP_MM_BASE_API_URL + '/api';

export const MARCHMELLOW_FACEBOOK_URL = 'https://www.facebook.com/March-Mellow-107778004484837';
export const HOSTNAME = 'march-mellow.com';

export const SESSION_TYPE_EXERCISE = 'SESSION_TYPE_EXERCISE';
export const SESSION_TYPE_TEST = 'SESSION_TYPE_TEST';

export const PROBLEM_STATUS_NEW = 'new'; // Both, new question that is not loaded to state.
export const PROBLEM_STATUS_PULLED = 'pulled'; // Both, the question is already loaded to state.
export const PROBLEM_STATUS_SELECTED = 'selected'; // Both, already select the answer but not submitted.
export const PROBLEM_STATUS_DONE = 'done'; // Exercise, already submitted.

export const QUESTION_TYPE = {
  MULTIPLE_CHOICE: 0,
  SHORT_ANSWER: 1,
};

// HACK!! TODO: Get rid of this
export const DIFFICULTY_RANK_1 = 'd43e648d-a707-446f-a4ef-d2cc639cae78';
export const SEQUENCE_TOPIC_ID = 'b11b1f7e-894b-41d1-969a-8ef83b0eb1d3';

// TODO: This should be retrieved from server.
export const USER_TYPE = {
  STUDENT: 0,
  TEACHER: 1,
  GENERAL: 2,
};

export const QUESTION_STATUS = {
  SOLVED: 0,
  ATTEMPTED: 1,
  TODO: 2,
};

export const TEST_STATUS = {
  SOLVED: 0,
  ATTEMPTED: 1,
  TODO: 2,
};

export const SOLVE_MODE = {
  MASTERY: 0,
  EXERCISE: 1,
  TEST: 2,
  TEST_TIMER: 3,
};

export const LOGIN_MODE = {
  EMAIL: 'user',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
};

export const COLOR = {
  // HIPPO: '#5CC0C5',
  // DARK_HIPPO: '#2E8184',
  // TEST : '#F6AB60',
  // EXERCISE : '#2E80BA',

  HIPPO00: '#5CC0C5',
  HIPPO11: '#2E8184',
  HIPPO01: '#C7E9EB',

  // WHITE: 'white',
  // GREY : 'rgb(233, 233, 233)',
  // GREY11: '#757575',
  // GREY12: '#515151',
  // GREY01: '#E0E0E0',

  // GREEN : '#28B294',
  // DARK_GREEN : '#1C7D68',
  // RED : '#EB5768',
  // DARK_RED : '#B71528'

  HIPPO: '#00AFAF',
  DARK_HIPPO: '#008686',
  LIGHT_HIPPO: '#80D7D7',

  BLUE: '#0064AF',
  DARK_BLUE: '#003686',
  LIGHT_BLUE: '#4D93C7',

  GREEN: '#00AF64',
  DARK_GREEN: '#008636',
  LIGHT_GREEN: '#80D7B2',

  ORANGE: '#FAAF00',
  DARK_ORANGE: '#F89600',
  LIGHT_ORANGE: '#FDD780',

  PURPLE: '#9600FF',
  DARK_PURPLE: '#7900FF',
  LIGHT_PURPLE: '#E0B3FF',

  RED: '#D32F2F',
  DARK_RED: '#B71212',
  LIGHT_RED: '#E99797',

  WHITE: 'white',

  GREY: 'rgba(0,0,0,0.6)',
  DARK_GREY: 'rgba(0,0,0,0.8)',
  LIGHT_GREY: 'rgba(0,0,0,0.2)',
  LIGHTER_GREY: 'rgba(0,0,0,0.05)',

  GREYSCALE: {
    GREY1: 'rgba(0,0,0,0.8)',
    GREY2: 'rgba(0,0,0,0.6)',
    GREY3: 'rgba(0,0,0,0.4)',
    GREY4: 'rgba(0,0,0,0.2)',
    GREY5: 'rgba(0,0,0,0.05)',
  },
};

export const ModalStyle = {
  overlay: {
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(64,64,64,0.5)',
    zIndex: 100000,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    position: 'absolute',
    // top: 'initial',
    // right: 'initial',
    // down: 'initial',
    // left: 'initial',
    // bottom: 'initial',
    maxWidth: '100vw',
    width: '876px',
    maxHeight: '90vh',
    minHeight: '400px',
    // border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '16px',
    outline: 'none',
    padding: '25px',
  },
};

// From https://github.com/c0bra/color-scheme-js.
export const RANDOM_COLORS = [
  '#009999',
  '#006666',
  '#ff6600',
  '#cc3300',
  '#ffcccc',
  '#ffcc99',
  '#ffcc00',
  '#cc6600',
  '#99ff66',
  '#ffcc99',
  '#330099',
  '#000066',
  '#ccccff',
  '#9999ff',
  '#ffcc66',
  '#996633',
  '#ffcccc',
  '#cc9933',
  '#663399',
  '#663399',
  '#ccccff',
  '#6633cc',
  '#cc6699',
  '#993366',
  '#ffcccc',
  '#cc3399',
  '#ffffcc',
  '#669933',
  '#ccffcc',
  '#66cc33',
  '#ccffff',
  '#99ffff',
];
