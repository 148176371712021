import React, { Component } from 'react';

import { hackTestName } from '../../common/util';
import Table from './Table';

class TestTable extends Component {
  columns = [
    {
      header: '#',
      accessor: 'index',
      width: 0.3,
      center: true,
    },
    {
      header: 'ชุด',
      accessor: 'name',
      width: 2,
      render: (name, _) => hackTestName(name),
    },
    {
      header: 'คำอธิบาย',
      accessor: 'description',
      render: (_, row) => {
        // TODO: Clean this up.
        const name =
          row.tag[0] === 'onet'
            ? 'O-NET'
            : row.tag[0] === 'pat'
            ? 'PAT-1'
            : row.tag[0] === 'saman'
            ? row.name.includes('-1')
              ? 'วิชาสามัญ-1'
              : 'วิชาสามัญ-2'
            : '';
        return `แนวข้อสอบ ${name} ปี ${row.year}`;
      },
      width: 3,
    },
    {
      header: 'จำนวนข้อ',
      accessor: 'question_count',
      width: 1,
      center: true,
    },
    {
      header: 'คะแนนสูงสุด',
      accessor: 'score',
      width: 1,
      render: (_, row) => (row.score ? `${row.score}/${row.question_count}` : ''),
      center: true,
    },
  ];

  handleSelectTest = (test) => {
    this.props.push(`/select-tests/${test.id}`);
  };

  render() {
    const { tests } = this.props;
    return (
      <div>
        <Table
          columns={this.columns}
          values={tests}
          rowClickCallback={(t) => this.handleSelectTest(t)}
        />
      </div>
    );
  }
}

export default TestTable;
