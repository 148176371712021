import React from 'react';

import Flex from '../../components/base/styled/Flex';
import { getTopicById } from '../../common/util';
import Tag from '../base/styled/Tag';

const TopicTags = ({ topicIds }) => (
  <Flex widthAuto className={'hp-mb-05'}>
    {topicIds.reduce((acc, cid) => {
      const topic = getTopicById(cid, true);
      if (topic) {
        acc.push(
          <Tag className="text-overflow-1" small key={cid}>
            {topic.name}
          </Tag>
        );
      }
      return acc;
    }, [])}
  </Flex>
);

export default TopicTags;
