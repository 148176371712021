import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'

import Text from '../../base/styled/Text'
import * as styles from './AnswerSheetItemChoices.styles'

export default class AnswerSheetChoiceLabel extends Component {
    constructor(props) {
        super(props)
        this.renderLabel = this.renderLabel.bind(this)
    }

    renderLabel(index) {
        return (
            <div key={ index } className='col' style={ styles.bg(index) }>
                <Text small bold>{ String.fromCharCode('1'.charCodeAt(0)+index) }</Text>
            </div>
        )
    }

    render() {
        const { count } = this.props

        return (
            <div className='row no-gutters'>
              <div className='col-2'>
              </div>
              <div className='col-10'>
                <div className='row no-gutters'>
                  { _.range(count).map(this.renderLabel) }
                </div>
              </div>
            </div>
        )
    }
}

AnswerSheetChoiceLabel.propTypes = {
    count: PropTypes.number,
}