import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router';

import apiClient from '../../api';
import { COLOR } from '../../common/const';

import Card, { CardHeader } from '../../components/base/styled/Card';
import Text from '../../components/base/styled/Text';
import Flex from '../../components/base/styled/Flex';

import BookmarkProblems from './bookmark';
import TotalProblems from './total';
import WrongProblems from './wrong';
import Sessions from './sessions';

class ProfileView extends Component {
  state = {
    problemsCount: null,
    xps: null,
    progresses: null,
    questionsCount: null,
    flatHeatLevels: null,
    heatLevels: null,
    streaks: null,
  };

  componentWillMount() {
    this.props.setHeaders('หน้าแรก');
    const topics = this.props.topics.reduce((acc, t) => {
      acc[t.id] = t;
      return acc;
    }, {});

    apiClient.stat.getUserStat().then((userStat) => {
      const problemsCount = userStat.problems_count;
      const xps = userStat.progresses;
      const questionsCount = userStat.questions_by_topic;
      const streaks = userStat.streaks;

      this.setState({
        xps,
        questionsCount,
        streaks,
        problemsCount,
      });
    });

    this.setState({
      topics: topics,
    });
  }

  render() {
    return (
      <div className="row animated fadeInRight">
        <div className="col-12">
          <div className="row">
            <div className="col">
              <Card className="hp-p-15">
                <CardHeader title="เลือกโหมด" />
                <Flex center className="flex-column flex-sm-row">
                  <ChooseModeButton
                    to="/select-topics"
                    iconClassName="fa-book"
                    title="ทำโจทย์แยกบท"
                  />
                  <ChooseModeButton
                    to="/select-tests"
                    iconClassName="fa-file-text-o"
                    title="ทำข้อสอบทั้งชุด"
                  />
                  <ChooseModeButton
                    to="/custom-exercise"
                    iconClassName="fa-pencil-square-o"
                    title="สร้างแบบฝึกหัด"
                  />
                </Flex>
              </Card>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <div className="row">
                <div className="col">
                  <TotalProblems problemsCount={this.state.problemsCount} />
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="row">
                <div className="col">
                  <BookmarkProblems />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <WrongProblems />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Sessions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    topics: state.topic.topics,
    topicIdNameMap: state.topic.topicIdNameMap,
  };
}

export default connect(mapStateToProps, null)(ProfileView);

const ChooseModeButton = ({ iconClassName, title, to, onClick }) => {
  return (
    <Link to={to} onClick={onClick}>
      <_Flex>
        <i className={`hp-py-05 hp-px-1 fa ${iconClassName}`} style={{ fontSize: '2.2rem' }} />
        <Text inherit>{title}</Text>
      </_Flex>
    </Link>
  );
};

const _Flex = styled(Flex)`
  width: 8.5rem;
  height: 8.5rem;
  margin: 0 2rem;
  border: 2px solid;
  border-radius: 12px;
  border-color: ${COLOR.HIPPO};
  color: ${COLOR.DARK_HIPPO};
  background-color: ${COLOR.WHITE};
  cursor: pointer;
  transition: 0.2s;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (hover: hover) {
    &:hover {
      color: ${COLOR.LIGHT_HIPPO};
      border-color: ${COLOR.LIGHT_HIPPO};
      box-shadow: 1px 1px 3px ${COLOR.LIGHT_HIPPO};
    }
  }
  &:active {
    color: ${COLOR.DARK_HIPPO};
    border-color: ${COLOR.DARK_HIPPO};
    box-shadow: 1px 1px 3px ${COLOR.DARK_HIPPO};
  }

  @media (max-width: 575px) {
    flex-direction: row;
    height: auto;
    width: auto;
    padding: 1rem;
    margin: 0.75rem 0;
    justify-content: start;
    border: 1px solid;
    border-radius: 6px;
  }
`