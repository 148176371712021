import React from 'react'
import styled from 'styled-components'

import { COLOR } from '../../../../common/const'
import CardBase from './CardBase'
import CardHeader from './CardHeader'

const Card = styled(CardBase)`
    background-color: ${ COLOR.WHITE };
    width: 100%;
    margin-bottom: 1.5rem;
    border-radius: 8px;
    box-shadow: 2px 2px 4px 0 rgba(0,0,0,0.2);

    @media (max-width: 991px) {
        border-radius: 0px;
        box-shadow: 0px 2px 4px 0 rgba(0,0,0,0.2); 
        margin-bottom: 1rem;
    }
`

export default Card

export { CardHeader }