import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import TopicList from './TopicList'
import Card from '../../components/base/styled/Card'
import Select from '../../components/base/Select'

class SelectTopicView extends Component {
  state = {
    filteredTopics: null,
  }

  componentWillMount() {
    this.props.setHeaders('ทำโจทย์แยกบท')
    this.setState({
      filteredTopics: this.props.topics
    })
  }

  filterTopics = tag => {
    const { topics } = this.props
    const filtered = !tag
      ? topics
      : topics.filter(t => _.includes(t.tag, tag))

    this.setState({
      filteredTopics: filtered
    })
  }

  render() {
    if (!this.state.filteredTopics) {
      return null
    }

    return (
      <div className='row justify-content-center animated fadeInRight '>
        <div className='col-lg-8'>
          <div className="pl-3 pl-lg-0 mb-0 my-sm-2 mt-lg-0">
            <Select 
              title
              items={SIDEBAR_ITEMS} 
              onClick={this.filterTopics}
              placeholder="รวมทุกบท"
            />
          </div>
          <Card className="py-0 pt-sm-2 pb-sm-4">
            <TopicList 
              topics={this.state.filteredTopics}
              push={this.props.router.push}
            />
          </Card>
        </div>
      </div>
    )
  }
}

SelectTopicView.propTypes = {
  topics: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    topics: state.topic.topics,
  }
}

export default connect(mapStateToProps, null)(SelectTopicView)

const SIDEBAR_ITEMS = [
  { title: "รวมทุกบท", },
  { title: "รวมเฉพาะบท O-NET", value: "onet" },
  // { title: "รวมบท PAT", value: "pat" },
  // { title: "รวมบท วิชาสามัญ", value: "saman" },
]
