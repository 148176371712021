import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';

import { Flex, Button, Box } from '../../../components/base';
import SingleProblem from '../../../components/problem/SingleProblem';
import QuestionFeedbackButton from '../../../components/QuestionFeedbackButton';
import { isAllQuestionsAnswered } from '../../../common/util';
import { COLOR, SOLVE_MODE } from '../../../common/const';
import { AnalyticContext } from '../../../contexts/AnalyticContext';

class Content extends Component {
  state = {
    noProblem: false,
  };

  componentWillMount() {
    const { session } = this.props;
    this.updateQuestion(session, false);
  }

  componentWillReceiveProps(nextProps) {
    const { session } = nextProps;
    this.updateQuestion(session, true);
  }

  updateQuestion = (session, closeSolution) => {
    const index = session.index;
    const problem = session.problems[index];
    if (!problem) {
      this.setState({
        noProblem: true,
      });
    } else {
      if (!problem.questionText) {
        this.props.getProblem(problem.questionId, index);
      }

      //   if (closeSolution) {
      //     this.refs.do.getWrappedInstance().closeSolution();
      //   }

      this.setState({
        noProblem: false,
      });
    }
  };

  render() {
    const {
      session,
      setProblemIndex,
      selectChoice,
      submitProblem,
      submitTest,
      finishSession,
      goToHomePage,
      onLeaveSession,
    } = this.props;

    if (this.state.noProblem) {
      return <h3>ไม่พบโจทย์ข้อนี้</h3>;
    }

    const index = session.index;
    const problem = session.problems[index];
    const { questionId, testName } = problem;
    return (
      <_ContentCard
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignItems={'center'}
        py={4}
        px={[3, 4]}
      >
        <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
          <SingleProblem
            index={index}
            {...problem}
            {...problem.result}
            selectChoice={selectChoice}
            questionName={`ข้อ ${index + 1}`}
            mainButton={null}
          />
        </Flex>
        <Box width={'100%'} py={3}>
          <MainButton
            session={session} // TODO: Get rid of this.
            mode={session.mode}
            solutionMode={session.solutionMode}
            first={index === 0}
            last={index === session.problems.length - 1}
            index={index}
            completed={problem.completed}
            setProblemIndex={setProblemIndex}
            submitProblem={submitProblem}
            submitTest={submitTest}
            finishSession={finishSession}
            leaveSession={onLeaveSession}
            isAnswered={problem.selectedChoiceId != null}
            next={() => setProblemIndex(index + 1)}
            previous={() => setProblemIndex(index - 1)}
          />
          <Box mt={3} borderTop={'1px solid rgba(0,0,0,0.2)'} pt={4}>
            <QuestionFeedbackButton
              questionId={questionId}
              questionName={`${testName} ข้อ ${index + 1}`}
            />
          </Box>
        </Box>
      </_ContentCard>
    );
  }
}

Content.propTypes = {
  session: PropTypes.object,
  getProblem: PropTypes.func,
  setProblemIndex: PropTypes.func,
  selectChoice: PropTypes.func,
  submitProblem: PropTypes.func,
  finishSession: PropTypes.func,
  submitTest: PropTypes.func,
  leaveSession: PropTypes.func,
};

export default Content;

const MainButton = ({
  // TODO: This is a hack. We shouldn't actually pass 'session' here.
  session,
  mode,
  solutionMode,
  first,
  last,
  finishSession,
  leaveSession,
  next,
  previous,
  submitTest,
}) => {
  const allAnswered = isAllQuestionsAnswered(session.problems);
  const isSolutionMode = solutionMode;

  const { logAction } = React.useContext(AnalyticContext);

  const handleNext = () => {
    logAction({
      category: solutionMode ? 'Review' : 'Session',
      action: 'Route',
      label: `Next`,
    });
    next();
  };
  const handlePrevious = () => {
    logAction({
      category: solutionMode ? 'Review' : 'Session',
      action: 'Route',
      label: `Previous`,
    });
    previous();
  };
  return (
    <Flex justifyContent={'space-between'}>
      {first ? (
        <div></div>
      ) : (
        <Button.Primary.Flat
          onClick={handlePrevious}
          style={{ paddingLeft: '16px', paddingRight: '16px' }}
        >
          &#60; ข้อก่อนหน้า
        </Button.Primary.Flat>
      )}

      <Flex>
        <FinishSessionButton
          mode={mode}
          leaveSession={() => leaveSession()}
          isSolutionMode={isSolutionMode}
          finishSession={() => finishSession()}
          submitTest={() => submitTest()}
          // hide={!allAnswered}
          disabled={!allAnswered && !solutionMode}
          style={{ marginLeft: 'auto' }}
        />
        {!last ? (
          <>
            {isSolutionMode ? (
              <Button.Primary.Default maxWidth={['130px', '150px']} onClick={handleNext} ml={2}>
                ข้อถัดไป &#62;
              </Button.Primary.Default>
            ) : allAnswered ? (
              <Button.Primary.Flat maxWidth={['130px', '150px']} onClick={handleNext} ml={2}>
                ข้อถัดไป &#62;
              </Button.Primary.Flat>
            ) : (
              <Button.Primary.Outline maxWidth={['130px', '150px']} onClick={handleNext} ml={2}>
                ข้อถัดไป &#62;
              </Button.Primary.Outline>
            )}
          </>
        ) : null}
      </Flex>
    </Flex>
  );
};

const FinishSessionButton = ({
  mode,
  isSolutionMode,
  leaveSession,
  finishSession,
  submitTest,
  disabled,
  hide,
  ...restProps
}) => {
  if (hide) {
    return <div></div>;
  }
  if (isSolutionMode) {
    return (
      <Button.Secondary.Default
        maxWidth={['130px', '150px']}
        onClick={leaveSession}
        disabled={disabled}
        {...restProps}
      >
        ออกจากห้องสอบ
      </Button.Secondary.Default>
    );
  }
  switch (mode) {
    case SOLVE_MODE.EXERCISE:
      // TODO: Refactor this to a method.
      // Check if all problems are answered.
      return (
        <Button.Primary.Main
          maxWidth={['130px', '150px']}
          onClick={finishSession}
          disabled={disabled}
          data-tip
          data-for="submit"
          {...restProps}
        >
          จบแบบฝีกหัด
          {disabled ? (
            <ReactTooltip id="submit" place="top" type="error">
              ตอบให้ครบทุกข้อก่อนนะ!
            </ReactTooltip>
          ) : null}
        </Button.Primary.Main>
      );
    case SOLVE_MODE.TEST:
    case SOLVE_MODE.TEST_TIMER:
      return (
        <Button.Primary.Main
          maxWidth={['130px', '150px']}
          onClick={submitTest}
          disabled={disabled}
          data-tip
          data-for="submit"
          {...restProps}
        >
          จบการสอบ
          {disabled ? (
            <ReactTooltip id="submit" place="top" type="error">
              ตอบให้ครบทุกข้อก่อนนะ!
            </ReactTooltip>
          ) : null}
        </Button.Primary.Main>
      );
  }
};

const _ContentCard = styled(Flex)`
  background-color: ${COLOR.WHITE};
  margin-left: 0.75rem;
  flex-basis: 70%;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
    border-radius: 0 0 0 0;
    flex-grow: 1;
    flex-basis: auto;
    box-shadow: none;
  }
`;

// For exercise
// if (mode === SOLVE_MODE.EXERCISE) {
//     if (!completed) {
//       return (
//         <Flex justifyContent={'space-between'}>
//           {!first ? (
//             <Button.Primary.Flat
//               onClick={previous}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 width: '9rem',
//                 cursor: 'pointer',
//               }}
//             >
//               &#60; ข้อก่อนหน้า
//             </Button.Primary.Flat>
//           ) : (
//             <div></div>
//           )}
//           <Button main width="10rem" disabled={!isAnswered} onClick={submitProblem}>
//             ตรวจคำตอบข้อนี้
//           </Button>
//         </Flex>
//       );
//     } else if (!allAnswered) {
//       return (
//         <Flex justifyContent={'space-between'}>
//           {!first ? (
//             <Button.Primary.Flat
//               onClick={previous}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 width: '9rem',
//                 cursor: 'pointer',
//               }}
//             >
//               &#60; ข้อก่อนหน้า
//             </Button.Primary.Flat>
//           ) : (
//             <div></div>
//           )}
//           {!last ? (
//             <>
//               {allAnswered ? (
//                 <Button.Primary.Main width="9rem" onClick={next}>
//                   ข้อถัดไป &#62;
//                 </Button.Primary.Main>
//               ) : (
//                 <Button.Primary.Outline width="9rem" onClick={next}>
//                   ข้อถัดไป &#62;
//                 </Button.Primary.Outline>
//               )}
//             </>
//           ) : null}
//         </Flex>
//       );
//     } else if (allAnswered) {
//       return (
//         <Flex justifyContent={'space-between'}>
//           {!first ? (
//             <Button.Primary.Flat
//               onClick={previous}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'center',
//                 width: '9rem',
//                 cursor: 'pointer',
//               }}
//             >
//               &#60; ข้อก่อนหน้า
//             </Button.Primary.Flat>
//           ) : (
//             <div></div>
//           )}
//           <FinishSessionButton
//             mode={mode}
//             leaveSession={() => leaveSession()}
//             isSolutionMode={isSolutionMode}
//             finishSession={() => finishSession()}
//             submitTest={() => submitTest()}
//             // hide={!allAnswered}
//             disabled={!allAnswered}
//             style={{ marginLeft: 'auto', marginRight: '12px' }}
//           />
//           {!last ? (
//             <>
//               {allAnswered ? (
//                 <Button.Primary.Main width="9rem" onClick={next}>
//                   ข้อถัดไป &#62;
//                 </Button.Primary.Main>
//               ) : (
//                 <Button.Primary.Outline width="9rem" onClick={next}>
//                   ข้อถัดไป &#62;
//                 </Button.Primary.Outline>
//               )}
//             </>
//           ) : null}
//         </Flex>
//       );
//     }
//   }
