import React, { Component } from 'react'
import Flex from '../../base/styled/Flex'

export default class ModalContent extends Component {
    render() {
        const { children } = this.props
 
        return (
            <Flex col className="hp-py-15" style={{ flexGrow: "1" }}>
                { children }
            </Flex>
        )
    }
}