import React from 'react';

import apiClient from '../../api';

import { QUERY_TYPE } from './constants';
import { addResultsToTests } from './utils';

const SubjectDetailContainer = ({ children, router, ...restProps }) => {
  const [subjectInfo, setSubjectInfo] = React.useState(null);
  const [tests, setTests] = React.useState(null);
  const [topics, setTopics] = React.useState(null);
  const [queryType, setQueryType] = React.useState(QUERY_TYPE.BY_TEST);
  const [loading, setLoading] = React.useState(true);

  const { subjectId } = router.params;

  React.useEffect(() => {
    const getData = async () => {
      const [subjectTests, subjectTopics, testResults, subjectInfo] = await Promise.all([
        apiClient.test.getTestsBySubjectId(subjectId),
        apiClient.topic.getTopicsBySubjectId(subjectId),
        apiClient.stat.getTestResults(),
        apiClient.tag.getTagInfoById(subjectId),
      ]);

      const testsWithResult = addResultsToTests(subjectTests, testResults);

      const sortedTestsWithResult = testsWithResult.sort(
        (a, b) => parseInt(b.index) - parseInt(a.index)
      );

      setSubjectInfo(subjectInfo);
      setTests(sortedTestsWithResult);
      setTopics(subjectTopics);
      setLoading(false);
    };
    getData();
  }, []);

  const handleSetQueryType = (queryType) => {
    setQueryType(queryType);
  };

  const handleSelectTest = (testId) => {
    router.push(`/subjects/${subjectId}/tests/${testId}`);
  };

  const handleStartTestSession = (testId) => {
    console.log('start', testId);
  };

  const handleSelectTopic = (topicId) => {
    router.push(`/subjects/${subjectId}/topics/${topicId}`);
  };

  return children({
    loading,
    subjectInfo,
    tests,
    topics,
    queryType,
    handleSetQueryType,
    handleSelectTest,
    handleStartTestSession,
    handleSelectTopic,
  });
};

export default SubjectDetailContainer;
