import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import ParsedText from '../common/ParsedText'
import { COLOR } from '../../common/const'
import Text, { Title } from '../base/styled/Text'
import Flex from '../base/styled/Flex'
import apiClient from '../../api'

class Solutions extends Component {
    state = {
        solutions: null
    }

    componentWillMount() {
        apiClient.practice.getSolutions(this.props.questionId)
        // this.props.solutions.map(solution => {
        //     solution.knowledge.map(knowledgeId => {
        //         this.props.getKnowledge(knowledgeId)
        //     })
        // })
    }

    componentWillReceiveProps(nextProps) {
        this.updateSolutions(nextProps.questionId)
    }

    updateSolutions = questionId => {
        apiClient.practice.getSolutions(questionId)
            .then(solutions => {
                this.setState({ solutions })
            })
    }

    renderSolutionStep = (solution, index) => {
        return (
            <div key={ solution.id } className="hp-mb-1">
                {/* <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="hh5 hp-ml-05" style={{ width: "50%" }}>
                        <div>Step { index + 1 }</div>
                        <hr className="hp-m-0 hp-hr-light" />
                    </div>
                    <div className="d-flex flex-column align-items-end">
                        { solution.knowledge.map((knowledgeId, index) =>
                            <div key={ knowledgeId }>
                                { knowledgeId in knowledges ? (
                                    <KnowledgeModal knowledgeId={ knowledgeId }>
                                        <a className='c3 link hp-ml-05'>{ knowledges[knowledgeId].name } ⇨</a>
                                    </KnowledgeModal>
                                ) : (null) }
                            </div>
                        ) }
                    </div>
                </div> */}
                <Text className="hp-py-1"><ParsedText text={solution.text} /></Text>
            </div>
        )
    }

    render() {
        return (
            <_Flex col>
                <Flex row between alignCenter>
                    <Title className="hp-py-1">
                        เฉลยวิธีคิด
                    </Title>
                    <a onClick={ this.props.toggleVisible }>
                        {/* <i className="fa fa-times fa-1x"></i> */}
                        <Text className="btn-link">ซ่อนเฉลย</Text>
                    </a>
                </Flex>
                <Flex>
                    { this.state.solutions != null
                        ? <div>
                            {this.state.solutions.map((solution, index) => this.renderSolutionStep(solution, index))}
                        </div>
                        : <Text orange>Solution is coming soon</Text>
                    }
                </Flex>
            </_Flex>
        )
    }
}

export default Solutions

Solutions.propTypes = {
    questionId: PropTypes.string.isRequired,
    isVisible: PropTypes.bool.isRequired,
    toggleVisible: PropTypes.func.isRequired,
}

const _Flex = styled(Flex)`
    padding: 0 1.5rem;
    border-left: solid 5px ${COLOR.LIGHT_HIPPO};
    background-color: rgba(0,0,0,0.025);
    margin-top: 1rem;
`