import _ from 'lodash';
import jwtDecode from 'jwt-decode';

import config from './config';
import { store } from '../index';
import { QUESTION_STATUS } from './const';

function _stringifyParams(params) {
  if (!params) {
    return '';
  }

  const keys = Object.keys(params);
  if (keys.length === 0) {
    return '';
  }

  const q = keys.reduce((acc, key) => {
    acc += `&${key}=${params[key]}`;
    return acc;
  }, '');

  return q.substring(1);
}

function _addTokenHeader(headers) {
  headers.append('Authorization', `Bearer ${store.getState().auth.jwt}`);
}

function _parseResponse(res) {
  return Promise.all([res.json(), res.ok]).then(([data, ok]) => {
    try {
      const success = ok ? true : false;

      if (!success && data.detail) {
        throw new Error(data.detail);
      }

      return data;

      // TODO: We should actually do this properly. Requires changes in several places.
      // if (data.constructor === Array) {
      //     return data
      // }

      // return {
      //     success,
      //     ...data
      // }
    } catch (e) {
      throw e;
      // TODO: Handle the case where response is not json properly
      // return {
      //     success: false
      // }
    }
  });
}

export function getRequest(urlPath, params = {}, requiredToken = true) {
  const queryString = _stringifyParams(params);

  const headers = new Headers();
  if (requiredToken) {
    _addTokenHeader(headers);
  }

  return fetch(`${config.baseApiUrl}${urlPath}?${queryString}`, {
    headers: headers,
    method: 'GET',
  }).then((res) => {
    // TODO: We should reject if res.ok is false.
    return _parseResponse(res);
  });
}

export function postRequest(urlPath, data, requiredToken = true) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (requiredToken) {
    _addTokenHeader(headers);
  }

  return fetch(`${config.baseApiUrl}${urlPath}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(data),
    mode: 'cors',
  }).then((res) => {
    return _parseResponse(res);
  });
}

export function deleteRequest(urlPath, data, requiredToken = true) {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (requiredToken) {
    _addTokenHeader(headers);
  }

  return fetch(`${config.baseApiUrl}${urlPath}`, {
    method: 'DELETE',
    headers: headers,
    body: JSON.stringify(data),
    mode: 'cors',
  }).then((res) => {
    return _parseResponse(res);
  });
}

// Format from 0.555555 to 55
export function getFormattedPercentage(percentage) {
  return (Number(percentage) * 100).toFixed(0);
}

export function createPromise(values) {
  return new Promise((resolve) => {
    resolve(values);
  });
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export function getSubtopics(topicId) {
  const topics = store.getState().topic.topics;
  const topic = _.find(topics, ['id', topicId]);

  if (!topic) {
    return null;
  }

  return topic.children;
}

export function translate(optionKey, value) {
  switch (optionKey) {
    case 'tag':
      switch (value) {
        case 'onet':
          return 'O-NET';
        case 'pat':
          return 'PAT';
        case 'saman':
          return 'สามัญ';
        default:
          return value;
      }
      break;
    case 'difficulty':
      switch (value) {
        case 1:
          return 'easy';
        case 2:
          return 'medium';
        case 3:
          return 'difficult';
        default:
          return value;
      }
      break;
    default:
      console.error('translate: unknown option key');
  }
}

export function isAllQuestionsAnswered(problems) {
  return problems.reduce((acc, p) => {
    return acc && p.selectedChoiceId;
  }, true);
}

export function getOnlyNumeric(string) {
  const cleaned = string.replace(/[^0-9.]/g, '');
  return !cleaned ? null : cleaned;
}

export function getDifficulty(difficultyId) {
  const difficulties = store.getState().topic.difficulties;
  return difficulties[difficultyId];
}

export function getTopicById(topicId, onlyTopLevel = false) {
  const topics = store.getState().topic.topics;

  if (onlyTopLevel) {
    return _.find(topics, ['id', topicId]);
  }

  console.error('not-supported-finding-subtopic-name');
}

export function getTokenInfo() {
  // TODO: We should load token from reducer instead.
  const token = store.getState().auth.jwt;

  const decodedToken = jwtDecode(token);
  const studentId = decodedToken.user_id;
  return decodedToken;
}

export function getRandomId() {
  return Math.random().toString(36).slice(2);
}

export function updateQuestionsStatus(questions, questionStat) {
  if (!questions || !questionStat) {
    return;
  }

  questions.forEach((q) => {
    const stat = questionStat[q.id];
    if (!stat || stat.completed == 0) {
      q.status = QUESTION_STATUS.TODO;
    } else if (stat.correct > 0) {
      q.status = QUESTION_STATUS.SOLVED;
    } else if (stat.completed > 0) {
      q.status = QUESTION_STATUS.ATTEMPTED;
    }
  });
}

export function updateQuestionsBookmark(questions, bookmarks) {
  if (!questions || !bookmarks) {
    return;
  }

  questions.forEach((q) => {
    q.isBookmarked = bookmarks.has(q.id);
  });
}

export function updateQuestionsIndex(questions) {
  _.sortBy(questions, ['index']).forEach((q, index) => {
    q.mergedIndex = index + 1;
  });
}

export function updateQuestionsInfo(questions, stat, bookmarks) {
  if (!questions || !stat || !bookmarks) {
    return;
  }
  updateQuestionsStatus(questions, stat);
  updateQuestionsBookmark(questions, bookmarks);
  updateQuestionsIndex(questions);
}

export function hackTestName(name) {
  if (typeof name !== 'string') {
    return name;
  }
  let updated = name.replace('PAT', 'PAT-1');
  updated = updated.replace('สามัญ', 'วิชาสามัญ');
  return updated;
}

export function fromHHMMSS(formatted) {
  if (formatted) {
    const splits = formatted.split(':');
    return parseInt(splits[0]) * 3600 + parseInt(splits[1]) * 60 + parseInt(splits[2]);
  } else {
    return '';
  }
}

export function toHHMMSS(sec) {
  const date = new Date(null);
  date.setSeconds(sec);
  return date.toISOString().substr(11, 8);
}

export function fromHHMMSSToSeconds(formatted) {
  const splits = formatted.split(':');
  return parseInt(splits[0]) * 3600 + parseInt(splits[1]) * 60 + parseInt(splits[2]);
}

function fromSecondsToMMSS(sec) {
  const minutes = Math.floor(sec / 60);
  const seconds = sec % 60;
  let formattedSeconds;
  if (seconds < 10) {
    formattedSeconds = `0${seconds}`;
  } else {
    formattedSeconds = seconds;
  }

  return `${minutes}:${formattedSeconds}`;
}

function fromSecondsToMinutes(seconds) {
  return seconds / 60;
}

export { fromSecondsToMMSS, fromSecondsToMinutes };
