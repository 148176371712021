import React from 'react'
import styled from 'styled-components'

import { COLOR } from '../../../../common/const'
import Flex from '../../styled/Flex'

const SidebarListItemBase = styled(Flex)`
    margin: 0rem;
    padding: 1rem 0;
    border-bottom: solid 1px ${COLOR.LIGHT_GREY};
    border-radius: 0rem;
`

export default SidebarListItemBase
