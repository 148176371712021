import cookie from 'react-cookie'

import apiClient from '../api'
import { HOSTNAME } from '../common/const'

export const LOGOUT = 'LOGOUT'
const SET_TOKEN = 'SET_TOKEN'

//export function getToken(data, mode) {
  //return dispatch => {
    //apiClient.auth.getToken(data, mode)
      //.then(res => {
        //dispatch({
          //type: LOGIN,
          //token: res.token,
        //})
      //})
      //.then(res => {
        //browserHistory.push('/')
      //})
      //.then(() => {
        //dispatch({ type: DONE_LOGIN })
      //})
      //.catch(error => {
        //dispatch({
          //type: UPDATE_FORM_ERROR,
          //payload: {
            //detail: error.message
          //}
        //})
      //})
  //}
//}

export function setToken(token) {
  return dispatch => dispatch({
    type: SET_TOKEN,
    token: token
  })
}

export function refreshToken(data) {
  return dispatch => {
    apiClient.auth.refreshToken(data)
      .then(res => {
        dispatch({
          type: SET_TOKEN,
          token: res.token,
        })
      })
  }
}

export function logout() {
  return dispatch => dispatch({ type: LOGOUT })
}

const ACTION_HANDLERS = {
    [LOGOUT]: handleLogout,
    [SET_TOKEN]: handleSetToken,
}

function handleLogout(state, action) {
  removeTokenFromCookie()
  return INITIAL_STATE
}

function handleSetToken(state, action) {
  saveTokenToCookie(action.token)
  return { ...state, jwt: action.token }
}

function saveTokenToCookie(token) {
  // TODO: We need to clean this up when we want to have staging.
  if (window.location.hostname === '127.0.0.1' || 'localhost'){
    cookie.save('token', token)
  } else {
    cookie.save('token', token, { domain: HOSTNAME })
  }
}

function removeTokenFromCookie() {
  // TODO: We need to clean this up when we want to have staging.
  if (window.location.hostname === '127.0.0.1' || 'localhost'){
    cookie.remove('token')
  } else {
    cookie.remove('token', { domain: HOSTNAME })
  }
}

export default function(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

const INITIAL_STATE = {
  jwt: null,
}
