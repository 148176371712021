import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../common/const'

const Label = styled.span`
    padding: 0.1rem 1rem;
    margin: 0 0.5rem 0 0;
    font-size: ${props => props.small ? "12px" : "1rem"};
    color: ${props => props.outline && props.color ? props.color : props.outline ? COLOR.LIGHT_GREY : COLOR.WHITE};
    background-color: ${props => props.outline ? COLOR.WHITE : props.color ? props.color : COLOR.LIGHT_GREY};
    border: 1px solid ${props => props.color || COLOR.LIGHT_GREY};
    border-radius: 4px;
    width: fit-content;
`

export default Label

Label.propTypes = {
    small: PropTypes.bool,
    outline: PropTypes.bool,
}