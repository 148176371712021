import React from 'react';
import _sortBy from 'lodash/sortBy';

import apiClient from '../../api';
import { store } from '../../index';

const SubjectTopicDetailContainer = ({ children, router, ...restProps }) => {
  const [topicInfo, setTopicInfo] = React.useState(null);
  const [questions, setQuestions] = React.useState(null);
  const [subjectInfo, setSubjectInfo] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  const { topicId, subjectId } = router.params;

  React.useEffect(() => {
    const getData = async () => {
      const [topicInfo, questions, bookmarks, subjectInfo] = await Promise.all([
        apiClient.topic.getTopicInfoById(topicId),
        apiClient.topic.getTopicQuestionsById(topicId),
        apiClient.other.getBookmarks(),
        apiClient.tag.getTagInfoById(subjectId),
      ]);

      const filteredQuestions = questions.filter((question) => {
        const { tag } = question;
        const { id } = tag[0];
        return id.toString() === subjectId.toString();
      });
      const formattedQuestions = filteredQuestions.map((question) => {
        const { topics, id, exam, ...questionInfo } = question;
        const { name } = topics[0] || {};
        const bookmarkInfo = bookmarks.find((bookmark) => bookmark.question === id);
        const isBookmarked = bookmarkInfo !== undefined;
        const relatedTest = exam[0];
        return { topic: name, id, bookmarked: isBookmarked, test: relatedTest, ...questionInfo };
      });

      const sortedQuestions = _sortBy(formattedQuestions, ['test', 'index']);
      setTopicInfo(topicInfo);
      setQuestions(sortedQuestions);
      setSubjectInfo(subjectInfo);
      setLoading(false);
    };

    getData();
  }, []);

  const updateBookmarks = () => {
    let bookmarks = store.getState().bookmark.bookmarks;
    bookmarks = bookmarks.reduce((agg, b) => {
      agg[b] = true;
      return agg;
    }, {});
    const updatedQuestions = questions.map((q) => {
      q.bookmarked = q.id in bookmarks;
      return q;
    });
    setQuestions(updatedQuestions);
  };

  return children({
    loading,
    topicInfo,
    questions,
    subjectInfo,
    handleBookmark: updateBookmarks,
  });
};

export default SubjectTopicDetailContainer;
