import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import Flex from '../Flex'

const _ProblemButton = styled.button`
    @media (max-width: 575px) {
        font-size: 1rem;
        box-shadow: none;
        border: 0;
        width: 100%;
        border-bottom: 1px solid ${COLOR.LIGHTER_GREY};
        border-radius: 0;
        color: ${props => props.isWrong ? COLOR.RED :
            props.isCorrect ? COLOR.GREEN : COLOR.DARK_GREY };
        margin: 0;
        padding: 0.5rem 0.5rem 0.5rem 0;
        @media (hover: hover) {
            &:hover {
                box-shadow: none;
                border-color: ${COLOR.LIGHTER_GREY};
                background-color: ${COLOR.LIGHTER_GREY};
            }
        }
        &:active {
            box-shadow: none;
            border-color: ${COLOR.LIGHTER_GREY};
            background-color: #d8d8d8;
        }
        &:focus {
            box-shadow: none;
            border-color: ${COLOR.LIGHTER_GREY};
            background-color: #d8d8d8;
        }
    }
    min-height: 48px;
    font-family:'Montserrat', 'Kanit', 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif' !important;
    font-size: 0.85rem;
    font-weight: 400;
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    width: calc((100% - 3rem)/3);
    transition: 0.2s;
    cursor: ${props => props.disable ? 'not-allowed' : 'pointer'};
    padding: 0.5rem 0.75rem;
    margin: 0.5rem 1rem 0.5rem 0;
    border: ${props => props.isWrong ? '1.5px solid' :
        props.isCorrect ? '1.5px solid' : '1px solid' };
    border-color: ${props => props.isWrong ? COLOR.RED :
        props.isCorrect ? COLOR.GREEN : COLOR.LIGHT_GREY };
    color: ${COLOR.DARK_GREY};
    cursor: pointer;
    background-color: transparent;
    border-radius: 8px;
    
    @media (hover: hover) {
        &:hover {
            text-decoration: none;
            color: ${props => props.isWrong ? COLOR.LIGHT_RED :
                props.isCorrect ? COLOR.LIGHT_GREEN : COLOR.LIGHT_HIPPO };
            border-color: ${props => props.isWrong ? COLOR.LIGHT_RED :
                props.isCorrect ? COLOR.LIGHT_GREEN : COLOR.LIGHT_HIPPO };
            box-shadow: 1px 1px 3px ${COLOR.LIGHT_GREY};
        }
    }

    &:active {
        outline: 0;
        color: ${props => props.isWrong ? COLOR.DARK_RED :
            props.isCorrect ? COLOR.DARK_GREEN : COLOR.DARK_HIPPO };
        border-color: ${props => props.isWrong ? COLOR.DARK_RED :
            props.isCorrect ? COLOR.DARK_GREEN : COLOR.DARK_HIPPO };
    }
`

const ProblemButton = ({ children, isWrong, isCorrect, easy, moderate, hard, hasVideo, isBookmarked, disable, key, onClick }) => {
    const levelColor = easy ? COLOR.GREEN :
        moderate ? COLOR.ORANGE :
        hard ? COLOR.RED : COLOR.LIGHT_GREY
    const bookmarkColor = isBookmarked ? COLOR.ORANGE : COLOR.LIGHT_GREY

    return(
        <_ProblemButton
            disable={ disable }
            isWrong={ isWrong }
            isCorrect={ isCorrect }
            key={ key }
            onClick={ onClick }
        >
            <Flex row between alignCenter>
                { children }
                <div style={{ fontSize: "1rem" }}>
                    { hasVideo ? <i className="fa fa-video-camera" style={{ color: COLOR.LIGHT_GREY, marginRight: "0.35rem" }}/> : null }
                    <i className="fa fa-circle-o" style={{ color: levelColor, marginRight: "0.35rem" }}/>
                    <i className="fa fa-bookmark" style={{ color: bookmarkColor }}/>
                </div>
            </Flex>
        </_ProblemButton>
    )
}

ProblemButton.propTypes = {
    width: PropTypes.string, // Use to fix button size
    disabled: PropTypes.bool,
    onet: PropTypes.bool,
    pat: PropTypes.bool,
    saman: PropTypes.bool
}

export default ProblemButton