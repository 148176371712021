import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.div`
    position: relative;

    @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
    }
    @media (max-width: 991px) {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        padding-right: 0;
        padding-left: 0;
        margin-left: 0;
        margin-right: 0;
    }
    @media (min-width: 992px) {
        width: 1140px;
        max-width: 100%;
        padding-right: 1rem;
        padding-left: 1rem;
        margin-left: auto;
        margin-right: auto;
    }    
`

export default Wrapper