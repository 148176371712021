import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { SESSION_TYPE_TEST } from '../../common/const';
import Button from '../../components/base/styled/Button';
import Text, { Title } from '../../components/base/styled/Text';
import Card from '../../components/base/styled/Card';
import Flex from '../../components/base/styled/Flex';
import { updateQuestionsInfo, hackTestName } from '../../common/util';
import PracticeInfoModal from '../../components/practice/PracticeInfoModal';
import TestPracticeButton from '../../components/practice/TestPracticeButton';
import { setSessionInfo } from '../../actions/solve';
import { getTestQuestions } from '../../reducers/test';
import { ProblemTable } from '../../components/tables';
import { QuestionCountByTopic } from '../../components/charts';

class TestInfoView extends Component {
  state = {
    test: null,
    logo: null,
  };

  componentWillMount() {
    this.updateTest(this.props.params.testId, this.props.tests, this.props.testResults);
    this.updateQuestions(this.state.test, this.props.bookmarks, this.props.questionStat);
  }

  componentWillReceiveProps(nextProps) {
    this.updateTest(nextProps.params.testId, nextProps.tests, nextProps.testReults);
    this.updateQuestions(this.state.test, nextProps.bookmarks, nextProps.questionStat);
  }

  updateTest(testId, tests, results) {
    if (!testId || !tests || !results) {
      return;
    }

    const test = _.find(tests, { id: testId });
    if (!test.questions) {
      this.props.getTestQuestions(testId);
    }
    this.props.setHeaders(`ทำโจทย์แนวข้อสอบ ${hackTestName(test.name)}`);
    this.setState({
      test,
      result: results[testId],
    });
  }

  updateQuestions(test, bookmarks, stats) {
    if (!test || !test.questions || !bookmarks || !stats) {
      return;
    }
    updateQuestionsInfo(test.questions, stats, bookmarks);
  }

  renderMainButton = (test) => {
    return (
      <Button
        className="w-100 hp-mt-15"
        onClick={() => {
          this.setState({ logo: <TestPracticeButton test={test} /> });
          this.props.setSessionInfo({
            type: SESSION_TYPE_TEST,
            testId: test.id,
            title: hackTestName(test.name),
            description: test.description,
            questionCount: test.question_count,
            duration: test.duration,
          });
          this.refs.infoModal.openModal();
        }}
      >
        ทำข้อสอบทั้งชุด
      </Button>
    );
  };

  renderGraph = (questions) => {
    return <QuestionCountByTopic questions={questions} topicLevel={1} />;
  };

  render() {
    const { test, result } = this.state;
    if (!test || !test.questions) {
      return null;
    }

    return (
      <div className="animated fadeInRight justify-content-center row">
        <div className="col-lg-8">
          <Card className="hp-p-15 d-flex flex-column flex-md-row">
            <Flex col className="w-100 w-md-60">
              <Title small>จำนวนข้อแยกตามบท</Title>
              <Flex alignCenter center>
                {this.renderGraph(test.questions)}
              </Flex>
            </Flex>
            <Flex col className="w-100 w-sm-60 w-md-40">
              <Title small className="hp-mb-05">
                รายละเอียด
              </Title>
              <Flex row between>
                <Text>จำนวนข้อ</Text>
                <Text>{this.state.test.question_count}</Text>
              </Flex>
              <Flex row between>
                <Text>ระยะเวลา</Text>
                <Text>180 นาที</Text>
              </Flex>

              <Flex row between>
                <Text>คะแนนสูงสุด</Text>
                {result ? (
                  <Text>
                    {result.highscore}/{test.question_count}
                  </Text>
                ) : null}
              </Flex>

              {/*<Title small className="hp-mb-05 hp-mt-15">ทำครั้งล่าสุด</Title>
                <Flex row between>
                    <Text>13-05-2017 <Text small orange className="d-inline">High Score!</Text></Text>
                    <Text>31/50</Text>
                </Flex>
                <Flex row between>
                    <Text>01-08-2016</Text>
                    <Text>18/50</Text>
                </Flex>
                */}
              {this.renderMainButton(test)}
              <Flex row center className="hp-mt-1">
                <Text grey>หรือ</Text>
              </Flex>
              <Flex row center className="hp-mt-1">
                <Text>เลือกทำโจทย์ข้อที่ต้องการด้านล่าง</Text>
              </Flex>
            </Flex>
          </Card>
          <Card>
            <ProblemTable problems={test.questions} topicLevel={1} />
          </Card>
          <PracticeInfoModal ref="infoModal" logo={this.state.logo} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    tests: state.test.tests,
    questionStat: state.stat.userQuestionStat,
    bookmarks: state.bookmark.bookmarkIds,
    testResults: state.stat.testResults,
  };
}

const actions = { setSessionInfo, getTestQuestions };
export default connect(mapStateToProps, actions)(TestInfoView);
