import React, { Component } from 'react'
import { Title } from '../../base/styled/Text'
import { ActionIcon } from '../../base/styled/Icon'
import Flex from '../../base/styled/Flex'

export default class ModalTitle extends Component {
    render() {
        const { title, closeButton } = this.props
 
        return (
            <Flex between alignCenter>
                <Title small>
                    { title }
                </Title>
                <div onClick={ closeButton }><ActionIcon size="1.5rem" className="fa fa-times"/></div>
            </Flex>
        )
    }
}