import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'

const TextBase = styled.p`
    margin: 0;
    font-family:'Montserrat', 'Kanit', 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif' !important;
    color: ${props => props.inherit ? null : 
        props.hippo ? COLOR.DARK_HIPPO : 
        props.grey ? COLOR.LIGHT_GREY : 
        props.orange ? COLOR.ORANGE :
        props.blue ? COLOR.BLUE :
        props.green ? COLOR.GREEN :
        props.red ? COLOR.RED :
        props.white ? COLOR.WHITE : 'black' };
`

export default TextBase

TextBase.propTypes = {
    hippo: PropTypes.bool, // Text with hippo color
    orange: PropTypes.bool, // Text with orange color
    blue: PropTypes.bool, // Text with blue color
    green: PropTypes.bool, // Text with green color
    red: PropTypes.bool, // Text with red color
    grey: PropTypes.bool, // Text with light_grey color
    white: PropTypes.bool, // Text with white color
    inherit: PropTypes.bool, // Use parent color
}