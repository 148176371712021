import apiClient from '../api'
import { LOGOUT } from './auth'

const GET_USER_INFO = 'GET_USER_INFO_NEW'

export function getUserInfo() {
  return dispatch => {
    apiClient.student.getStudentInfo()
      .then(info => {
        dispatch({
          type: GET_USER_INFO,
          info: info
        })
      })
  }
}

const ACTION_HANDLERS = {
  [GET_USER_INFO]: handleGetUserInfo,
  [LOGOUT]: handleLogout,
}

function handleGetUserInfo(state, action) {
  return { ...state, userInfo: action.info }
}

function handleLogout(state, action) {
  return INITIAL_STATE
}

const INITIAL_STATE = {
  userInfo: null,
}

export default function(state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
