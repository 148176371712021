import React, { Component } from 'react'

export default class ComingSoonView extends Component {
    render() {
        return (
            <div className="animated fadeInRight">
                <div className="row no-gutters justify-content-center hp-mt-3">
                    <div className="col-12 text-center">
                        <img src="/img/hippoIcon-small.png" />
                    </div>
                    <div className="col-12 text-center hp-mt-1">
                        <div className="hh1">Coming Soon!</div>
                    </div>
                    <div className="col-12 text-center hp-mt-1">
                        <div className="c3">We are working hard to deliver this feature to you! It is coming soon!</div>
                    </div>
                </div>
            </div>
        )
    }
}