import styled from 'styled-components';

import Box from './Box';

const Card = styled(Box)`
  background-color: white;
  border-radius: 16px;
  padding: 28px 20px;
  text-decoration: none;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.07s ease-out, transform 0.07s ease-out, opacity 0.07s ease-out;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 32px;
  }

  ${({ clickable }) =>
    clickable
      ? `
      cursor: pointer;
      &:hover {
        box-shadow: 0 16px 16px 0 rgba(32, 32, 32, 0.05), 0 8px 8px 0 rgba(42, 42, 42, 0.05),
          0 4px 4px 0 rgba(49, 49, 49, 0.05), 0 2px 2px 0 rgba(45, 45, 45, 0.05),
          0 64px 64px 0 rgba(35, 35, 35, 0.05), 0 32px 32px 0 rgba(49, 49, 49, 0.05);
        transform: translateY(-8px);
      }
      &:active {
        box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
      }
    `
      : ''}
`;

export default Card;
