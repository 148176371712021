import React, { Component } from 'react'

export default class HeatmapCell extends Component {
    
    render() {
        const { level, small } = this.props
        const size = small ? "20px" : "30px"

        let bgClass
        switch(level) {
            case 0:
                bgClass = 'light-bg'
                break
            case 1:
                bgClass = 'hippo-light-bg'
                break
            case 2:
                bgClass = 'hippo-bg'
                break
            case 3:
                bgClass = 'hippo-dark-bg'
                break
            default:
                // If > 3
                bgClass = 'hippo-dark-bg'
                break
        }

        return (
            <div style={{ padding:"0.075rem"}}>
                <div className={`${bgClass}`} style={{ width: size, height: size, padding:"0.02rem"}} />
            </div>
        )
    }
}

// HeatmapCell.defaultProps = {
//     size: "30px"
// }