import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Box, Loading } from '../../../components/base';
import { PACKAGE_STATUS } from '../constants';

import PackageCard from './PackageCard';

const PackageLayout = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;

  align-items: stretch;

  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`;

const MyPackages = ({ packages, onPackageActivated }) => {
  return !packages ? (
    <Loading />
  ) : (
    <PackageLayout>
      {packages.map((myPackage) => {
        const {
          id,
          name,
          description,
          price,
          displayPrice,
          expiryDate,
          accessibleTestNames,
          detail,
          isEnrolled,
        } = myPackage || {};
        return (
          <PackageCard
            key={id}
            name={name}
            description={description}
            price={price}
            displayPrice={displayPrice}
            isEnrolled={isEnrolled}
            expiryDate={expiryDate}
            accessibleTestNames={accessibleTestNames}
            detail={detail}
            id={id}
            onPackageActivated={onPackageActivated}
          />
        );
      })}
    </PackageLayout>
  );
};

MyPackages.propTypes = {
  packages: PropTypes.array,
};
MyPackages.defaultProps = {
  packages: [],
};

export default MyPackages;
