import React from 'react';
import PropTypes from 'prop-types';

import Image from './Image';

const LOGO_VARIANTS = {
  HORIZONTAL: 'HORIZONTAL',
  SQUARE: 'SQUARE',
};

const imgUrl = {
  [LOGO_VARIANTS.SQUARE]: '/img/mm-logo-small.svg',
  [LOGO_VARIANTS.HORIZONTAL]: '/img/mm-logo.svg',
};
const Logo = ({ variant, alt, ...restProps }) => {
  return <Image src={imgUrl[variant]} alt={alt} {...restProps} />;
};

Logo.VARIANTS = LOGO_VARIANTS;

Logo.propTypes = {
  variant: PropTypes.oneOf(Object.values(LOGO_VARIANTS)).isRequired,
  alt: PropTypes.string,
};

Logo.defaultProps = {
  variant: LOGO_VARIANTS.HORIZONTAL,
  alt: 'marchmellow logo',
};

export default Logo;
