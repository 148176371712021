import React from 'react';
import PropTypes from 'prop-types';

import Bookmark from '../../../common/Bookmark';
import { Flex, Text } from '../../../base';

const Header = ({ questionName, questionId, onBookmark, onUnbookmark }) => {
  return (
    <Flex
      flexDirection={['column', 'row']}
      justifyContent={'space-between'}
      alignItems={['flex-start', 'center']}
      width={'100%'}
      pb={4}
      borderBottom={'1px solid black'}
    >
      <Text.Title.Lg width={'100%'} mb={[3, 0]}>
        {questionName}
      </Text.Title.Lg>
      <Bookmark
        questionId={questionId}
        variant={'button'}
        onBookmark={onBookmark}
        onUnbookmark={onUnbookmark}
      />
    </Flex>
  );
};

Header.propTypes = {
  questionName: PropTypes.string,
  questionId: PropTypes.string,
};
export default Header;
