import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../common/const';
import ListItemBase from '../../components/base/List/ListItemBase';
import { Title } from '../../components/base/styled/Text';
import Flex from '../../components/base/styled/Flex';

class TopicList extends Component {
  render() {
    const { topics, push } = this.props;
    return (
      <div>
        {topics.map(topic => (
          <div key={topic.id} onClick={() => push(`/select-topics/${topic.id}`)}>
            <_ListItemBase
              className={`d-flex flex-row justify-content-between align-items-center`}
              style={{ color: null }}
            >
              <Flex row>
                <Img width="100%" height="100%" src={topic.image_url || '/img/blueIcon.png'} />
                <Flex col center>
                  <Title inherit small className="hp-mx-1 text-overflow-1">
                    {topic.name}
                  </Title>
                </Flex>
              </Flex>
            </_ListItemBase>
          </div>
        ))}
      </div>
    );
  }
}

TopicList.propTypes = {
  topics: PropTypes.array.isRequired,
};

export default TopicList;

const _ListItemBase = styled(ListItemBase)`
  cursor: pointer;
  @media (hover: hover) {
    &:hover {
      color: ${COLOR.LIGHT_HIPPO};
    }
  }

  &:active {
    color: ${COLOR.DARK_HIPPO};
  }

  &:focus {
    color: ${COLOR.DARK_HIPPO};
  }
`;

const Img = styled.img`
  @media (max-width: 575px) {
    max-width: 2.75rem;
    max-height: 2.75rem;
  }
  @media (min-width: 575px) and (max-width: 991px) {
    max-width: 52px;
    max-height: 52px;
  }
  @media (min-width: 992px) {
    max-height: 60px;
    max-width: 60px;
  }
`;
