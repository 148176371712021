import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { COLOR } from '../../common/const';
import { ActionIcon } from '../base/styled/Icon';
import Text from '../base/styled/Text';
import Flex from '../base/styled/Flex';
import { Button } from '../base';
import { bookmark, unbookmark } from '../../reducers/bookmark';

const StyledButton = styled(Button)`
  width: 170px;
  ${(props) =>
    props.isBookmarked
      ? `
      background-color: ${COLOR.ORANGE};
      color: ${COLOR.WHITE};
      border: 1px solid ${COLOR.ORANGE};
      &:hover {
        background-color: ${COLOR.LIGHT_ORANGE};
        border-color: ${COLOR.LIGHT_ORANGE};
      }
      &:active {
        background-color: ${COLOR.DARK_ORANGE};
        border-color: ${COLOR.DARK_ORANGE};
      }
    `
      : `
      background-color: ${COLOR.WHITE};
      border: 1px solid ${COLOR.GREY};
      color: ${COLOR.GREY};
      &:hover {
        border-color: ${COLOR.LIGHT_GREY};
        color: ${COLOR.LIGHT_GREY};
      }
      &:active {
        border-color: ${COLOR.DARK_GREY};
        color: ${COLOR.DARK_GREY};
      }
    `}
`;
StyledButton.defaultProps = {
  dense: true,
};

const BookmarkButton = ({ onClick, isBookmarked }) => {
  return (
    <StyledButton isBookmarked={isBookmarked} onClick={onClick}>
      <ActionIcon
        size="14px"
        className="fa fa-bookmark hp-mr-05"
        noTransition={true}
        style={{ color: isBookmarked ? COLOR.WHITE : 'inherit' }}
        active={!isBookmarked}
      />
      {isBookmarked ? 'บุ๊คมาร์กแล้ว' : 'กดเพื่อบุ๊คมาร์ก'}
    </StyledButton>
  );
};

class Bookmark extends Component {
  static propTypes = {
    questionId: PropTypes.string.isRequired,
    onBookmark: PropTypes.string,
  };

  _isBookmarked = () => {
    const { questionId, bookmarks } = this.props;
    return _.includes(bookmarks, questionId);
  };

  _onClickBookmark = () => {
    const { questionId, bookmark, unbookmark, onBookmark, onUnbookmark } = this.props;

    if (this._isBookmarked()) {
      unbookmark(questionId);
      if (onUnbookmark) {
        onUnbookmark();
      }
    } else {
      bookmark(questionId);
      if (onBookmark) {
        onBookmark();
      }
    }
  };

  handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this._onClickBookmark();
  };

  render() {
    const { variant, compact } = this.props;
    return (
      <div>
        {variant === 'button' ? (
          <BookmarkButton onClick={this.handleClick} isBookmarked={this._isBookmarked()} />
        ) : (
          <Flex widthAuto>
            <ActionIcon
              size="1.125rem"
              className="fa fa-bookmark hp-mr-05"
              active={this._isBookmarked() ? true : false}
              onClick={this.handleClick}
            />
            {!compact && (
              <Text
                small
                orange={this._isBookmarked() ? true : false}
                className="hidden-sm-down"
                style={{ width: '68px' }}
              >
                {this._isBookmarked() ? 'บุ๊คมาร์กแล้ว' : 'ยังไม่บุ๊คมาร์ก'}
              </Text>
            )}
          </Flex>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookmarks: state.bookmark.bookmarks,
  };
}

const actions = {
  bookmark,
  unbookmark,
};

export default connect(mapStateToProps, actions)(Bookmark);
