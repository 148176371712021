import { getRequest, toHHMMSS } from '../common/util';

export async function getSessions() {
  const sessions = await getRequest('/sessions/');
  if (sessions) {
    const formattedSessions = sessions.map((s) => {
      const { datetime, id, score, title, timespent } = s;
      const { correct, total } = score || {};

      return {
        date: datetime,
        id,
        score: correct,
        fullScore: total,
        name: title,
        timeSpent: toHHMMSS(timespent)
      };
    });
    return formattedSessions;
  }
  return [];
}
