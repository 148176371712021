import { getRequest, postRequest, deleteRequest } from '../common/util'

export function getProblem(questionId) {
    return getRequest(`/questions/${questionId}/`)
}

export function submitResponse(data, timespent) {
    return postRequest('/submit-response/', data)
}

export function submitStandaloneResponse(questionId, answerId, timespent) {
    const data = {
        questionId: questionId,
        answerId: answerId,
        timespent: 0 // timespent
    }

    return postRequest('/submit-standalone-response/', data)
}

export function getVideoSolution(questionId) {
    const params = { questionId }
    return getRequest('/video-solutions/', params)
}

export function addQuestionNote(questionId, title, message) {
    const data = { type: 0, title, message }
    return postRequest(`/questions/${questionId}/notes/`, data)
}

export function getQuestionNotes(questionId) {
    return getRequest(`/questions/${questionId}/notes/`)
        .then(notes => {
            return notes.map(convertNote)
        })
}

export function getQuestionNote(noteId) {
    return getRequest(`/notes/${noteId}/`)
        .then(note => convertNote(note))
}

export function likeQuestionNote(noteId) {
    return postRequest(`/notes/${noteId}/likes/`)
        .then(note => convertNote(note))
}

export function unlikeQuestionNote(noteId) {
    return deleteRequest(`/notes/${noteId}/likes/`)
        .then(note => convertNote(note))
}

export function getSolutions(questionId) {
    return getRequest('/solutions/', { questionId: questionId })
}

export function getPremiumContents(questionId) {
    return getRequest(`/questions/${questionId}/premiums/`)
}

export function getMyNote(questionId) {
    return getRequest(`/questions/${questionId}/mynote/`)
}

export function updateMyNote(questionId, message) {
    return postRequest(`/questions/${questionId}/mynote/`, { message })
}

export function getWrongQuestions() {
    return getRequest('/questions/wrong')
}

export function getSessions() {
    return getRequest(`/sessions/`)
}

export function getSession(sessionId) {
    return getRequest(`/sessions/${sessionId}/`)
}

function convertNote(note) {
    return {
        id: note.id,
        title: note.title,
        viewCount: note.views_count,
        likeCount: note.likes_count,
        creator: note.student.email,
        type: note.type,
        text: note.message,
    }
}
