import React, { Component } from 'react'
import { connect } from 'react-redux'

import { TEST_STATUS } from '../../common/const'
import Card, { CardHeader } from '../../components/base/styled/Card'
import { TestTable } from '../../components/tables'
import { getTestResults } from '../../reducers/stat'

class SelectTestsView extends Component {
  componentWillMount() {
    this.props.setHeaders('ทำโจทย์แนวข้อสอบ')
    this.props.getTestResults()
  }
    
  render() {
    const { tests, testResults } = this.props
    return <div className='animated fadeInRight justify-content-center row'>
      <div className='col-lg-8 '>
        {TAGS.map(tag => {
          const targetTests = tests.filter(t => t.tag[0] === tag.tag)
          targetTests.forEach((t, index) => {
            t.index = index + 1
            const result = testResults[t.id]
            if (result) {
              t.score = result.highscore
              // t.fullScore = result.fullscore // I don't think we have this yet.
              //t.status = (result.highscore / result.fullscore > 0.7) 
              t.status = result.highscore / t.question_count > 0.7
                ? TEST_STATUS.SOLVED
                : TEST_STATUS.ATTEMPTED
            } else {
              t.status = TEST_STATUS.TODO
            }
          })

          return <div className='row' key={tag.text}>
            <div className='col'>
              <Card>
                <div style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
                  <CardHeader 
                    className="mb-0 mb-sm-4" 
                    title={`รวมแนวข้อสอบ ${tag.text}`} 
                  />
                </div>
                <TestTable 
                  testName={tag.text} 
                  tests={targetTests} 
                  push={this.props.router.push} 
                />
              </Card>
            </div>
          </div>
        })}
      </div>
    </div>
  }
}

function mapStateToProps(state) {
  return {
    tests: state.test.tests,
    testResults: state.stat.testResults
  }
}

export default connect(mapStateToProps, { getTestResults })(SelectTestsView)

const TAGS = [
  { id: 0, tag: 'onet', text: 'O-NET' },
  { id: 1, tag: 'pat', text: 'PAT1' },
  { id: 2, tag: 'saman', text: 'วิชาสามัญ' }
]
