import React, { Component } from 'react'
import HeatmapCell from './HeatmapCell'

export default class Heatmap extends Component {
    render() {
        const { progresses } = this.props

        // Seprate into 9 cells per line
        const formattedData = Object.keys(progresses).reduce((acc, k, index) => {
            if (index % 9 == 0) {
                acc.push([])
            }

            acc[acc.length-1].push(progresses[k].highest)
            return acc
        }, [])

        return (
            <div>
                { formattedData.map((r, index) => {
                    return (
                        <div className="row no-gutters justify-content-start" key={ index }>
                            { r.map((c, index) => {
                                return <HeatmapCell level={ c } key={ index }/>
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }
}