import * as payment from './payment';
import * as knowledge from './knowledge';
import * as student from './student';
import * as practice from './practice';
import * as stat from './stat';
import * as topic from './topic';
import * as other from './other';
import * as test from './test';
import * as exercise from './exercise';
import * as auth from './auth';
import * as tag from './tag';
import * as session from './session';
// package is the reserve word, therefore, we use packages instead.
import * as packages from './packages';

export default {
  payment,
  knowledge,
  topic,
  student,
  practice,
  stat,
  other,
  test,
  exercise,
  auth,
  tag,
  session,
  packages,
};
