import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import _ from 'lodash';

import { Button, Modal, Text, Flex } from '../base';
import Loading from '../common/Loading';

const ScoreText = styled(Text)`
  font-size: 48px;
  font-weight: 500;
`;

class PracticeSummaryModal extends Component {
  getScore(problems) {
    const total = problems.length;
    var score =
      problems && problems.length > 0
        ? problems.reduce((total, p) => {
            return p.result ? total + (p.result.isCorrect ? 1 : 0) : total;
          }, 0)
        : null;

    return [total, score];
  }

  isResultReady = (problems) => {
    const noResult = _.some(problems, (p) => !p.result);
    return !noResult;
  };

  render() {
    const { leaveSession, goToSolution, title, session, sessionResult, open, onClose } = this.props;

    const [total, score] = this.getScore(session.problems);
    return (
      <Modal open={open} onClose={onClose} closeIcon={false} disableBackdropClick={true}>
        {!this.isResultReady(session.problems) && score ? (
          <Loading />
        ) : (
          <>
            <Text.Title.Lg mb={5}>{title}</Text.Title.Lg>
            <Flex flexDirection={'column'} alignItems={'center'}>
              <Text.Title.Md>คุณทำข้อสอบเสร็จแล้ว</Text.Title.Md>
              <Text.Title.Md mb={4}>คะแนนของคุณคือ</Text.Title.Md>
              <ScoreText mb={4}>{`${score}/${total}`}</ScoreText>
              <Text.Title.Sm mb={5}>คะแนน</Text.Title.Sm>

              <Button.Primary.Main mb={3} width={'180px'} onClick={goToSolution}>
                ดูเฉลย
              </Button.Primary.Main>
              <Button.Secondary.Default width={'180px'} onClick={leaveSession}>
                ออกจากห้องสอบ
              </Button.Secondary.Default>
            </Flex>
          </>
        )}
      </Modal>
    );
  }
}

PracticeSummaryModal.propTypes = {
  title: PropTypes.string,
  leaveSession: PropTypes.func,
  goToSolution: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
  return {
    session: state.data.session,
    sessionResult: state.data.sessionResult,
  };
}

export default connect(mapStateToProps, null, null, { withRef: true })(PracticeSummaryModal);
