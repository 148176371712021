import React from 'react';
import PropTypes from 'prop-types';

import { Card, Text, Box } from '../../../components/base';

const BasicInfo = ({ email, jobTitle }) => {
  return (
    <Card>
      <Text.Title.Md mb={3}>ข้อมูลส่วนตัว</Text.Title.Md>
      <Text.Paragraph.Lg light={true}>
        <Text.Span width={'54px'} display={'inline-block'}>
          อีเมล:{' '}
        </Text.Span>
        {email}
      </Text.Paragraph.Lg>
      <Text.Paragraph.Lg light={true}>
        <Text.Span width={'54px'} display={'inline-block'}>
          อาชีพ:{' '}
        </Text.Span>
        {jobTitle}
      </Text.Paragraph.Lg>
    </Card>
  );
};

BasicInfo.propTypes = {
  email: PropTypes.string,
  jobTitle: PropTypes.string,
};

export default BasicInfo;
