import React from 'react';

import { COLOR } from '../../common/const';
import { getDifficulty } from '../../common/util';
import Tag from '../base/styled/Tag';

const DifficultyTag = ({ difficultyId, floatRight }) => {
  const difficulty = getDifficulty(difficultyId);
  if (!difficulty) {
    return null;
  }

  const color =
    difficulty.rank === 1
      ? COLOR.GREEN
      : difficulty.rank === 2
      ? COLOR.ORANGE
      : difficulty.rank === 3
      ? COLOR.RED
      : COLOR.ORANGE;

  const style = floatRight
    ? {
        marginRight: '0px',
        marginLeft: '0.5rem',
      }
    : null;
  return (
    <Tag small outline color={color} style={style} className={'hp-mb-05'}>
      <span style={{ whiteSpace: 'nowrap' }}>{difficulty.version}</span>
    </Tag>
  );
};

export default DifficultyTag;
