import React, { Component } from 'react';
import { connect } from 'react-redux';

import TopHeader from './TopHeader';
import TitleBar from './TitleBar';
import Loading from '../components/common/Loading';
import ContentWrapper from './ContentWrapper';
import FirstTimeModal from '../components/FirstTimeModal';
import FeedbackCreator from '../components/FeedbackCreator';
import apiClient from '../api';
import { getBookmarks } from '../reducers/bookmark';
import { getAllTopics, getAllDifficulties, getAllTags } from '../reducers/topic';
import { getAllTests } from '../reducers/test';
import { getAllExercises } from '../reducers/exercise';
import { getTestResults, getUserQuestionStat } from '../reducers/stat';
import { getUserInfo } from '../reducers/user';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: undefined,
    };
  }

  componentWillMount() {
    const {
      bookmarks,
      topics,
      difficulties,
      tags,
      tests,
      testResults,
      userInfo,
      exercises,
      userQuestionStat,
    } = this.props;
    if (!bookmarks) {
      this.props.getBookmarks();
    }

    if (!topics) {
      this.props.getAllTopics();
    }

    if (!difficulties) {
      this.props.getAllDifficulties();
    }

    if (!tags) {
      this.props.getAllTags();
    }

    if (!tests) {
      this.props.getAllTests();
    }

    if (!exercises) {
      this.props.getAllExercises();
    }

    if (!testResults) {
      this.props.getTestResults();
    }

    if (!userInfo) {
      this.props.getUserInfo();
    }

    if (!userQuestionStat) {
      this.props.getUserQuestionStat();
    }
  }

  componentDidMount() {
    this._checkForFirstTimeModal();
  }

  componentDidUpdate() {
    this._checkForFirstTimeModal();
  }

  _checkForFirstTimeModal = () => {
    if (this.isReady() && this.isFirst()) {
      this.refs.firstModal.openModal();
    }
  };

  postFirstLogin = () => {
    apiClient.student.postFirstLogin().then((res) => {
      this.props.getUserInfo();
    });
  };

  setHeaders = (title) => {
    // To use default value, use undefined.
    this.setState({
      title: title,
    });
  };

  isFirst = () => {
    const { userInfo } = this.props;
    return userInfo ? userInfo.meta.is_first_login : false;
  };

  isReady = () => {
    const {
      bookmarks,
      topics,
      difficulties,
      tags,
      tests,
      testResults,
      userInfo,
      exercises,
      userQuestionStat,
    } = this.props;
    return (
      bookmarks &&
      topics &&
      difficulties &&
      tags &&
      tests &&
      testResults &&
      userInfo &&
      exercises &&
      userQuestionStat
    );
  };

  render() {
    // NOTE: cloneElement() doesn't work with multiple children
    return (
      <div>
        <TopHeader />
        <TitleBar title={this.state.title} />
        {this.isReady() ? (
          <div className="app-wrapper gray-bg">
            <ContentWrapper>
              {React.cloneElement(this.props.children, {
                setHeaders: this.setHeaders,
              })}
            </ContentWrapper>
            <FeedbackCreator url={this.props.location.pathname} />
            {this.isFirst() ? (
              <FirstTimeModal
                ref="firstModal"
                postFirstLogin={this.postFirstLogin}
                beforeCloseHandler={this.postFirstLogin}
              />
            ) : null}
          </div>
        ) : (
          <Loading />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    bookmarks: state.bookmark.bookmarks,
    topics: state.topic.topics,
    difficulties: state.topic.difficulties,
    tags: state.topic.tags,
    tests: state.test.tests,
    exercises: state.exercise.exercises,
    testResults: state.stat.testResults,
    userInfo: state.user.userInfo,
    userQuestionStat: state.stat.userQuestionStat,
  };
}

const actions = {
  getBookmarks,
  getAllTopics,
  getAllDifficulties,
  getAllTags,
  getAllTests,
  getAllExercises,
  getTestResults,
  getUserInfo,
  getUserQuestionStat,
};

export default connect(mapStateToProps, actions)(App);
