import styled from 'styled-components';

import { Box } from '../../base';

const NavBarWrapper = styled(Box)`
  background-color: white;
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 28px 0px;
  z-index: 1000;
`;

export default NavBarWrapper;
