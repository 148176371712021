import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../../common/const';
import { Title } from '../../base/styled/Text';
import Text from '../../base/styled/Text';
import Flex from '../../base/styled/Flex';
import Label from '../../base/styled/Label';
import Icon from '../../base/styled/Icon';
import Bookmark from '../../common/Bookmark';
import TopicTags from '../../common/TopicTags';
import DifficultyTag from '../../common/DifficultyTag';

const CorrectCountTag = ({ correct }) => (
  <Text small green className="hp-mr-1" style={{ whiteSpace: 'nowrap' }}>
    <Icon size="16px" inherit className="fa fa-check hp-mr-02" />
    <span className="hidden-sm-down">ทำถูก</span> {correct}
    <span className="hidden-sm-down">ครั้ง</span>
  </Text>
);

const CompletedCountTag = ({ completed }) => (
  <Text small blue className="hp-mr-1" style={{ whiteSpace: 'nowrap' }}>
    <Icon size="16px" inherit className="fa fa-eye hp-mr-02" />
    <span className="hidden-sm-down">เคยทำ</span> {completed}
    <span className="hidden-sm-down">ครั้ง</span>
  </Text>
);

class Header extends Component {
  render() {
    const { index, questionId, questionStat, difficultyId, topicIds, testName } = this.props;

    const completed = questionStat ? questionStat.completed : 0;
    const correct = questionStat ? questionStat.correct : 0;
    return (
      <SubheaderBase row alignCenter>
        <Flex col between>
          <Flex row alignCenter between>
            <Title className="hp-mr-1" style={{ whiteSpace: 'nowrap' }}>
              ข้อ {index}
            </Title>
            <Flex row alignCenter widthAuto>
              <CompletedCountTag completed={completed} />
              <CorrectCountTag correct={correct} />
              <Bookmark questionId={questionId} variant={'button'} />
            </Flex>
          </Flex>
          <Flex row widthAuto className="hp-py-05" style={{ flexWrap: 'wrap' }}>
            <TestNameLabel testName={testName} />
            <DifficultyTag difficultyId={difficultyId} />
            <TopicTags topicIds={topicIds} />
          </Flex>
        </Flex>
      </SubheaderBase>
    );
  }
}

Header.propTypes = {
  index: PropTypes.number,
  questionId: PropTypes.string,
  questionStat: PropTypes.object,
  difficultyId: PropTypes.string,
  topicIds: PropTypes.array,
};

export default Header;

const TestNameLabel = ({ testName }) => {
  const color = testName.startsWith('O')
    ? COLOR.BLUE
    : testName.startsWith('P')
    ? COLOR.ORANGE
    : testName.startsWith('ส')
    ? COLOR.GREEN
    : COLOR.HIPPO;

  return (
    <Label small color={color} className={'hp-mb-05'} style={{ whiteSpace: 'nowrap' }}>
      {testName}
    </Label>
  );
};

const SubheaderBase = styled(Flex)`
  flex-direction: row;
  padding: 1rem 0;
  align-items: center;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;
