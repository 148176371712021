import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'

const SidebarList = styled.div`
    margin: 1rem 0;
`

export default SidebarList

SidebarList.propTypes = {
}

