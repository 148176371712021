import React from 'react';

import { AnalyticContext } from '../../contexts/AnalyticContext';

import LandingView from './Landing.view';

const Landing = (props) => {
  const analytic = React.useContext(AnalyticContext);
  React.useEffect(() => {
    analytic.logAction('landing', { page: 'landing-page' });
  }, [analytic]);

  return <LandingView />;
};

export default Landing;
