import React from 'react';
import styled from 'styled-components';
import Drawer from '@material-ui/core/Drawer';

import { Flex, Box, Text, RouterLink, Icon } from '../base';

import NavBarWrapper from './components/NavBarWrapper';
import NavBarContainer from './components/NavBarContainer';
import NavLogo from './components/NavLogo';

const HamburgerButtonContainer = styled(Box)`
  width: 48px;
  height: 48px;
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
const HamburgerButton = ({ onClick }) => {
  return (
    <HamburgerButtonContainer onClick={onClick}>
      <Icon name={Icon.ICON_LIST.MENU_BURGER} size={32} color={'black'} />
    </HamburgerButtonContainer>
  );
};

const DrawerMenuListItem = styled(RouterLink)`
  padding: 24px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0;
`;

const DrawerNavBar = ({ display, userInfo }) => {
  const [open, setOpen] = React.useState(false);
  const { firstName } = userInfo || {};

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <NavBarWrapper display={display}>
      <NavBarContainer>
        <NavLogo />
        <HamburgerButton onClick={handleOpen} />
        <Drawer open={open} onClose={handleClose}>
          <Flex flexDirection={'column'} width={'240px'} height={'100%'}>
            <Flex py={4} px={'20px'} backgroundColor={'primaries.light'}>
              <Icon name={Icon.ICON_LIST.FACE} size={24} mr={'6px'} />
              <Text.Title.Xs mb={'2px'} style={{ textTransform: 'uppercase' }}>
                {firstName}
              </Text.Title.Xs>
            </Flex>
            <DrawerMenuListItem onClick={handleClose} to={'/'}>
              <Icon name={Icon.ICON_LIST.SELECT_TEST} mr={2} size={20} color={'black'} />
              <Text.Paragraph>เลือกทำโจทย์</Text.Paragraph>
            </DrawerMenuListItem>
            {/* <DrawerMenuListItem onClick={handleClose} to={'/profile'}>
              <Icon name={Icon.ICON_LIST.STAT} mr={1} mr={2} size={20} color={'black'} />
              <Text.Paragraph>สถิติ</Text.Paragraph>
            </DrawerMenuListItem> */}
            <DrawerMenuListItem
              onClick={handleClose}
              to={'/account-info'}
              style={{ borderTop: '1px solid rgba(0,0,0,0.1)' }}
            >
              <Icon name={Icon.ICON_LIST.ACCOUNT_BOX} mr={2} size={20} color={'black'} />
              <Text.Paragraph>ข้อมูลผู้ใช้</Text.Paragraph>
            </DrawerMenuListItem>
            <DrawerMenuListItem onClick={handleClose} to={'/logout'}>
              <Icon name={Icon.ICON_LIST.LOG_OUT} mr={2} size={20} color={'black'} />
              <Text.Paragraph>ออกจากระบบ</Text.Paragraph>
            </DrawerMenuListItem>
          </Flex>
        </Drawer>
      </NavBarContainer>
    </NavBarWrapper>
  );
};

export default DrawerNavBar;
