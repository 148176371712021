import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AnswerSheetItemChoices from './AnswerSheetItemChoices'
import Text from '../../base/styled/Text'


export const INCORRECT = 'INCORRECT'
export const ENABLE = 'ENABLE'
export const DISABLE = 'DISABLE'
export const SELECTED = 'SELECTED'
export const CORRECT = 'CORRECT'

class AnswerSheetItem extends Component {
    render() {
        const { setProblemIndex, problemIndex, problem, index} = this.props
        if (!problem) {
            return null
        }
        
        const style = index === problemIndex ? SELECTED_STYLE : {}

        // TODO: Refactor this later ...
        let item
        if (problem.type === 0) {
            const selectedIndex = !problem.choices 
                ? null
                : problem.choices.reduce((res, c, index) => {
                    return c.id === problem.selectedChoiceId ? index : res
                }, null)

            const correctIndex = !problem.choices || !problem.result
                ? null
                : problem.choices.reduce((res, c, index) => {
                    return c.id === problem.result.answerKey ? index : res
                }, null)

            let status
            if (problem.completed) {
                status = problem.result.isCorrect ? CORRECT : INCORRECT
            } else {
                status = problem.selectedChoiceId ? SELECTED : ENABLE
            }

            item = <AnswerSheetItemChoices 
                count={ 5 } 
                selectedIndex={ selectedIndex } 
                correctIndex={ correctIndex } 
                status={ status } 
            />
        } else {
            let status
            if (problem.result) {
                status = problem.result.isCorrect ? SHORT_ANSWER_STATUS.CORRECT : SHORT_ANSWER_STATUS.WRONG
            } else {
                status = !problem.selectedChoiceId ? SHORT_ANSWER_STATUS.DEFAULT : SHORT_ANSWER_STATUS.ANSWERED
            }

            item = <div className='col-10'>
                <div className='row no-gutters'>
                    <AnswerSheetItemShortAnswer status={ status } answer={ problem.selectedChoiceId }/>
                </div>
            </div>
        }

        return (
            <div 
                className='row no-gutters answersheet-row' 
                onClick={ () => setProblemIndex(problemIndex) } 
                style={{ ...style }}
            >
                <div className='col-2 hp-pt-02 text-right' style={{ paddingRight:"0.2rem"}} >
                    <Text small bold>{ problemIndex + 1 }</Text>
                </div>
                { item }
            </div>
        )
    }
}

AnswerSheetItem.propTypes = {
    setProblemIndex: PropTypes.func,
    problemIndex: PropTypes.number,
    selectedIndex: PropTypes.number,
    correctIndex: PropTypes.number,
    status: PropTypes.oneOf([CORRECT, INCORRECT, ENABLE, DISABLE, SELECTED]) 
}

export default AnswerSheetItem


const SELECTED_STYLE = {
    backgroundColor: 'rgba(0,0,0,0.1)',
    WebkitBoxShadow: 'inset 0px 0px 0px 0px',
    MozBoxShadow: 'inset 0px 0px 0px 0px',
    boxShadow: 'inset 0px 0px 0px 0px',
} 

const AnswerSheetItemShortAnswer = ({ status, answer }) => {
    switch (status) {
        case SHORT_ANSWER_STATUS.ANSWERED:
            return <Text className="hp-ml-05 text-overflow-1" style={{ width: "75px" }}>{ answer }</Text>
        case SHORT_ANSWER_STATUS.CORRECT:
            return <Text green className="hp-ml-05 text-overflow-1">{ answer }<span className="hp-ml-05 fa fa-check"/></Text>
        case SHORT_ANSWER_STATUS.WRONG:
            return <Text red className="hp-ml-05 text-overflow-1">{ answer }<span className="hp-ml-05 fa fa-times"/></Text>
        default:
            return <div></div>
    }

}

const SHORT_ANSWER_STATUS = {
    DEFAULT: 0,
    ANSWERED: 1,
    CORRECT: 2,
    WRONG: 3
}
