import React from 'react';

import SubjectTestDetailContainer from './SubjectTestDetail.container';
import SubjectTestDetailView from './SubjectTestDetail.view';

const SubjectTestDetail = (props) => {
  return (
    <SubjectTestDetailContainer {...props}>
      {({ loading, testInfo, questions, handleStartSession, handleSelectQuestion, handleBookmark }) => {
        return (
          <SubjectTestDetailView
            loading={loading}
            testInfo={testInfo}
            questions={questions}
            onStartSession={handleStartSession}
            onSelectQuestion={handleSelectQuestion}
            onBookmark={handleBookmark}
          />
        );
      }}
    </SubjectTestDetailContainer>
  );
};

export default SubjectTestDetail;
