import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, Box, Flex } from '../../../components/base';

const PriceText = styled(Text)`
  font-size: 48px;
  font-weight: 500;
  display: inline-block;
`;
PriceText.defaultProps = {
  as: 'span',
};

const DisplayPrice = styled(Text)`
  font-size: 20px;
  font-weight: 400;
  color: #7f7f7f;
  text-decoration: line-through;
  position: absolute;
  top: -8px;

  &::after {
    content: ' บาท';
  }
`;

const Price = ({ price, displayPrice, ...restProps }) => {
  return (
    <Box style={{ position: 'relative' }} pt={1}>
      {displayPrice && displayPrice !== price && <DisplayPrice>{displayPrice}</DisplayPrice>}
      <Text.Title.Sm {...restProps}>
        <PriceText mr={1}>{price}</PriceText>
        บาท
      </Text.Title.Sm>
    </Box>
  );
};

export default Price;
