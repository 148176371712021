import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import Text from '../Text'

const FlatButton = styled(Text)`
    color: ${ COLOR.HIPPO };

    &:hover {
        color: ${ COLOR.LIGHT_HIPPO };
    }

    &:active {
        color: ${ COLOR.DARK_HIPPO };
    }

`

export default FlatButton

FlatButton.propTypes = {
    small: PropTypes.bool, // Title with font-size=1.25rem
}