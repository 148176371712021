import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { browserHistory, withRouter } from 'react-router';

import Flex from '../../components/base/styled/Flex';
import { Text } from '../../components/base';
import ConfirmModal from '../../components/common/ConfirmModal';
import SessionHeader from './SessionHeader';
import Subheader from './Subheader';
import Content from './Content';
import SessionSidebar from './SessionSidebar';

import { randomTestAnswers } from '../../actions/solve';

import PracticeSummaryModal from '../../components/practice/PracticeSummaryModal';
import Loading from '../../components/common/Loading';
import {
  killSession,
  getProblem,
  setProblemIndex,
  selectChoice,
  submitResponse,
  submitTest,
  postSessionResult,
  goToSolution,
} from '../../actions/solve';

const BACK_LOCATION = 'hp-go-back';

class SessionView extends Component {
  constructor(props) {
    super(props);
    this.subheader = React.createRef();
  }
  state = {
    secretState: 0,
    canLeave: this.props.isReviewSession ? true : false,
    nextLocation: null,
    summaryModalOpen: false,
  };

  wtf = (e) => {
    if (this.state.secretState === 0 && e.keyCode === 70) {
      this.setState({ secretState: 1 });
      return;
    }

    if (this.state.secretState === 1 && e.keyCode === 85) {
      this.setState({ secretState: 2 });
      return;
    }

    if (this.state.secretState === 2 && e.keyCode === 67) {
      this.setState({ secretState: 3 });
      return;
    }

    if (this.state.secretState === 3 && e.keyCode === 75) {
      this.props.randomTestAnswers();
    }

    this.setState({ secretState: 0 });
  };

  componentDidMount() {
    document.addEventListener('keydown', this.wtf, false);
    this.props.router.setRouteLeaveHook(this.props.route, this.preLeave);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.wtf, false);
  }

  preLeave = (nextLocation) => {
    const { canLeave } = this.state;
    if (!this.props.sessionInfo) {
      killSession();
      return true;
    }

    if (canLeave) {
      killSession();
      return true;
    }

    this.setState({ nextLocation }, () => {
      this.refs.confirmModal.openModal();
    });
    return false;
  };

  submitProblem = () => {
    const { session } = this.props;

    const index = session.index;
    const problem = session.problems[index];

    // this.refs.timer.stopTimer()
    // var timespent = this.refs.timer.state.elaspedSeconds
    this._submitResponse(session.id, index, problem.questionId, problem.selectedChoiceId);
  };

  submitTest = async () => {
    const { session, submitTest } = this.props;
    var data = {
      sessionId: session.id,
      answers: session.problems.map((p, index) => {
        return {
          questionId: p.questionId,
          answerId: p.selectedChoiceId,
          index: index,
          timespent: 0,
        };
      }),
    };

    this.subheader.current.stopTimer();
    const timespent = this.subheader.current.getElapsedTime();

    session.problems.forEach((p, index) => {
      if (!p.questionText) {
        this.props.getProblem(p.questionId, index);
      }
    });

    submitTest(data, session.id, timespent);
  };

  endSession = () => {
    this.submitTest();
    this.setState(
      {
        canLeave: true,
      },
      this.handleOpenSummaryModal()
    );
  };

  endSessionWithOutSubmit = () => {
    this.setState(
      {
        canLeave: true,
      },
      this.handleOpenSummaryModal()
    );
  };

  _submitResponse = (sessionId, index, questionId, answerId) => {
    const { submitResponse } = this.props;
    var data = {
      sessionId: sessionId,
      index: index,
      questionId: questionId,
      timespent: 0, //timespent,
      answerId: answerId,
    };

    submitResponse(data, null);
  };

  finishSession = () => {
    const { session, postSessionResult } = this.props;

    // Make sure to submit all problems
    // To get here we guaranteed all problems answered.
    session.problems
      .filter((p) => !p.completed)
      .forEach((p) => {
        this._submitResponse(session.id, p.index, p.questionId, p.selectedChoiceId);
      });

    postSessionResult(session.id);
    this.refs.subheader.refs.timer.stopTimer();
    this.setState(
      {
        canLeave: true,
      },
      this.handleOpenSummaryModal()
    );
  };

  leaveSession = () => {
    this.setState({ canLeave: true }, () => {
      if (this.state.nextLocation === BACK_LOCATION) {
        browserHistory.goBack();
      } else {
        browserHistory.push(this.state.nextLocation);
      }
    });
  };

  goToHomePage = () => {
    browserHistory.push('/');
  };

  handleClickLeaveSession = () => {
    if (this.state.canLeave) {
      browserHistory.goBack();
    } else {
      this.setState(
        {
          nextLocation: BACK_LOCATION,
        },
        this.refs.confirmModal.openModal()
      );
    }
  };

  goHome = () => {
    this.setState({ canLeave: true }, () => {
      browserHistory.goBack();
    });
  };

  handleOpenSummaryModal = () => {
    this.setState({ summaryModalOpen: true });
  };

  handleCloseSummaryModal = () => {
    this.setState({ summaryModalOpen: false });
  };

  render() {
    const {
      session,
      sessionInfo,
      getProblem,
      setProblemIndex,
      selectChoice,
      goToSolution,
    } = this.props;
    if (!sessionInfo && browserHistory) {
      this.setState({ canLeave: true }, browserHistory.push('/not-found'));
    }

    if (!session) {
      return <Loading />;
    }

    return (
      <_SessionWrapper>
        <Flex col className="min-h-100">
          <Flex between alignStart>
            <SessionHeader sessionTitle={session.title} />
            <Subheader
              ref={this.subheader}
              leaveSession={this.handleClickLeaveSession}
              duration={sessionInfo.duration}
              solutionMode={session.solutionMode}
              onSeeSolution={this.endSessionWithOutSubmit}
              onTimeUp={this.submitTest}
              isReviewSession={this.props.isReviewSession}
            />
          </Flex>
          <_Flex col between alignStart className="flex-lg-row">
            <SessionSidebar
              mode={session.mode}
              problems={session.problems}
              index={session.index}
              setProblemIndex={setProblemIndex}
              testName={session.title}
              submitTest={this.endSession}
              finishSession={this.finishSession}
              solutionMode={session.solutionMode}
            />
            <Content
              session={session}
              getProblem={getProblem}
              setProblemIndex={setProblemIndex}
              selectChoice={selectChoice}
              submitProblem={this.submitProblem}
              submitTest={this.endSession}
              finishSession={this.finishSession}
              onLeaveSession={this.handleClickLeaveSession}
              goToHomePage={this.goToHomePage}
            />
          </_Flex>
          <PracticeSummaryModal
            open={this.state.summaryModalOpen}
            onClose={this.handleCloseSummaryModal}
            title={session.title}
            leaveSession={this.goHome}
            goToSolution={() => {
              this.handleCloseSummaryModal();
              goToSolution();
            }}
          />
        </Flex>
        <ConfirmModal
          ref="confirmModal"
          title="ออกจากห้องสอบ?"
          onConfirm={this.leaveSession}
          height={'180px'}
          modalStyle={{
            minHeight: '240px',
            width: '600px',
            maxWidth: 'calc(100vw)',
            padding: '32px',
          }}
        >
          {session.solutionMode ? (
            <Text.Paragraph.Lg>คุณต้องการออกจากการทบทวนข้อสอบชุดนี้ใช่หรือไม่</Text.Paragraph.Lg>
          ) : (
            <Text.Paragraph.Lg>
              คุณยังไม่ได้ส่งกระดาษคำตอบ หากออกจากการทำข้อสอบ
              ข้อมูลการทำข้อสอบครั้งนี้ของคุณจะไม่ถูกบันทึก
            </Text.Paragraph.Lg>
          )}
        </ConfirmModal>
      </_SessionWrapper>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.data.session,
    sessionInfo: state.data.sessionInfo,
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    {
      killSession,
      getProblem,
      setProblemIndex,
      selectChoice,
      submitResponse,
      submitTest,
      postSessionResult,
      goToSolution,
      randomTestAnswers,
    },
    null,
    { withRef: true }
  )(SessionView)
);

const _SessionWrapper = styled(Flex)`
  min-height: calc(100vh - 161px);
  margin-bottom: 1.5rem;

  @media (max-width: 991px) {
    padding: 0;
    min-height: auto;
  }
`;

const _Flex = styled(Flex)`
  height: auto;
`;
