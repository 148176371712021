import React from 'react'
import PropTypes from 'prop-types'

const Bubble = ({ status, size, ...others }) => {
    let image
    switch(status) {
        case BUBBLE_STATUS.DISABLED:
            image = '/img/bubbleDisable.svg'
            break
        case BUBBLE_STATUS.ENABLED:
            image = '/img/bubbleMain.svg'
            break
        case BUBBLE_STATUS.WARNING:
            image = '/img/bubbleOrange.svg'
            break
        case BUBBLE_STATUS.CORRECT:
            image = '/img/bubbleGreen.svg'
            break
        case BUBBLE_STATUS.WRONG:
            image = '/img/bubbleRed.svg'
            break
        default:
            image = '/img/bubbleMain.svg'
            break
    }

    return <img src={ image } height={ size || "20px" } { ...others }/>
}

Bubble.propTypes = {
    status: PropTypes.number.isRequired
}

export default Bubble

export const BUBBLE_STATUS = {
    DISABLED: 0,
    ENABLED: 1,
    WARNING: 2,
    CORRECT: 3,
    WRONG: 4
}