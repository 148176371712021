import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router';
import PropTypes from 'prop-types';
import AnimateHeight from 'react-animate-height';
import ReactTooltip from 'react-tooltip';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import _ from 'lodash';

import { COLOR } from '../../../common/const';
import Text from '../../base/styled/Text';
import Flex from '../../base/styled/Flex';
import Header from './Header';
import Button from '../../base/styled/Button';
import Loading from '../../common/Loading';
import ParsedText from '../../common/ParsedText';
import ProblemChoice from '../ProblemChoice';
import ProblemTextInput from '../ProblemTextInput';
import Icon from '../../base/styled/Icon';
import VideoSolution from '../VideoSolution';
import Solutions from '../Solutions';
import { getOnlyNumeric, hackTestName } from '../../../common/util';
import config from '../../../common/config';
import apiClient from '../../../api';

const CalloutWrapper = styled.div`
  /* Styles are derived from blueprintjs <Callout /> */
  display: flex;
  align-items: center;
  background-color: rgba(217, 130, 43, 0.15);
  padding: 10px;
`

const Callout = ({children}) => {
  return <CalloutWrapper>
    <i class="fa fa-exclamation-triangle" aria-hidden="true" style={{ color: '#bf7326', marginRight: '8px' }}></i>
    {children}
  </CalloutWrapper>
}

class Do extends Component {
  state = {
    isSolutionVisible: false,
  };

  closeSolution = () => {
    if (this.refs.solutionTabPanel) {
      this.refs.solutionTabPanel.closeSolution();
    }
  };

  render() {
    const {
      questionId,
      questionText,
      choices,
      index,
      selectChoice,
      selectedChoiceId,
      completed,
      isCorrect,
      answerKey,
      mainButton,
      type,
      difficultyId,
      topicIds,
      testName,
      questionStat,
      alwaysShowSolution,
      user,
    } = this.props;

    if (!questionText || !choices) {
      return <Loading />;
    }
    const disabledSolution = !completed && !alwaysShowSolution;

    return (
      <Flex col alignCenter>
        <Flex col alignCenter>
          <Header
            index={index + 1}
            questionId={questionId}
            questionStat={questionStat[questionId]}
            difficultyId={difficultyId}
            topicIds={topicIds}
            testName={testName}
          />
          {/* <Flex col alignCenter style={{ overflow:"auto", flex: "0 0 100%", maxWidth: "100%" }}> */}
          <Tabs className="w-100">
            <TabList>
              <Tab>
                <Icon size="1rem" inherit className="fa fa-file-o hp-mr-05" />
                โจทย์
              </Tab>
              <Tab disabled={disabledSolution} data-tip data-for="solution">
                <Icon size="1rem" inherit className="fa fa-graduation-cap hp-mr-05" />
                เฉลย/โน้ต
                {disabledSolution ? (
                  <ReactTooltip id="solution" place="top" type="error">
                    ส่งคำตอบก่อนนะ
                  </ReactTooltip>
                ) : null}
              </Tab>
            </TabList>
            <TabPanel>
              <Flex col alignCenter className="hp-py-1">
                {/* <Text 
                                    className="align-self-start" 
                                    style={{ maxWidth:"90%" }}
                                > */}
                <ParsedText text={questionText} />
                {/* </Text> */}
                <ChoiceContainer col widthAuto>
                  {type === 0 ? (
                    choices.map((c, choiceIndex) => {
                      return (
                        <ProblemChoice
                          key={choiceIndex}
                          index={`${choiceIndex + 1}.`}
                          choiceId={c.id}
                          content={<ParsedText text={c.text} />}
                          onClick={() => selectChoice(index, c.id)}
                          selectedChoiceId={selectedChoiceId}
                          completed={completed}
                          isCorrect={isCorrect}
                          answerKey={answerKey}
                        />
                      );
                    })
                  ) : (
                    <ProblemTextInput
                      answer={selectedChoiceId || ''}
                      onChange={e => {
                        const value = getOnlyNumeric(e.target.value);
                        selectChoice(index, value);
                      }}
                      isCorrect={isCorrect}
                      answerKey={answerKey}
                    />
                  )}
                </ChoiceContainer>
                {mainButton}
              </Flex>
            </TabPanel>
            <TabPanel>
              <SolutionTabPanel
                ref="solutionTabPanel"
                isPremiumUser={user.is_premium}
                questionId={questionId}
                config={config}
              />
            </TabPanel>
          </Tabs>
          {/* </Flex> */}
        </Flex>
      </Flex>
    );
  }
}

Do.propTypes = {
  questionId: PropTypes.string,
  questionText: PropTypes.string,
  choices: PropTypes.array,
  index: PropTypes.number,
  selectedChoiceId: PropTypes.string,
  completed: PropTypes.bool,
  // If completed.
  isCorrect: PropTypes.bool,
  answerKey: PropTypes.string,
  solution: PropTypes.object,
  selectChoice: PropTypes.func,
  mainButton: PropTypes.node,
  type: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    questionStat: state.stat.userQuestionStat,
    user: state.user.userInfo,
  };
}

export default connect(
  mapStateToProps,
  null,
  null,
  { withRef: true }
)(Do);

const ChoiceContainer = styled(Flex)`
  @media (min-width: 992px) {
    min-width: 65%;
    max-width: 80%;
    padding: 0;
  }
  @media (max-width: 991px) {
    min-width: 80% !important;
    max-width: 100% !important;
    padding: 0;
  }
  @media (max-width: 767px) {
    min-width: 80% !important;
    max-width: 100% !important;
    padding: 0;
  }
  @media (max-width: 575px) {
    min-width: 100% !important;
    max-width: 100% !important;
    padding: 0;
  }
  margin: 2rem 0 1rem;
`;

const PREMIUM_SOLUTION_TYPE = {
  TEXT: 0,
  VIDEO: 1,
};

class SolutionTabPanel extends Component {
  state = {
    isSolutionVisible: false,
    userNotes: null,
    premiumContents: null,
  };

  componentWillMount() {
    this.updatePremiumContents(this.props.questionId);
  }

  componentWillReceiveProps(nextProps) {
    this.updatePremiumContents(nextProps.questionId);
  }

  updatePremiumContents = questionId => {
    apiClient.practice.getPremiumContents(questionId).then(premiumContents => {
      this.setState({ premiumContents });
    });
  };

  updateUserNotes = questionId => {
    apiClient.practice.getQuestionNotes(questionId).then(userNotes => {
      this.setState({ userNotes });
    });
  };

  toggleSolutionVisible = () => {
    this.setState({ isSolutionVisible: !this.state.isSolutionVisible });
  };

  closeSolution = () => {
    this.setState({ isSolutionVisible: false });
  };

  render() {
    const { questionId, config, isPremiumUser } = this.props;

    // if (!this.state.userNotes || !this.state.premiumContents) {
    if (!this.state.premiumContents) {
      return null;
    }

    return (
      <Flex col className="hp-mt-1">
        <Text bold hippo>
          เฉลยพรีเมี่ยม
        </Text>
        {!isPremiumUser && (
          <Callout intent={'warning'} className={'hp-mt-1'}>
            <Flex alignCenter>
              <Text className={'mr-2'} style={{ fontSize: '14px' }}>
                ต้องเป็นสมาชิกพรีเมี่ยมก่อน จึงจะสามารถดูเฉลยพรีเมี่ยมทั้งหมดได้
              </Text>
              <RouterLink to={'/account-info'}>
                <Button flat style={{ fontSize: '14px', cursor: 'pointer' }}>
                  สมัครเลย!
                </Button>
              </RouterLink>
            </Flex>
          </Callout>
        )}
        {this.state.premiumContents.length === 0 ? (
          <Text orange small>
            ยังไม่มีเฉลยพรีเมี่ยม
          </Text>
        ) : (
          <Flex widthAuto col alignStart className="hp-mt-1">
            {this.state.premiumContents.map((s, index) => {
              return <PremiumSolutionButton solution={s} key={index} />;
            })}
          </Flex>
        )}
        <MyNote questionId={questionId} />
      </Flex>
    );
  }
}

SolutionTabPanel.propTypes = {
  questionId: PropTypes.string,
};

class PremiumSolutionButton extends Component {
  state = {
    isSolutionVisible: false,
  };

  toggleSolutionVisible = () => {
    const { solution } = this.props;
    if (!solution.available) {
      return;
    }
    this.setState({ isSolutionVisible: !this.state.isSolutionVisible });
    //if (solution.type === PREMIUM_SOLUTION_TYPE.TEXT) {
    //this.setState({ isSolutionVisible: !this.state.isSolutionVisible })
    //} else if (solution.type === PREMIUM_SOLUTION_TYPE.VIDEO) {
    //this.refs.videoModal.getWrappedInstance().openModal()
    //}
  };

  render() {
    const { solution } = this.props;
    return (
      <PremiumSolutionButtonContainer ref="container" col available={solution.available}>
        <PremiumSolutionTitleContainer
          available={solution.available}
          row
          alignCenter
          onClick={this.toggleSolutionVisible}
        >
          <Icon
            size="1rem"
            style={{ width: '24px' }}
            className={`hp-ml-05 fa ${
              solution.type === PREMIUM_SOLUTION_TYPE.TEXT ? 'fa-graduation-cap' : 'fa-video-camera'
            }`}
          />
          <Flex col widthAuto className="hp-ml-1">
            <Text inherit>{hackTestName(solution.title)}</Text>
            <Text small grey>
              {solution.creator}
            </Text>
          </Flex>
          <Flex
            widthAuto
            alignCenter
            className="hp-ml-a"
            style={{ color: solution.available ? COLOR.GREEN : COLOR.LIGHT_GREY }}
          >
            <Icon inherit size="1rem" className="hp-px-05 fa fa-check-circle" />
            <Text small inherit>
              Premium
            </Text>
          </Flex>
        </PremiumSolutionTitleContainer>
        <AnimateHeight
          duration={this.state.isSolutionVisible ? 500 : 0}
          height={this.state.isSolutionVisible ? 'auto' : 0}
          style={{ width: '100%' }}
        >
          {solution.type === PREMIUM_SOLUTION_TYPE.TEXT ? (
            <Solutions
              questionId={solution.questionId}
              toggleVisible={() => this.toggleSolutionVisible()}
              isVisible={this.state.isSolutionVisible}
            />
          ) : (
            <VideoSolution questionId={solution.questionId} />
          )}
        </AnimateHeight>
      </PremiumSolutionButtonContainer>
    );
  }
}

const PremiumSolutionButtonContainer = styled(Flex)`
  background-color: ${props => (props.available ? 'transparent' : COLOR.LIGHT_GREY)};
  border: 1px solid ${props => (props.available ? COLOR.HIPPO : COLOR.LIGHT_GREY)};
  border-radius: 6px;
  margin-bottom: 1rem;
  padding: 0.5rem;
`;

const PremiumSolutionTitleContainer = styled(Flex)`
  color: ${props => (props.available ? COLOR.HIPPO : COLOR.LIGHT_GREY)};
  cursor: ${props => (props.available ? 'pointer' : 'not-allowed')};

  &:hover {
    color: ${props => (props.available ? COLOR.LIGHT_HIPPO : COLOR.LIGHT_GREY)};
  }

  &:active {
    color: ${props => (props.available ? COLOR.DARK_HIPPO : COLOR.LIGHT_GREY)};
  }
`;

class MyNote extends Component {
  state = {
    message: null,
    isSaving: false,
  };

  componentWillMount() {
    this.updateMyNote(this.props.questionId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.questionId !== this.props.questionId) {
      this.updateMyNote(nextProps.questionId);
    }
  }

  updateMyNote = questionId => {
    apiClient.practice.getMyNote(questionId).then(note => {
      this.setState({
        message: note.message,
      });
    });
  };

  save = _.debounce(() => {
    apiClient.practice.updateMyNote(this.props.questionId, this.state.message).then(() => {
      this.setState({ isSaving: false });
    });
  }, 500);

  handleMessageChange = e => {
    this.save();
    this.setState({
      message: e.target.value,
      isSaving: true,
    });
  };

  render() {
    if (this.state.message === null) {
      return null;
    }

    return (
      <div>
        <Flex row between alignCenter className="hp-mt-15">
          <Text bold>
            โน้ตของคุณ{' '}
            <Text grey className="d-inline">
              (โน้ตนี้จะเห็นเฉพาะคุณเท่านั้น)
            </Text>
          </Text>
          <Text inherit>{this.state.isSaving ? 'saving ...' : 'saved'}</Text>
        </Flex>
        <textarea
          style={{
            borderStyle: 'none',
            borderRadius: '8px',
            border: `1px solid ${COLOR.LIGHT_GREY}`,
            padding: '0.75rem 1rem',
            boxShadow: 'none',
            minHeight: '240px',
          }}
          className="hp-mb-2 hp-mt-15 w-100"
          onChange={this.handleMessageChange}
          name="message"
          placeholder="จดโน้ตของคุณ"
          value={this.state.message}
        />
      </div>
    );
  }
}
