import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { setSessionInfo } from '../actions/solve';
import PracticeInfoModal from '../components/practice/PracticeInfoModal';
import PracticeButton from '../components/practice/PracticeButton';
import { SESSION_TYPE_EXERCISE } from '../common/const';
import { translate, getDifficulty, updateQuestionsInfo } from '../common/util';
import Card, { CardHeader } from '../components/base/styled/Card';
import Flex from '../components/base/styled/Flex';
import Loading from '../components/common/Loading';
import apiClient from '../api';
import { ProblemTable } from '../components/tables';

class TopicInfoView extends Component {
  topicId = null;
  state = {
    logo: null,
    topic: null,
    questions: null,
    questionId: null,
  };

  componentWillMount() {
    this.topicId = this.props.params.topicId;
    this.updateTopic(
      this.props.topics,
      this.topicId,
      this.props.questionStat,
      this.props.bookmarks
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.topicId !== this.topicId) {
      this.topicId = nextProps.params.topicId;
      this.updateTopic(nextProps.topics, this.topicId, nextProps.questionStat, nextProps.bookmarks);
    } else {
      updateQuestionsInfo(this.state.questions, nextProps.questionStat, nextProps.bookmarks);
    }
  }

  updateTopic = (topics, topicId, questionStat, bookmarks) => {
    apiClient.topic.getTopicQuestions(topicId).then((questions) => {
      updateQuestionsInfo(questions, questionStat, bookmarks);
      this.setState({ questions });
    });

    const topic = topics.filter((t) => t.id === topicId)[0];
    this.setState({ topic });
    this.props.setHeaders(
      topic.name,
      '',
      ['Home', 'Practices', 'Exercises'],
      false,
      '/select-topics'
    );
  };

  renderPracticeButtons = () => {
    const { exercises } = this.props;
    const BUTTON_COUNT_PER_ROW = 4;

    let buttons = [];
    const topicExercises = exercises[this.topicId];
    if (topicExercises) {
      _.sortBy(topicExercises, [(e) => getDifficulty(e.meta.difficultyid).rank, 'name']);

      buttons = topicExercises.map((e) => {
        const [title, setId] = e.name.split(' - ');
        const pat = _.includes(e.tag, 'pat');
        const onet = _.includes(e.tag, 'onet');
        const saman = _.includes(e.tag, 'saman');

        const button = (
          <PracticeButton
            onClick={() => {
              this.props.setSessionInfo({
                type: SESSION_TYPE_EXERCISE,
                title: e.name,
                // subtitle: { subtitle },
                description: '',
                topicId: e.meta.topicid,
                difficultyId: e.meta.difficultyid,
                questionCount: e.question_count,
                exerciseId: e.id,
              });
              this.setState({
                logo: button,
              });
              this.refs.infoModal.openModal();
            }}
            title={title}
            subtitle={translate('tag', e.tag[0])}
            disabled={false}
            exercise
            pat={pat}
            onet={onet}
            saman={saman}
            points={setId}
            difficultyId={e.meta.difficultyid}
          />
        );

        return (
          <div key={e.id} className="d-flex flex-row justify-content-center w-100 w-sm-25">
            {button}
          </div>
        );
      });
    }

    // Grouped
    const grouped = [];
    const groupCount = Math.ceil(buttons.length / BUTTON_COUNT_PER_ROW);
    for (let i in _.range(groupCount)) {
      i = Number(i);
      const start = i * 4;
      const end = (i + 1) * 4;
      const g = buttons.slice(start, end);
      grouped.push(g);
    }

    return grouped.map((g, index) => {
      return (
        <div
          className="d-flex flex-column flex-sm-row justify-content-start hp-my-05 mb-sm-15"
          key={index}
        >
          {g}
        </div>
      );
    });
  };

  updateTopics = (questions) => {
    if (!questions) {
      return questions;
    }

    return questions.map((q) => {
      const targetTopic = _.find(q.category, { level: 2 });
      q.topic = targetTopic ? targetTopic.name : 'ไม่ระบุ';
      return q;
    });
  };

  render() {
    if (this.state.topic === null) {
      return <Loading />;
    }

    return (
      <div className="row animated fadeInRight">
        <div className="col-lg-4 hidden-md-down">
          <TopicInfoCard subtopics={this.state.topic.children} />
        </div>
        <div className="col-lg-8">
          <Card>
            <div style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
              <CardHeader className="mb-0 mb-sm-4" title={`โจทย์เรื่อง ${this.state.topic.name}`} />
            </div>
            <ProblemTable problems={this.state.questions || []} topicLevel={2} />
          </Card>
          <PracticeInfoModal ref="infoModal" logo={this.state.logo} />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    topics: state.topic.topics,
    tags: state.topic.tags,
    exercises: state.exercise.exercises,
    questionStat: state.stat.userQuestionStat,
    bookmarks: state.bookmark.bookmarkIds,
  };
}

export default connect(mapStateToProps, {
  setSessionInfo,
})(TopicInfoView);

const TopicInfoCard = ({ subtopics }) => (
  <Card className="hp-p-15">
    <CardHeader title="เนื้อหาที่เกี่ยวข้อง" />
    <Flex col>
      {subtopics.map((topic) => (
        <Flex alignCenter className="hp-mb-05" key={topic.id}>
          <i className="fa fa-circle hp-mr-05" style={{ fontSize: '0.25rem' }} />
          {topic.name}
        </Flex>
      ))}
    </Flex>
  </Card>
);

TopicInfoCard.propTypes = {
  subtopics: PropTypes.array.isRequired,
};
