import React from 'react';
import styled from 'styled-components';

import Box from './Box';

const Image = styled(Box)``;

Image.defaultProps = {
  as: 'img',
};

export default Image;
