import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { COLOR } from '../../common/const'
import Loading from '../common/Loading'

export const NO_FILTER_OPTION = {
  value: -1,
  label: 'ทั้งหมด'
}

class TableBase extends Component {
  constructor(props) {
    super(props)
      this.state = {
        columnWidthMap: [],
      }
      this.renderPagination = this.renderPagination.bind(this)
  }

  componentWillMount() {
    this.setState({
      columnWidthMap: this.generateColumnWidthMap(this.props.columns),
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      columnWidthMap: this.generateColumnWidthMap(nextProps.columns),
    })
  }

  generateColumnWidthMap(columns) {
    const totalColumnWidth = columns.reduce((sum, c) => {
      sum += c.width ? c.width : 1
      return sum
    }, 0)

    return columns.map(c => {
      const width = c.width
      return width 
        ? `${ width / totalColumnWidth * 100 }%` 
        : `${ 1 / totalColumnWidth * 100 }%`
    })
  }

  renderFilters() {
    const { setFilter, filtersWithOptions } = this.props
    if (filtersWithOptions.length < 1) { 
      return null 
    }

    return <div className="p-05">
      { filtersWithOptions.map((f, index) => {
          return (
              <selectWrap key={index} className="mr-1">
                  <select className="select-outline" ref={`filter-${f.key}`} onChange={() => setFilter(f.key)}>
                      {f.options.map((o, index) => {
                          return <option key={index} value={o.value}>{o.label}</option>
                      })}
                  </select>
              </selectWrap>
          )
      })}
    </div>
  }

    renderPagination() {
        const { page, length, selectPage, itemsPerPage } = this.props

        const pageCount = Math.ceil(length/itemsPerPage)
        const end = Math.min(page * itemsPerPage + itemsPerPage, length)
        const start = end === 0 ? 0 : page * itemsPerPage + 1

        return (
            <div className="d-flex flex-row align-items-center ml-a">
                <span className="mr-1" style={{ minWidth:"5rem" }}>{ start } - { end } of { length }</span>
                <button className="btn pagination-button-left mr-05" disabled={ page <= 0 } onClick={ () => selectPage(page - 1) }></button>
                <button className="btn pagination-button-right" disabled={ page + 1 >= pageCount } onClick={ () => selectPage(page + 1) }></button>
            </div>
        )
    }

  renderCell(render, row, accessor, subaccessor) {
    const value = row[accessor]
    if (!subaccessor) {
      return render ? render(value, row) : <p style={{ whiteSpace: 'nowrap' }}>{value}</p>
    }

    const multiSubAccessors = subaccessor.constructor === Array
    const subvalue = multiSubAccessors
      ? subaccessor.map(a => row[a])
      : row[subaccessor]

    return render 
      ? render(value, subvalue, row) 
      : <div>
          <p style={{ whiteSpace: 'nowrap' }}>{value}</p>
          <p className="f14 grey">{ multiSubAccessors ? subvalue[0] : subvalue }</p>
      </div>
  }

  renderSortArrow(sortOrder){
    return sortOrder ? <i className="fa fa-caret-down" /> : <i className="fa fa-caret-up" />
  }

    render() {
      const { 
        columns, 
        rowClickCallback, 
        values, 
        sortColumn, 
        noColumnHeader, 
        noHeader, 
        sortKey, 
        sortOrder, 
        setItemsPerPage, 
        noItemsPerPageSelector, 
        page, 
        length, 
        itemsPerPage, 
        selectPage 
      } = this.props
      const cursor = rowClickCallback ? "pointer" : "cursor"
      return (
        <div>
          { noHeader 
            ? null
            : <div className="d-flex flex-row justify-content-between align-items-center mb-1">
              { this.renderFilters() }
            </div>
          }
          <div style={{ overflowX: 'scroll' }}>
          <table className="table-main" style={{ tableLayout: "auto", backgroundColor: "transparent" }}>
            { noColumnHeader 
              ? null
              : <thead className="table-clickable-head" >
                <tr>
                  { columns.map((c, index) => {
                    if (c.visible === false) { return null }
                    return <th 
                      key={ index } 
                      onClick={ () => { sortColumn(c.accessor) }} 
                      style={{ 
                        maxWidth: '100%', 
                        width: this.state.columnWidthMap[index], 
                        whiteSpace: 'nowrap',
                        textAlign: c.center ? 'center' : null
                      }}
                    >
                      <span>{c.header} {sortKey === c.accessor ? (this.renderSortArrow(sortOrder)) : null}</span>
                    </th>
                  }) }
                </tr>
              </thead>
            }
            { !values
                ? <tbody><tr><td colSpan={ columns.length }><Loading /></td></tr></tbody>
                : <tbody className={ rowClickCallback ? "table-clickable" : "" }>
                    { values.map((row, index) => {
                        return (
                          <tr 
                            key={ index } 
                            style={{ cursor: cursor }} 
                            onClick={ () => {
                              if (rowClickCallback) {
                                rowClickCallback(row)
                              } 
                            }}
                          >
                            { columns.map((c, index) => {
                                if (c.visible === false) {
                                    return null
                                }
                                return <td 
                                  key={index} 
                                  style={{ 
                                    width: this.state.columnWidthMap[index] ,
                                    whiteSpace: 'nowrap',
                                    textAlign: c.center ? 'center' : null
                                  }}
                                >
                                  {this.renderCell(c.render, row, c.accessor, c.subaccessor)}
                                </td>
                            }) }
                          </tr>
                        )
                    })
                }
                </tbody>
            }
              </table>
            </div>
              {noHeader 
                ? null 
                : <TableFooter 
                  noHeader={ noHeader } 
                  noItemsPerPageSelector={ noItemsPerPageSelector } 
                  setItemsPerPage={ setItemsPerPage }
                  paginator={<Paginator page={page} pagesCount={Math.ceil(length/itemsPerPage)} selectPage={selectPage} />}
                  defaultItemsPerPage={itemsPerPage}
                /> 
              }
          </div>
      )
  }
}

TableBase.propTypes = {
  columns: PropTypes.array.isRequired,
  values: PropTypes.array,
  page: PropTypes.number.isRequired,
  length: PropTypes.number.isRequired,
  sortcolumn: PropTypes.func,
  setFilter: PropTypes.func,
  setItemsPerPage: PropTypes.func,
  selectPage: PropTypes.func,
  rowClickCallback: PropTypes.func,
  itemsPerPage: PropTypes.number,
  filtersWithOptions: PropTypes.array,
  noColumnHeader: PropTypes.bool,
  noHeader: PropTypes.bool,
  sortKey: PropTypes.string,
  sortOrder: PropTypes.bool,
  noItemsPerPageSelector: PropTypes.bool,
}

TableBase.defaultProps = {
  sortColumn: accessor => null,
  setFilter: key => null,
  selectPage: page => null,
}

export default TableBase

const TableFooter = ({ setItemsPerPage, noHeader, noItemsPerPageSelector, paginator, defaultItemsPerPage }) => {
  return <div>
    {noHeader 
      ? null
        : <div 
          style={{ borderTop: '1px solid #ddd', height: '4rem', backgroundColor: '#f8f8f8' }} 
          className="d-flex flex-row justify-content-between align-items-center mb-2 hp-p-1"
        >
          {noItemsPerPageSelector 
            ? null 
            : <ItemsPerPageSelector 
              defaultItemsPerPage={defaultItemsPerPage} 
              setItemsPerPage={setItemsPerPage} 
            /> 
          }
        { paginator }
      </div>
    }
  </div>
}

const ItemsPerPageSelector = ({ setItemsPerPage, defaultItemsPerPage }) => {
  return <div style={{ display: 'flex', alignItems: 'center' }}>
    <div className="bp3-select">
      <select defaultValue={defaultItemsPerPage} onChange={ e => setItemsPerPage(e.target.value) }>
        { ITEMS_PER_PAGE_OPTIONS.map(o => {
          return <option key={ o.value } value={ o.value }>{ o.label }</option>
        })}
      </select>
    </div>
    <div style={{ marginLeft: '0.5rem' }}>
      รายการ / หน้า
    </div>
  </div>
}

const ITEMS_PER_PAGE_OPTIONS = [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
]

const PAGINATION_ITEMS_PER_SIDE_COUNT = 2
const Paginator = ({ selectPage, page, pagesCount }) => {
  let min = page - PAGINATION_ITEMS_PER_SIDE_COUNT
  let max = page + PAGINATION_ITEMS_PER_SIDE_COUNT
  if (min < 0) {
    min = 0
    max += PAGINATION_ITEMS_PER_SIDE_COUNT - page
    if (max > pagesCount ) {
      max = pagesCount
    }
  } else if (max > pagesCount) {
    max = pagesCount
    min -= PAGINATION_ITEMS_PER_SIDE_COUNT - (pagesCount - page)
    if (min < 0) {
      min = 0
    }
  }

  return (
    <div className="bp3-button-group float-right">
      {page <= 0
        ? null
        : <a className="bp3-button bp3-icon-chevron-left" onClick={() => selectPage(page-1)} tabIndex="0" role="button"></a>
      }
      {_.range(min, max).map(p => {
        return p === page
          ? <a style={{ backgroundColor: COLOR.HIPPO }} className="bp3-button" tabIndex="0" role="button" key={p}>{p+1}</a>
          : <a className="bp3-button" onClick={() => selectPage(p)} tabIndex="0" role="button" key={p}>{p+1}</a>
      })}
      {page >= pagesCount - 1
        ? null
        : <a className="bp3-button bp3-icon-chevron-right" onClick={() => selectPage(page+1)} tabIndex="0" role="button"></a>
      }
    </div>
  )
}
