import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import theme from '../../../theme';
import { Button, Card, Text, Box, Flex, Icon } from '../../../components/base';
import { AnalyticContext } from '../../../contexts/AnalyticContext';

import Price from './Price';
import PackageDetailModal from './PackageDetailModal';

const Label = styled(Text)`
  font-size: 14px;
  font-weight: 500;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  background: linear-gradient(to right, #11a2a2, #51e6e8);
  position: absolute;
  right: 24px;
  top: 24px;
`;
const PackageEnrolledLabel = ({ expiryDate }) => {
  return (
    <Flex bg={'successes.light'} py={2} px={3} borderRadius={'8px'}>
      <Icon
        name={Icon.ICON_LIST.CHECK_CIRCLE}
        size={24}
        mr={2}
        color={theme.colors.successes.main}
      />
      <Box>
        <Text.Title.Xs color={'successes.main'} mb={1}>
          คุณซื้อแพ็คเกจนี้แล้ว
        </Text.Title.Xs>
        <Text.Caption color={'textLight'}>แพ็คเกจนี้ใช้ได้ถึง {expiryDate}</Text.Caption>
      </Box>
    </Flex>
  );
};

const PackageCard = ({
  id,
  name,
  price,
  displayPrice,
  description,
  isEnrolled,
  expiryDate,
  accessibleTestNames,
  detail,
  onPackageActivated,
}) => {
  const [detailModalOpen, setDetailModalOpen] = React.useState(false);

  const { logAction } = React.useContext(AnalyticContext);

  const handleOpenDetailModal = () => {
    setDetailModalOpen(true);
  };

  const handleCloseDetailModal = () => {
    setDetailModalOpen(false);
    logAction({
      category: 'Package',
      action: `Close Modal - See Detail - ${isEnrolled ? 'Enrolled' : 'Not Enrolled'}`,
      label: `Package: ${name}`,
    });
  };

  const handleClickBuyPackage = () => {
    handleOpenDetailModal();
    logAction({
      category: 'Package',
      action: 'Open Modal - Buy Package',
      label: `Package: ${name}`,
    });
  };

  const handleClickSeeDetail = () => {
    handleOpenDetailModal();
    logAction({
      category: 'Package',
      action: `Open Modal - See Detail - ${isEnrolled ? 'Enrolled' : 'Not Enrolled'}`,
      label: `Package: ${name}`,
    });
  };

  return (
    <>
      <Card
        border={'1px solid'}
        borderColor={isEnrolled ? 'successes.main' : 'white'}
        style={{ position: 'relative' }}
      >
        {displayPrice && price !== displayPrice && <Label>BEST DEAL</Label>}
        <Flex flexDirection={'column'} width={'100%'} height={'100%'}>
          <Price price={price} displayPrice={displayPrice} />
          <Text.Caption mb={3} mt={1} color={'textLight'}>
            ใช้ได้ถึง {expiryDate}
          </Text.Caption>
          <Text.Title.Sm mb={2} fontWeight={500}>
            {name}
          </Text.Title.Sm>
          <Text.Paragraph.Md mb={4} light={true}>
            {description}
          </Text.Paragraph.Md>
          <Button.Primary.Text onClick={handleClickSeeDetail} mb={4}>
            ดูรายละเอียด
          </Button.Primary.Text>
          <Box mt={'auto'}>
            {isEnrolled ? (
              <PackageEnrolledLabel expiryDate={expiryDate} />
            ) : (
              <Flex justifyContent={'center'} mb={2}>
                <Button.Primary.Main onClick={handleClickBuyPackage} px={5}>
                  ซื้อแพ็คเกจ
                </Button.Primary.Main>
              </Flex>
            )}
          </Box>
        </Flex>
      </Card>
      <PackageDetailModal
        open={detailModalOpen}
        onClose={handleCloseDetailModal}
        isEnrolled={isEnrolled}
        packageInfo={{ name, price, description, expiryDate, accessibleTestNames, id, detail }}
        onPackageActivated={onPackageActivated}
      />
    </>
  );
};

PackageCard.propTypes = {
  name: PropTypes.string,
  price: PropTypes.number,
  description: PropTypes.string,
  expiryDate: PropTypes.string,
  isEnrolled: PropTypes.bool,
  accessibleTestNames: PropTypes.array,
};
PackageCard.defaultProps = {
  accessibleTestNames: [],
};

export default PackageCard;
