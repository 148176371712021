import React, { Component } from 'react'

export default class ParsedText extends Component {
    createMarkup(text) {
        return {
            __html: text
        }
    }

    componentDidMount() {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
    }

    componentDidUpdate(prevProps, prevState) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
    }

    render() {
        return (
            <div className="parsed-text w-100 align-self-start">
                 <div dangerouslySetInnerHTML={ this.createMarkup(this.props.text) } /> 
            </div>
        )
    }
}

export const renderParsedText = {
  fontFamily: "'Open Sans', sans-serif",
  fontSize: '18px',
}