import React from 'react';
import { browserHistory } from 'react-router';

import { Button } from '../components/base';
// import FeedbackCreator from '../components/FeedbackCreator';
import { EmptyNavBar } from '../components/Navbar';
import ContentWrapper from './ContentWrapper';

const LeaveSessionButton = () => {
  const handleClick = () => {
    browserHistory.goBack();
  };
  return (
    <Button.Secondary.Default dense={true} onClick={handleClick}>
      ออกจากห้องสอบ
    </Button.Secondary.Default>
  );
};

const EmptyApp = ({ children, location }) => (
  <div>
    <EmptyNavBar mainButton={<LeaveSessionButton />} />
    <div className="app-wrapper gray-bg">
      <ContentWrapper>{children}</ContentWrapper>
      {/* <FeedbackCreator url={location.pathname} /> */}
    </div>
  </div>
);

export default EmptyApp;
