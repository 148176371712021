import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import Text from '../Text'

const _Link = styled(Text)`
    color: ${props => props.disabled ? COLOR.LIGHT_GREY : COLOR.HIPPO};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};

    &:hover {
        color: ${props => props.disabled ? COLOR.LIGHT_GREY : COLOR.LIGHT_HIPPO};
    }

    &:active {
        color: ${props => props.disabled ? COLOR.LIGHT_GREY : COLOR.DARK_HIPPO};
    }
`
const Link = _Link.withComponent('RouterLink')

export default Link

Link.propTypes = {
    disabled: PropTypes.bool, // Title with font-size=1.25rem
}