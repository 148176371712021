import React, { Component } from 'react'
import styled from 'styled-components'

import { COLOR } from '../common/const'
import Flex from '../components/base/styled/Flex'
import { Header } from '../components/base/styled/Text'
import Wrapper from './Wrapper'

export default class TitleBar extends Component {
    render() {
        return (
            <_Flex style={{ backgroundColor: COLOR.DARK_HIPPO }}>
                <Wrapper className="h-100">
                    <Flex alignCenter className="h-100">
                        { this.props.icon }
                        <Header  white>{ this.props.title }</Header>
                    </Flex>
                </Wrapper>
            </_Flex>
        )
    }
}

const _Flex = styled(Flex)`
    @media (max-width: 575px) {
        height: 94px;
    }
    @media (max-width: 991px) {
        padding: 0 1.5rem;
    }

    width: 100%;
    height: 7rem;
    padding: 0 1rem;
`