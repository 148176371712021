import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import apiClient from '../../../../api';
import { Text, Flex, Box } from '../../../base';

const NoteArea = styled('textarea')`
  border: 1px solid ${({ theme }) => theme.colors.greys.grey3};
  border-radius: 16px;
  min-height: 120px;
  box-shadow: none;
  padding: 24px;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 300;

  &::placeholder {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.greys.grey2};
  }
`;

class MyNote extends Component {
  state = {
    message: null,
    isSaving: false,
  };

  componentWillMount() {
    this.updateMyNote(this.props.questionId);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.questionId !== this.props.questionId) {
      this.updateMyNote(nextProps.questionId);
    }
  }

  updateMyNote = (questionId) => {
    apiClient.practice.getMyNote(questionId).then((note) => {
      this.setState({
        message: note.message,
      });
    });
  };

  save = _.debounce(() => {
    apiClient.practice.updateMyNote(this.props.questionId, this.state.message).then(() => {
      this.setState({ isSaving: false });
    });
  }, 500);

  handleMessageChange = (e) => {
    this.save();
    this.setState({
      message: e.target.value,
      isSaving: true,
    });
  };

  render() {
    if (this.state.message === null) {
      return null;
    }

    return (
      <Box>
        <Flex
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
          alignItems={'baseline'}
          mb={3}
        >
          <Text.Title.Sm>จดโน้ต (โน้ตนี้จะเห็นเฉพาะคุณเท่านั้น)</Text.Title.Sm>
          <Text.Subtitle.Sm color={'greys.grey2'} fontWeight={300}>
            {this.state.isSaving ? '...กำลังบันทึก' : 'บันทึกแล้ว'}
          </Text.Subtitle.Sm>
        </Flex>
        <NoteArea
          onChange={this.handleMessageChange}
          name="message"
          placeholder="จดโน้ตของคุณ"
          value={this.state.message}
        />
      </Box>
    );
  }
}

export default MyNote;
