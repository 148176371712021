import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'

const IconBase = styled.i`
    font-size: ${props => props.size || '1rem' };
    width: ${props => props.width || ''};
    color: ${props => props.hippoColor ? COLOR.HIPPO : props.inherit ? "inherit" : COLOR.LIGHT_GREY};
    margin: 0;
    border: 0;
`

IconBase.propTypes = {
    size: PropTypes.string, // Define size of Icon for fontIcon ex. fontawesome
    width: PropTypes.string, // Define overall width of Icon
    hippoColor: PropTypes.bool, // Define Icon color as COLOR.HIPPO or COLOR.LIGHT_GREY
    inherit: PropTypes.bool
}

export default IconBase
