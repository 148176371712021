import React from 'react';

import AccountInfoV1Container from './AccountInfoV1.container';
import AccountInfoV1View from './AccountInfoV1.view';

const AccountInfoV1 = (props) => {
  return (
    <AccountInfoV1Container {...props}>
      {({ userInfo, packages, onPackageActivated }) => {
        return (
          <AccountInfoV1View
            userInfo={userInfo}
            packages={packages}
            onPackageActivated={onPackageActivated}
          />
        );
      }}
    </AccountInfoV1Container>
  );
};

export default AccountInfoV1;
