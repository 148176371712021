import React, { Component } from 'react';

import ContentWrapper from './ContentWrapper';

import { EmptyNavBar } from '../components/Navbar';
import { Button, RouterLink } from '../components/base';

export default class PublicApp extends Component {
  render() {
    // NOTE: cloneElement() doesn't work with multiple children
    return (
      <div>
        <EmptyNavBar
          mainButton={
            <RouterLink to={'/login'}>
              <Button.Primary.Default>เข้าสู่ระบบ</Button.Primary.Default>
            </RouterLink>
          }
        />
        <div className="app-wrapper gray-bg" style={{ height: 'calc( 100vh - 114px )' }}>
          <ContentWrapper>{React.cloneElement(this.props.children)}</ContentWrapper>
        </div>
      </div>
    );
  }
}
