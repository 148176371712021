import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, Text, Box, Flex, Modal, Icon } from '../../../components/base';
import { AnalyticContext } from '../../../contexts/AnalyticContext';
import theme from '../../../theme';
import apiClient from '../../../api';

const SuccessModal = ({ packageName, open, onClose }) => {
  return (
    <Modal size={'xs'} open={open} onClose={onClose} closeIcon={false} disableBackdropClick={true}>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Icon name={Icon.ICON_LIST.CHECK_CIRCLE} size={48} color={theme.colors.success} mb={3} />
        <Text.Title.Md mb={2} textAlign={'center'}>
          ซื้อแพ็คเกจเรียบร้อย
        </Text.Title.Md>
        <Text.Paragraph textAlign={'center'} light={true}>
          คุณสามารถใช้งานแพ็คเกจ <br />
          <Text.Span fontWeight={500} display={'inline'}>
            {packageName}
          </Text.Span>
          <br />
          ได้แล้ว
        </Text.Paragraph>
      </Flex>
    </Modal>
  );
};

const CodeInput = styled(Box)`
  height: 40px;
  width: 180px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: black;
  border: 1px solid ${({ theme }) => theme.colors.greys.grey4};
  border-radius: 4px;

  &::placeholder {
    color: ${({ theme }) => theme.colors.greys.grey4};
  }

  &::focus {
    border-color: ${({ theme }) => theme.colors.primary};
  }
  &:disabled {
    color: ${({ theme }) => theme.colors.greys.grey4};
  }
`;
CodeInput.defaultProps = {
  as: 'input',
};

const ActivatePackageForm = ({ packageId, packageName, onSuccess, onError }) => {
  const [code, setCode] = React.useState('');
  const [error, setError] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [successModalOpen, setSuccessModalOpen] = React.useState(false);

  const { logAction } = React.useContext(AnalyticContext);

  const handleConfirmCode = async () => {
    logAction({
      category: 'Package',
      action: 'Activate',
      label: `Package: ${packageName}`,
    });
    setLoading(true);
    try {
      const res = await apiClient.packages.validatePackageCode(code);
      const { success, message, packageId: codePackageId } = res;
      if (!success) {
        const errorMessage = message ? message : 'โค้ดนี้ไม่สามารถใช้ได้ โปรดตรวจสอบอีกครั้ง';
        logAction({
          category: 'Package',
          action: 'Error',
          label: `Package: ${packageName} - ${errorMessage}`,
        });
        setError(errorMessage);
        setLoading(false);
      } else if (success && codePackageId !== packageId) {
        const errorMessage = 'โค้ดนี้ไม่สามารถใช้กับแพ็คเกจนี้ได้ โปรดตรวจสอบแพ็คเกจอีกครั้ง';
        logAction({
          category: 'Package',
          action: 'Error',
          label: `Package: ${packageName} - ${errorMessage}`,
        });
        setError(errorMessage);
        setLoading(false);
      } else if (success && codePackageId === packageId) {
        try {
          const res = await apiClient.packages.activatePackage(code);
          logAction({
            category: 'Package',
            action: 'Activated',
            label: `Package: ${packageName}`,
          });
          onSuccess();
          handleOpenSuccessModal();
        } catch (err) {
          console.error(err);
          const errorMessage = 'มีบางอย่างผิดพลาด โปรดลองใหม่อีกครั้ง';
          logAction({
            category: 'Package',
            action: 'Error',
            label: `Package: ${packageName} - ${errorMessage}`,
          });
          setError(errorMessage);
          setLoading(false);
        }
      }
    } catch (err) {
      console.error(err);
      const errorMessage = 'มีบางอย่างผิดพลาด โปรดลองใหม่อีกครั้ง';
      logAction({
        category: 'Package',
        action: 'Error',
        label: `Package: ${packageName} - ${errorMessage}`,
      });
      setError(errorMessage);
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    if (!code) {
      logAction({
        category: 'Package',
        action: 'Input Code',
        label: `Package: ${packageName}`,
      });
    }
    setCode(value);
    if (error) {
      setError(null);
    }
  };

  const handleOpenSuccessModal = () => {
    setSuccessModalOpen(true);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };
  return (
    <>
      <Flex style={{ position: 'relative' }} pb={3}>
        <CodeInput
          value={code}
          placeholder={'ใส่โค้ดที่นี่'}
          disabled={loading}
          onChange={handleChange}
          error={error}
          mr={2}
        />
        <Button.Primary.Default
          onClick={handleConfirmCode}
          dense={true}
          loading={loading}
          width={'150px'}
        >
          ยืนยันโค้ด
        </Button.Primary.Default>
        <Text.Paragraph.Sm
          color={'danger'}
          style={{ position: 'absolute', bottom: '-4px', left: 0 }}
        >
          {error}
        </Text.Paragraph.Sm>
      </Flex>
      <SuccessModal
        open={successModalOpen}
        onClose={handleCloseSuccessModal}
        packageName={packageName}
      />
    </>
  );
};

export default ActivatePackageForm;
