import React from 'react';

import { Button, Text, Box, Modal, List, Divider } from '../../../components/base';
import { MARCHMELLOW_FACEBOOK_URL } from '../../../common/const';

import Price from './Price';
import ActivatePackageForm from './ActivatePackageForm';
import { AnalyticContext } from '../../../contexts/AnalyticContext';

const AccessibleTestModal = ({ packageName, accessibleTestNames }) => {
  const [open, setOpen] = React.useState(false);
  const { logAction } = React.useContext(AnalyticContext);

  const handleOpenModal = () => {
    setOpen(true);
    logAction({
      category: 'Package',
      action: 'Open Modal - Accessible Test',
      label: `Package: ${packageName}`,
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
    logAction({
      category: 'Package',
      action: 'Close Modal - Accessible Test',
      label: `Package: ${packageName}`,
    });
  };
  return (
    <>
      <Modal open={open} onClose={handleCloseModal} size={'xs'}>
        <Text.Title.Sm mb={3}>ชุดข้อสอบที่ดูวิดีโอเฉลยได้ในแพ็คเกจนี้</Text.Title.Sm>
        <List>
          {accessibleTestNames.map((testName) => {
            return (
              <List.Item key={testName}>
                <Text.Span>{testName}</Text.Span>
              </List.Item>
            );
          })}
        </List>
      </Modal>
      <Button.Primary.Text onClick={handleOpenModal} display={'inline-block'}>
        ดูชุดข้อสอบที่ดูวิดีโอเฉลยได้ในแพ็คเกจนี้
      </Button.Primary.Text>
    </>
  );
};

const getPackageDetails = (price, description, accessibleTestNames, expiryDate, isEnrolled) => {
  return [
    `แพ็คเกจนี้มีราคา ${price} บาท`,
    `เมื่อซื้อแพ็คเกจนี้คุณจะสามารถ ${description} `,
    `แพ็คเกจนี้สามารถใช้ได้ถึง ${expiryDate}`,
  ];
};

const PackageDetailModal = ({
  open,
  onClose,
  onPackageActivated,
  disableBackdropClick,
  isEnrolled,
  packageInfo,
}) => {
  const { name, price, description, expiryDate, id, accessibleTestNames, detail } =
    packageInfo || {};

  const packageDetails = detail ? detail : [];

  const { logAction } = React.useContext(AnalyticContext);

  const handleClickContactUs = () => {
    logAction({
      category: 'Package',
      action: 'Contact Us',
      label: `Package: ${name}`,
    });
  };

  return (
    <Modal open={open} onClose={onClose} disableBackdropClick={disableBackdropClick}>
      <Box>
        <Text.Paragraph.Sm color={'greys.grey2'}>แพ็คเกจ</Text.Paragraph.Sm>
        <Text.Title.Md mb={3}>{name}</Text.Title.Md>
        <Price price={price} mb={4} />

        <Text.Title.Sm mb={2}>รายละเอียด</Text.Title.Sm>
        <List>
          {packageDetails.map((detail) => {
            return <List.Item key={detail}>{detail}</List.Item>;
          })}
          <List.Item>
            <AccessibleTestModal accessibleTestNames={accessibleTestNames} packageName={name} />
          </List.Item>
        </List>

        {!isEnrolled ? (
          <>
            {' '}
            <Divider />
            <Text.Title.Sm mb={2}>วิธีการซื้อ</Text.Title.Sm>
            <List as={'ol'}>
              <List.Item bulletContent={'1.'}>
                <Button.Primary.Text
                  display={'inline-block'}
                  mr={2}
                  forwardedAs={'a'}
                  href={MARCHMELLOW_FACEBOOK_URL}
                  target={'_blank'}
                  onClick={handleClickContactUs}
                >
                  ติดต่อเรา
                </Button.Primary.Text>
                เพื่อซื้อโค้ดสำหรับเริ่มใช้งานแพ็คเกจนี้ <br />
                <Text.Span color={'textLight'}>(เราจะตอบกลับหาคุณภายใน 24 ชม.)</Text.Span>
              </List.Item>
              <List.Item bulletContent={'2.'}>
                นำโค้ดที่ได้มากรอกลงในช่องด้านล่างแล้วกด{' '}
                <Text.Span fontWeight={500} display={'inline'}>
                  ยืนยันโค้ด
                </Text.Span>
              </List.Item>
            </List>
            <Text.Paragraph.Md mt={4} mb={2}>
              ใส่โค้ดด้านล่าง เพื่อเริ่มใช้งานแพ็คเกจนี้
            </Text.Paragraph.Md>
            <ActivatePackageForm packageId={id} onSuccess={onPackageActivated} packageName={name} />
          </>
        ) : null}
      </Box>
    </Modal>
  );
};

export default PackageDetailModal;
