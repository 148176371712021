import React, { Component } from 'react'
import Modal from 'react-modal'

import { getDisplayName } from '../common/util'
import { ModalStyle } from '../common/const'

export function inModal(ModalContentComponent) {
    class InModal extends Component {
        constructor(props) {
            super(props)
            this.state = {
                showModal: false
            }

            this.openModal = this.openModal.bind(this)
            this.closeModal = this.closeModal.bind(this)
            this.toggleModal = this.toggleModal.bind(this)
        }

        openModal() {
            this.setState({
                showModal: true
            })
        }

        closeModal() {
            this.setState({
                showModal: false
            })
        }

        toggleModal() {
            this.setState({
                showModal: !this.state.showModal
            })
        }

        render() {
            const { beforeCloseHandler, modalStyle, ...passThroughProps } = this.props
            const contentStyle = ModalStyle.content
            const overlayStyle = ModalStyle.overlay
            const styles = {
                overlay: { ...overlayStyle }, 
                content: { ...contentStyle, ...modalStyle }
            }
            return (
                <Modal
                    isOpen={this.state.showModal}
                    onRequestClose={() => {
                        if (beforeCloseHandler) {
                            beforeCloseHandler()
                        }

                        this.closeModal()
                    }}
                    contentLabel="modal"
                    className="do-modal"
                    style={styles}
                >
                    <ModalContentComponent 
                        closeModal={this.closeModal} 
                        openModal={this.openModal}
                        visible={this.state.showModal}
                        {...passThroughProps}
                    />
                </Modal>
            )
        }
    }

    InModal.displayName = `InModal(${getDisplayName(ModalContentComponent)})`
    return InModal
}
