import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { Router, browserHistory } from 'react-router';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import reducers from './reducers';
import getRoutes from './routes';
import promise from 'redux-promise';
import thunk from 'redux-thunk';
import 'react-tabs/style/react-tabs.css';
import { ThemeProvider } from 'styled-components';
import { AnalyticProvider } from './contexts/AnalyticContext';
import theme from './theme';

const finalCreateStore = compose(applyMiddleware(promise), applyMiddleware(thunk))(createStore);

export const store = finalCreateStore(reducers);

// Format dayjs to th globally
dayjs.locale('th');

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <AnalyticProvider>
        <Router history={browserHistory} routes={getRoutes(store)} />
      </AnalyticProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);
