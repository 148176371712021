import styled from 'styled-components'

import Wrapper from './Wrapper'

const ContentWrapper = styled(Wrapper)`
    @media (min-width: 992px) {
        padding-top: 1.5rem !important;
    }
    padding-top: 0;
`

export default ContentWrapper
