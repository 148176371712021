import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dialog from '@material-ui/core/Dialog';

import Box from './Box';
import Icon from './Icon';
import Button from './Button';

const ModalPaper = styled(Box)`
  position: relative;
  background-color: white;
  padding: 40px 16px 32px;
  border-radius: 16px;
  /* border-radius: 0; */
  width: 100% !important;
  /* height: 100%; */
  max-height: calc(100% - 48px) !important;
  margin: 0 !important;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 56px 40px 48px;
    width: calc(100% - 64px) !important;
    max-height: calc(100% - 64px) !important;
    border-radius: 16px;
    margin: 32px !important;
    height: fit-content;
  }
`;

const CloseIconButton = styled(Button.Secondary.Flat)`
  position: absolute;
  top: 12px;
  right: 12px;
`;

const CloseIcon = ({ onClick }) => {
  return (
    <CloseIconButton onClick={onClick} square={true} dense={true} width={'48px'} height={'48px'}>
      <Icon name={Icon.ICON_LIST.CLOSE} size={24} />
    </CloseIconButton>
  );
};

const Modal = ({ open, onClose, children, size, fullWidth, closeIcon, disableBackdropClick }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={ModalPaper}
      fullWidth={fullWidth}
      maxWidth={size}
      disableBackdropClick={disableBackdropClick}
    >
      {closeIcon && <CloseIcon onClick={onClose} />}
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  fullWidth: PropTypes.bool,
  closeIcon: PropTypes.bool,
};

Modal.defaultProps = {
  size: 'sm',
  fullWidth: true,
  closeIcon: true,
};

export default Modal;
