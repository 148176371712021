import _ from 'lodash'

import apiClient from '../api'

const BOOKMARK = 'BOOKMARK'
const UNBOOKMARK = 'UNBOOKMARK'
const GET_BOOKMARKS = 'GET_BOOKMARKS'

export function bookmark(questionId) {
    return dispatch => {
        dispatch({
            type: BOOKMARK,
            questionId: questionId
        })

        return apiClient.other.bookmark(questionId)
            .then(res => {
                dispatch(getBookmarks())
            })
    }
}

export function unbookmark(questionId) {
    return dispatch => {
        dispatch({
            type: UNBOOKMARK,
            questionId: questionId
        })

        return apiClient.other.unbookmark(questionId)
            .then(res => {
                dispatch(getBookmarks())
            })
    }
}

export function getBookmarks() {
    return dispatch => {
        apiClient.other.getBookmarks()
            .then(bookmarks => {
                dispatch({
                    type: GET_BOOKMARKS,
                    bookmarks: bookmarks
                })
            })
    }
}

const ACTION_HANDLERS = {
    [BOOKMARK]: handleBookmark,
    [UNBOOKMARK]: handleUnbookmark,
    [GET_BOOKMARKS]: handleGetBookmarks
}

function handleBookmark(state, action) { 
    const bookmarks = _.concat(state.bookmarks, action.questionId)
    return { ...state, bookmarks: bookmarks, bookmarksFromServer: false }
}

function handleUnbookmark(state, action) {
    const bookmarks = _.without(state.bookmarks, action.questionId)
    return { ...state, bookmarks: bookmarks, bookmarksFromServer: false }
}

function handleGetBookmarks(state, action) {
    const bookmarks = action.bookmarks.map(b => b.question)
    const bookmarkIds = new Set(bookmarks)

    return { 
        ...state, 
        bookmarks: bookmarks, 
        bookmarkIds: bookmarkIds,
        bookmarksFromServer: true 
    }
}

const INITIAL_STATE = {
    bookmarks: null,
    bookmarksFromServer: false
}

export default function(state=INITIAL_STATE, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}