import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'

import { logout } from '../reducers/auth'
import Card from '../components/base/styled/Card'
import { Title } from '../components/base/styled/Text'
import Flex from '../components/base/styled/Flex'

class LogoutView extends Component {
    componentWillMount() {
        this.props.logout()
        _.delay( browserHistory.push, 3000, '/login')
    }

    render() {
        return (
            <div className="animated fadeInRight">
                <Flex center>
                    <Card className="hp-p-3" style={{ maxWidth:"100vw", minHeight:"100%" }}> 
                        <Flex col alignCenter>
                            <img width="240px" src="/img/hippoGraphic.svg" />
                            <Title className="hp-mt-2">แล้วเจอกันครับ!</Title>
                        </Flex>
                    </Card>
                </Flex>
            </div>
        )
    }
}

export default connect(null, {
    logout
})(LogoutView)
