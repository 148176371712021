import React, { Component } from 'react';
import styled from 'styled-components';

import Button from '../base/styled/Button';
import Text from '../base/styled/Text';
import Table from './Table';

const CellText = styled(Text)`
  font-size: 16px;
  font-weight: 400;
  padding: 6px 4px;
`;

class RecentTestTable extends Component {
  columns = [
    {
      header: 'วันที่ทำ',
      accessor: 'date',
      width: 1.5,
      render: (date) => <CellText>{date}</CellText>,
    },
    {
      header: 'ชุดแนวข้อสอบ',
      accessor: 'name',
      width: 2,
      render: (name) => <CellText>{name}</CellText>,
    },
    {
      header: 'คะแนนที่ได้',
      accessor: 'score',
      width: 1,
      render: (score) => <CellText>{score}</CellText>,
    },
    {
      header: '',
      accessor: 'id',
      width: 1,
      render: () => (
        <Button outline style={{ padding: '8px 16px' }}>
          ทบทวน
        </Button>
      ),
    },
  ];

  handleSelectTest = (test) => {
    this.props.push(`/select-tests/${test.id}`);
  };

  render() {
    const { tests } = this.props;

    return (
      <div>
        <Table
          columns={this.columns}
          values={tests}
          rowClickCallback={(t) => this.handleSelectTest(t)}
        />
      </div>
    );
  }
}

export default RecentTestTable;
