import React from 'react';
import PropTypes from 'prop-types';

import { AppContainer, Text, Box } from '../../components/base';

import BasicInfo from './components/BasicInfo';
import MyPackages from './components/MyPackages';

const AccountInfoView = ({ userInfo, packages, onPackageActivated }) => {
  const { displayName, email, jobTitle } = userInfo || {};
  return (
    <AppContainer>
      <Box mb={5}>
        <Text.Title.Lg mb={3} as={'h1'}>
          {displayName}
        </Text.Title.Lg>
        <BasicInfo email={email} jobTitle={jobTitle} />
      </Box>
      <Box>
        <Text.Title.Lg mb={3} as={'h1'}>
          แพ็คเกจ
        </Text.Title.Lg>
        <MyPackages packages={packages} onPackageActivated={onPackageActivated} />
      </Box>
    </AppContainer>
  );
};

AccountInfoView.propTypes = {
  userInfo: PropTypes.object,
  packages: PropTypes.array,
};
AccountInfoView.defaultProps = {
  packages: [],
};
export default AccountInfoView;
