export const addResultsToTests = (subjectTests, testResults) => {
  const testsWithResult = subjectTests.map((test) => {
    const { id } = test;
    const result = testResults[id];
    const { fullScore, highscore } = result || {};
    return { highScore: highscore, fullScore, ...test };
  });

  return testsWithResult;
};
