import styled from 'styled-components';

import Box from './Box';

const AppContainer = styled(Box)`
  max-width: 768px;
  margin: 0 auto;
  padding: 32px 12px 32px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    padding: 48px 8px 32px;
  }
`;

export default AppContainer;
