import React, { Component } from 'react'
import { browserHistory } from 'react-router'
import _ from 'lodash'

import apiClient from '../api'

export default class ActivateAccountView extends Component {
    constructor(props){
        super(props)
        this.state = {
            status: undefined
        }
    }

    componentWillMount() {
        const code = this.props.location.query.code
        if(!code){
            this.setState({status: 'Code is missing'})
        }
        apiClient.student.activateAccount(code)
        .then(res=>{
            this.setState({status: 'Account successfully activated'})
            _.delay( browserHistory.push, 3000, '/')
        })
        .catch(res=>{
            this.setState({status: 'Invalid or expired code'})
        })
    }

    render() {
        return (
            <div className="animated fadeInRight">
                <div className="row no-gutters justify-content-center hp-mt-3">
                    <div className="col-12 text-center">
                        <img src="/img/hippoIcon-small.png" />
                    </div>
                    <div className="col-12 text-center hp-mt-1">
                        <div className="hh1">{this.state.status}</div>
                    </div>
                </div>
            </div>
        )
    }
}