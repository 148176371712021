import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import TextBase from './TextBase'

const Header = styled(TextBase)`
    font-size: ${props => props.small ? '23px' : '1.75rem'};
    font-weight: 300
`

export default Header

Header.propTypes = {
    small: PropTypes.bool, // Header with font-size=1.75rem
}