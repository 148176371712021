import React from 'react';
import styled from 'styled-components';

import {
  AppContainer,
  Box,
  Button,
  Card,
  Text,
  Loading,
  Table,
  Icon,
  RouterLink,
} from '../../components/base';
import { COLOR } from '../../common/const';
import StartSessionModal from '../../components/session/StartSessionModal';
import SingleProblemModal from '../../components/problem/SingleProblemModal';

import { AnalyticContext } from '../../contexts/AnalyticContext';

const BackLink = styled(Button.Secondary.Text)`
  font-size: 16px;
  font-weight: 400;
`;

const GridLayout = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SubjectTestDetailView = ({ loading, testInfo, questions, onBookmark }) => {
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [startSessionModalOpen, setStartSessionModalOpen] = React.useState(false);
  const [singleProblemModalOpen, setSingleProblemModalOpen] = React.useState(false);

  const { name: testName, id: testId, tag } = testInfo || {};
  const subject = tag ? tag[0] : null;
  const { id: subjectId, text: subjectName } = subject || {};

  const { logAction } = React.useContext(AnalyticContext);

  const handleSelectQuestion = (questionId, questionIndex) => {
    const selectedQuestion = {
      questionId,
      questionIndex,
    };
    setSelectedQuestion(selectedQuestion);
    setSingleProblemModalOpen(true);
    logAction({
      category: 'Single Question',
      action: 'Open Modal',
      label: `Test: ${testName}`,
    });
  };

  const handleCloseSingleProblemModal = () => {
    setSingleProblemModalOpen(false);
    const { questionIndex } = selectedQuestion || {};
    logAction({
      category: 'Single Question',
      action: 'Close Modal',
      label: `Test: ${testName}`,
    });
  };

  const handleStartTestSession = (id) => {
    setStartSessionModalOpen(true);
    logAction({
      category: 'Start Session',
      action: 'Open Modal - TestDetail page',
      label: `Test: ${testName}}`,
    });
  };

  const handleCloseStartSessionModal = () => {
    setStartSessionModalOpen(false);
    logAction({
      category: 'Start Session',
      action: 'Close Modal - TestDetail page',
      label: `Test: ${testName}}`,
    });
  };

  const handleStartSession = () => {
    logAction({
      category: 'Start Session',
      action: 'Start - TestDetail page',
      label: `Test: ${testName}}`,
    });
  };

  return (
    <>
      <AppContainer>
        {loading ? (
          <Loading />
        ) : (
          <>
            <RouterLink to={`/subjects/${subjectId}`}>
              <BackLink>{subjectName} /</BackLink>
            </RouterLink>
            <Text.Title.Lg mt={2} mb={4} as={'h1'}>
              {testName}
            </Text.Title.Lg>
            <Card mb={3}>
              <GridLayout>
                <Text.Paragraph mb={[3, 0]} textAlign={['center', 'left']}>
                  ทำข้อสอบทั้งชุดพร้อมจับเวลาเหมือนการเข้าสอบแข่งขันจริง หรือ
                  เลือกโจทย์ทำข้อที่ต้องการด้านล่าง
                </Text.Paragraph>
                <Button.Primary.Default
                  onClick={handleStartTestSession}
                  justifySelf={['center', 'end']}
                >
                  ทำข้อสอบทั้งชุด
                </Button.Primary.Default>
              </GridLayout>
            </Card>
            <Card>
              <Text.Title.Md mb={4}>โจทย์ทั้งหมด</Text.Title.Md>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.HeadCell display={['none', 'table-cell']}></Table.HeadCell>
                    <Table.HeadCell>
                      <Text.Title.Xs px={[0, 4]} minWidth={'48px'}>
                        ข้อที่
                      </Text.Title.Xs>
                    </Table.HeadCell>
                    <Table.HeadCell>
                      <Text.Title.Xs>เนื้อหาที่เกี่ยวข้อง</Text.Title.Xs>
                    </Table.HeadCell>
                    <Table.HeadCell></Table.HeadCell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {questions.map(({ index, id, bookmarked, topic }) => {
                    return (
                      <Table.Row key={id}>
                        <Table.Cell center={true} display={['none', 'table-cell']}>
                          <Icon
                            name={Icon.ICON_LIST.BOOKMARK}
                            color={bookmarked ? COLOR.ORANGE : COLOR.GREYSCALE.GREY2}
                            fontSize={'20px'}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          <Text.Paragraph px={[1, 4]}>
                            <Text.Span display={['none', 'inline']}>ข้อที่</Text.Span> {index}
                          </Text.Paragraph>
                        </Table.Cell>
                        <Table.Cell>
                          <Text.Paragraph light={true} pr={3}>
                            {topic}
                          </Text.Paragraph>
                        </Table.Cell>
                        <Table.Cell>
                          <Button.Secondary.Default
                            dense={true}
                            onClick={() => handleSelectQuestion(id, index)}
                            style={{ float: 'right' }}
                          >
                            ทำโจทย์ข้อนี้
                          </Button.Secondary.Default>
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Card>
          </>
        )}
      </AppContainer>
      <SingleProblemModal
        open={singleProblemModalOpen}
        onClose={handleCloseSingleProblemModal}
        questionId={selectedQuestion && selectedQuestion.questionId}
        questionIndex={selectedQuestion && selectedQuestion.questionIndex}
        testName={testName}
        onBookmark={onBookmark}
      />
      <StartSessionModal
        open={startSessionModalOpen}
        onClose={handleCloseStartSessionModal}
        testId={testId}
        onStartSession={handleStartSession}
      />
    </>
  );
};

SubjectTestDetailView.defaultProps = {
  questions: [],
  testInfo: {},
};

export default SubjectTestDetailView;
