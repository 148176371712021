import React, { Component } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { COLOR } from '../../../common/const';
import Text from '../../base/styled/Text';
import Flex from '../../base/styled/Flex';
import { getDifficulty } from '../../../common/util';

const PracticeButtonBase = styled(Flex)`
  @media (max-width: 575px) {
    width: 100%;
    height: auto;
    border: 1px solid;
    border-radius: 6px;
    background-color: ${COLOR.WHITE};
    padding: 0.5rem 1.5rem;
    margin: 0.5rem 0.5rem 0 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0.5rem;
  }
  position: relative;
  background-color: ${COLOR.WHITE};
  width: 8.5rem;
  height: 8.5rem;
  margin: 0;
  border: 2px solid;
  border-radius: 12px;
  border-color: ${(props) =>
    props.onet ? COLOR.BLUE : props.pat ? COLOR.ORANGE : props.saman ? COLOR.GREEN : COLOR.HIPPO};
  cursor: ${(props) => (props.disabled ? null : 'pointer')};
  transition: 0.2s;
`;

const PracticeButtonWrapper = styled(PracticeButtonBase)`
  @media (max-width: 575px) {
    border: 0;
    width: 100%;
    height: 5rem;
  }
  border-color: transparent;
  background-color: transparent;
`;

const PracticeButtonExercise = styled(PracticeButtonBase)`
  position: absolute;
  color: ${COLOR.HIPPO};
  border-color: ${COLOR.HIPPO};

  @media (hover: hover) {
    &:hover {
      z-index: 1002;
      color: ${(props) => (props.image ? '' : COLOR.LIGHT_HIPPO)};
      border-color: ${(props) => (props.image ? '' : COLOR.LIGHT_HIPPO)};
      box-shadow: 1px 1px 3px ${(props) => (props.image ? 'transparent' : COLOR.LIGHT_HIPPO)};
    }
  }

  &:active {
    border-color: ${(props) => (props.image ? '' : COLOR.DARK_HIPPO)};
  }

  @media (max-width: 575px) {
    box-shadow: none;
    @media (hover: hover) {
      &:hover {
        box-shadow: none;
        border-color: ${COLOR.LIGHTER_GREY};
        background-color: #f0f0f0;
      }
    }

    &:active {
      box-shadow: none;
      border-color: ${COLOR.LIGHTER_GREY};
      background-color: #d8d8d8;
    }
    &:focus {
      box-shadow: none;
      border-color: ${COLOR.LIGHTER_GREY};
      background-color: #d8d8d8;
    }
  }
`;

const PracticeButtonTest = styled(PracticeButtonBase)`
  position: absolute;
  color: ${(props) =>
    props.onet
      ? COLOR.BLUE
      : props.pat
      ? COLOR.ORANGE
      : props.saman
      ? COLOR.GREEN
      : COLOR.LIGHT_HIPPO};
  @media (hover: hover) {
    &:hover {
      z-index: 1002;
      color: ${(props) =>
        props.image
          ? ''
          : props.onet
          ? COLOR.LIGHT_BLUE
          : props.pat
          ? COLOR.LIGHT_ORANGE
          : props.saman
          ? COLOR.LIGHT_GREEN
          : COLOR.LIGHT_HIPPO};
      border-color: ${(props) =>
        props.image
          ? ''
          : props.onet
          ? COLOR.LIGHT_BLUE
          : props.pat
          ? COLOR.LIGHT_ORANGE
          : props.saman
          ? COLOR.LIGHT_GREEN
          : COLOR.LIGHT_HIPPO};
      box-shadow: 1px 1px 3px
        ${(props) =>
          props.image
            ? 'transparent'
            : props.onet
            ? COLOR.LIGHT_BLUE
            : props.pat
            ? COLOR.LIGHT_ORANGE
            : props.saman
            ? COLOR.LIGHT_GREEN
            : COLOR.LIGHT_HIPPO};
    }
  }

  &:active {
    border-color: ${(props) =>
      props.image
        ? ''
        : props.onet
        ? COLOR.DARK_BLUE
        : props.pat
        ? COLOR.DARK_ORANGE
        : props.saman
        ? COLOR.DARK_GREEN
        : COLOR.DARK_HIPPO};
  }

  @media (max-width: 575px) {
    box-shadow: none;
    @media (hover: hover) {
      &:hover {
        box-shadow: none;
        border-color: ${COLOR.LIGHTER_GREY};
        background-color: #f0f0f0;
      }
    }
    &:active {
      box-shadow: none;
      border-color: ${COLOR.LIGHTER_GREY};
      background-color: #d8d8d8;
    }
    &:focus {
      box-shadow: none;
      border-color: ${COLOR.LIGHTER_GREY};
      background-color: #d8d8d8;
    }
  }
`;

const PracticeButtonDisabled = styled(PracticeButtonBase)`
  color: ${COLOR.GREY};
  background-color: ${COLOR.LIGHT_GREY};
  border-color: ${COLOR.LIGHT_GREY};
  cursor: default;
`;

const DoneOverlay = styled(Flex)`
  @media (max-width: 575px) {
    top: 0.5rem;
    height: calc(100% - 0.5rem);
    border-radius: 6px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 0 1.5rem;
  }

  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  border-radius: 10px;
  transition: 0.2s ease;
  color: white;
  background-color: rgba(0, 0, 0, 0.65);
  cursor: pointer;

  @media (hover: hover) {
    &:hover {
      display: none;
      backface-visibility: hidden;
    }
  }

  &:active {
    display: none;
  }
`;

class PracticeButton extends Component {
  onClick = _.debounce(() => {
    if (this.props.onClick) {
      this.props.onClick();
    }
    return;
  }, 200);

  render() {
    const {
      children,
      icon,
      title,
      subtitle,
      onet,
      pat,
      saman,
      points,
      test,
      exercise,
      disabled,
      done,
      image,
      difficultyId,
    } = this.props;

    let PracticeButtonType;
    if (disabled) {
      PracticeButtonType = PracticeButtonDisabled;
    } else if (test) {
      PracticeButtonType = PracticeButtonTest;
    } else if (exercise) {
      PracticeButtonType = PracticeButtonExercise;
    } else {
      PracticeButtonType = PracticeButtonDisabled;
    }

    const difficultyColors = {
      red: false,
      green: false,
      orange: false,
    };

    if (exercise) {
      const difficulty = getDifficulty(difficultyId);
      if (difficulty.rank === 1) {
        difficultyColors.green = true;
      } else if (difficulty.rank === 2) {
        difficultyColors.orange = true;
      } else {
        difficultyColors.red = true;
      }
    }

    return (
      <PracticeButtonWrapper center alignCenter done={done} onClick={this.onClick}>
        {done ? (
          <DoneOverlay
            onClick={this.onClick}
            className="flex-row justify-content-end justify-content-sm-center flex-sm-column align-items-center"
          >
            <i className="fa fa-check mb-0 mb-sm-02 hp-mx-05" />
            <Text inherit>DONE</Text>
          </DoneOverlay>
        ) : null}
        <PracticeButtonType
          col
          between
          alignCenter
          onet={onet}
          pat={pat}
          saman={saman}
          image={image}
          disabled={this.props.onClick ? false : true}
        >
          <Flex className="h-50 flex-row flex-sm-column justify-content-sm-between align-items-center pb-sm-02">
            <Text small grey className="hp-mt-02 hidden-xs-down">
              รวมแนวข้อสอบ
            </Text>
            <Text
              inherit
              className="text-left text-sm-center text-overflow-1 practiceButton-text pb-0 w-100"
              style={{ fontSize: '1.25rem' }}
            >
              {title}
            </Text>
          </Flex>
          <Flex className="h-50 flex-row flex-sm-column justify-content-start justify-content-sm-between align-items-end align-items-sm-center">
            <Text
              inherit={!exercise}
              blue={onet}
              orange={pat}
              green={saman}
              className="text-left text-sm-center text-overflow-1 practiceButton-text pr-2 pr-sm-0"
            >
              {subtitle}
            </Text>
            {test ? (
              <Flex widthAuto className="align-items-center flex-row flex-sm-column hidden-xs-down">
                <Text
                  small
                  grey
                  className="text-center text-overflow-1 practiceButton-text hp-mx-05"
                >
                  คะแนนสูงสุด
                </Text>
                <Text small grey className="text-center text-overflow-1 practiceButton-text">
                  {points}
                </Text>
              </Flex>
            ) : (
              <Text
                className="text-center text-overflow-1 practiceButton-text mb-0 mb-sm-1"
                {...difficultyColors}
              >
                {points}
              </Text>
            )}
          </Flex>
        </PracticeButtonType>
      </PracticeButtonWrapper>
    );
  }
}

PracticeButtonExercise.defaultProps = {
  points: 'NN/NN',
};

export default PracticeButton;
