import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { inModal } from '../../hoc/inModal'

import { getUserQuestionStat } from '../../reducers/stat'
import { ModalTitle } from '../common/Modal'
import apiClient from '../../api'
import Do from '../practice/Problem/Do'
import Flex from '../base/styled/Flex'
import Button from '../base/styled/Button'

class ContentModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ...DEFAULT_STATE
        }
    }

    componentWillMount() {
        this.updateProblem(this.props.questionId)
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.questionId !== nextProps.questionId) {
            this.updateProblem(nextProps.questionId)
        }
    }

    updateProblem = questionId => {
        this.setState({
            ...DEFAULT_STATE
        })

        apiClient.practice.getProblem(questionId)
            .then(problem => {
                this.setState({
                    problem: problem
                })
            })
    }

    submitProblem = () => {
        // this.refs.timer.stopTimer()
        // var timespent = this.refs.timer.state.elaspedSeconds
        apiClient.practice.submitStandaloneResponse(
            this.props.questionId, 
            this.state.selectedChoiceId,
        )
            .then(res => {
                this.setState({
                    isCorrect: res.correct,
                    answerKey: res.answerkeyId,
                    completed: true
                })
            })
            .then(() => {
                this.props.getUserQuestionStat()
            })
    }

    selectChoice = (index, answerId) => {
        this.setState({
            selectedChoiceId: answerId
        })
    }

    leaveSession = () => {
        this.props.closeModal()
    }

    render() {
        const { closeModal, questionIndex, testName } = this.props
        if (!this.state.problem) {
            return null
        }

        return (
            <Flex col alignCenter>
                <ModalTitle 
                    title={ `แนวข้อสอบ ${testName}` }
                    closeButton={ closeModal }
                />
                <Do 
                    index={ questionIndex }
                    questionId={ this.state.problem.id }
                    questionText={ this.state.problem.text }
                    choices={ this.state.problem.choice }
                    selectedChoiceId={ this.state.selectedChoiceId }
                    completed={ this.state.completed }
                    isCorrect={ this.state.isCorrect }
                    answerKey={ this.state.answerKey }
                    type={ this.state.problem.type }
                    difficultyId={this.state.problem.difficulty}
                    topicIds={this.state.problem.category}
                    testName={testName}
                    alwaysShowSolution={true}
                    
                    selectChoice={ this.selectChoice }
                    mainButton={ 
                        <MainButton 
                            solutionMode={ this.state.solutionMode }
                            completed={ this.state.completed }
                            submitProblem={ this.submitProblem }
                            leaveSession={ this.leaveSession }
                            isAnswered={ this.state.selectedChoiceId != null }
                        /> 
                    }
                />
            </Flex>
        )
    }
}

ContentModal.propTypes = {
    questionId: PropTypes.string,
    // These props are for title purpose only.
    questionIndex: PropTypes.number,
    testName: PropTypes.string,
}

ContentModal = inModal(ContentModal)

export default connect(null, { getUserQuestionStat }, null, { withRef: true })(ContentModal)

const DEFAULT_STATE = {
    problem: null,
    selectedChoiceId: null,
    completed: false,
    isCorrect: null,
    answerKey: null,
}

const MainButton = ({ 
    completed, 
    isAnswered,
    submitProblem,
    leaveSession }) => {
    if (!completed) {
        return <Button 
            main 
            width="10rem" 
            disabled={ !isAnswered }
            onClick={ submitProblem }
        >
            ตรวจคำตอบ
        </Button>
    }

    return <Button 
        main 
        width="10rem"
        onClick={ leaveSession }
    >
        ปิด
    </Button>
}
