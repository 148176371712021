import React, { Component } from 'react'

import apiClient from '../../api'
import VimeoPlayer from '../common/VimeoPlayer'

import Text, { Title } from '../base/styled/Text'

class VideoSolution extends Component {
  state = {
    isVisible: false,
    videoId: null,
  }

  TEST_VDEO_ID = '7b361f1b-28c3-4c87-ab3a-4cb66b040122'
  componentWillMount() {
    apiClient.practice.getVideoSolution(this.props.questionId)
      .then(res => {
        res = res[0]
          this.setState({ 
            question: res.question,
            videoId: res.video_id,
          })
      })
  }

  componentWillReceiveProps(nextProps) {
    apiClient.practice.getVideoSolution(nextProps.questionId)
      .then(res => {
        res = res[0]
          this.setState({ 
            question: res.question,
            videoId: res.video_id,
          })
      })
  }

  render() {
    if (this.state.videoId === null) {
        return null
    }

    return <div className="hp-pt-2">
      <VimeoPlayer videoId={this.state.videoId} />
      <div className="hp-mt-05">
        <Title small>เฉลยแนวข้อสอบ {this.state.question.exam[0]} - ข้อ {this.state.question.index}</Title>
        <Text>โดย อ.คณิต มงคลพิทักษ์สุข</Text>
      </div>
    </div>
  }
}

export default VideoSolution
