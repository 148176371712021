import _ from 'lodash'

export default class List {
    constructor(values) {
        this.items = values ? values.slice() : [] // Clone values if not null.

        this.sort = this.sort.bind(this)
        this.filter = this.filter.bind(this)
    }

    // ascending order by default 
    sort(key, ascOrder=true) {
        let newItems = _.sortBy(this.items, key)
        if (!ascOrder) {
            newItems = _.reverse(newItems)
        }

        return new List(newItems)
    }

    filter(filters) {
        const newItems = filters.reduce((acc, f) => {
            acc = _.filter(acc, { [f.key]: f.value })
            return acc
        }, this.items)

        return new List(newItems)
    }

    paginate(itemsPerPage, page=0) {
        const offset = page * itemsPerPage
        return this.items.slice(offset, offset+itemsPerPage)
    }

    getPageCount(itemsPerPage) {
        return Math.ceil(this.items.length / itemsPerPage)
    }
}
