import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import ButtonBase from './ButtonBase'

const MainButton = styled(ButtonBase)`
    color: ${ COLOR.WHITE };
    background-color: ${props => props.disabled ? COLOR.LIGHT_GREY : 
        props.isDisabled ? COLOR.LIGHT_GREY : COLOR.HIPPO};
    border-color: ${props => props.disabled ? "transparent" : 
        props.isDisabled ? "transparent" : COLOR.HIPPO};

    &:hover {
        background-color: ${props => props.disabled ? COLOR.LIGHT_GREY : 
            props.isDisabled ? COLOR.LIGHT_GREY : COLOR.LIGHT_HIPPO};
        border-color: ${props => props.disabled ? "transparent" : 
            props.isDisabled ? "transparent" : COLOR.LIGHT_HIPPO};
    }

    &:active {
        background-color: ${props => props.disabled ? COLOR.LIGHT_GREY : 
            props.isDisabled ? COLOR.LIGHT_GREY : COLOR.DARK_HIPPO};
        border-color: ${props => props.disabled ? "transparent" : 
            props.isDisabled ? "transparent" : COLOR.DARK_HIPPO};
    }

    &:focus {
        background-color: ${props => props.disabled ? COLOR.LIGHT_GREY : 
            props.isDisabled ? COLOR.LIGHT_GREY : props.noFocus ? null : COLOR.DARK_HIPPO};
        border-color: ${props => props.disabled ? "transparent" : 
            props.isDisabled ? "transparent" :  props.noFocus ? null : COLOR.DARK_HIPPO};
    }
`

MainButton.propTypes = {
    disabled: PropTypes.bool
}

export default MainButton
