import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { CORRECT, INCORRECT, SELECTED } from './AnswerSheetItem'
import * as styles from './AnswerSheetItemChoices.styles'

export default class AnswerSheetItemChoices extends Component {
    constructor(props) {
        super(props)
        this.renderChoice = this.renderChoice.bind(this)
    }

    renderChoice(index) {
        let color, className
        const selectClassName = "fa fa-circle"
        const filledClassName = "fa fa-dot-circle-o"
        const notfilledClassName = "fa fa-circle-o"
        switch (this.props.status) {
            case SELECTED:
                if (index === this.props.selectedIndex) {
                    className = selectClassName
                    color = '#00AFAF'
                } else {
                    className = selectClassName
                    color = 'rgba(0,0,0,0.1)'
                }
                break
            case CORRECT:
                if (index === this.props.selectedIndex) {
                    className = filledClassName
                    color = '#00AF64'
                } else {
                    className = selectClassName
                    color = 'rgba(0,0,0,0.1)'
                }
                break
            case INCORRECT:
                if (index === this.props.selectedIndex) {
                    className = filledClassName
                    color = '#D32F2F'
                } else if (index === this.props.correctIndex) {
                    className = notfilledClassName
                    color = '#00AF64'
                } else {
                    className = selectClassName
                    color = 'rgba(0,0,0,0.1)'
                }
                break
            default:
                className = selectClassName
                color = 'rgba(0,0,0,0.1)'
        }

        return ( 
            <div className='col' key={ index } style={ styles.bg(index) }> 
                <i className={ className } style={ styles.circle(color) }></i>
            </div>
        )
    }

    render() {
        const { count } = this.props

        return (
            <div className='col-10'>
                <div className='row no-gutters'>
                { _.range(count).map(this.renderChoice) }
                </div>
            </div>
        )
    }
}

AnswerSheetItemChoices.propTypes = {
    count: PropTypes.number.isRequired,
    selectedIndex: PropTypes.number,
    correctIndex: PropTypes.number,
    // status: PropTypes.oneOf([CORRECT, INCORRECT, ENABLE, DISABLE, SELECTED]).isRequired
    status: PropTypes.oneOf(['CORRECT', "INCORRECT", 'ENABLE', "DISABLE", "SELECTED"]).isRequired
}