import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../../../common/const';

const ButtonBase = styled.button`
  font-family: 'Montserrat', 'Kanit', 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial',
    'Sans-serif' !important;
  font-size: 13px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  padding: 0.75rem 1.5rem;
  border: solid 1px;
  border-radius: 8rem;
  width: ${props => props.width || 'fit-content'};
  transition: 0.2s;
  cursor: ${props =>
    props.disabled ? 'not-allowed' : props.isDisabled ? 'not-allowed' : 'pointer'};

  &:hover {
    text-decoration: none;
  }
  &:focus {
    outline: 0;
  }
`;

ButtonBase.propTypes = {
  width: PropTypes.string, // Use to fix button size
  disabled: PropTypes.bool,
};

export default ButtonBase;
