import { getRequest } from '../common/util';

import { getAllTags } from './tag';
import { transformQuestionInfo } from './transformData';

export function getAllTopics() {
  return getRequest('/topics/');
}

export async function getTopicsBySubjectId(subjectId) {
  // This is the workaround to filter test based on subjectId.
  // Currently, getAllTopics return topics with tags which contain tags' name, not tags' id
  // TODO: Let API return tags as ids instead of name
  const allTags = await getAllTags();

  const allTopics = await getAllTopics();
  // Currently, we use tag as subject because there was no subject when we first design the database.
  // TODO: Change Tag to Subject
  const filteredTopics = allTopics.filter((topic) => {
    const tags = topic.tag;
    const tagIds = tags.map((t) => `${t.id}`);

    // TODO: This should be tags.includes(subjectId) when API return tags as ids instead of name
    return tagIds && tagIds.includes(subjectId.toString());
  });

  const formattedTopics = filteredTopics.map((topic) => {
    const { image_url } = topic;
    return { imageUrl: image_url, ...topic };
  });
  return formattedTopics;
}

export async function getTopicInfoById(topicId) {
  const topicInfo = await getRequest(`/topics/${topicId}/`);
  return topicInfo;
}

/**
 * Get list of questionInfo in the specified test.
 */
export async function getTopicQuestionsById(topicId) {
  const [questions, allTags] = await Promise.all([
    getRequest(`/topics/${topicId}/questions/`, null),
    getAllTags(),
  ]);

  return questions.map((q) => transformQuestionInfo(q, allTags));
}

export function getTopicQuestions(topicId) {
  return getRequest(`/topics/${topicId}/questions/`);
}

export function getAllDifficulties() {
  return getRequest(`/difficulties/`);
}

// ### Old ###
export function getTopicsTop() {
  return getRequest(`/topics/`, { all: true, level: '1' });
}

export function getTopicIdNameMap() {
  return getRequest('/topic-id-name-map/');
}

export function getTopicFilter(data) {
  return getRequest(`/topics/`, data);
}
