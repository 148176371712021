import React, { Component } from 'react'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'

import { RANDOM_COLORS } from '../../common/const'

class QuestionCountByTopic extends Component {
  state = {
    countByTopic: {},
  }

  componentWillMount() {
    this.countQuestions(this.props.questions, this.props.topicLevel)
  }

  componentWillReceiveProps(nextProps) {
    this.countQuestions(nextProps.questions, nextProps.topicLevel)
  }

  countQuestions(questions, topicLevel) {
    if (!questions) {
      return
    }

    const countByTopic = {}
    questions.forEach(q => {
      q.category.filter(c => c.level === topicLevel).forEach(c => {
        if (!countByTopic[c.id]) {
          countByTopic[c.id] = {
            id: c.id,
            name: c.name,
            count: 0,
          }
        }

        countByTopic[c.id].count += 1
      })
    })

    this.setState({ countByTopic })
  }

  renderLabel = ({ x, y, stroke, textAnchor, fill, color, payload }) => {
  //renderLabel = (p) => {
    const label = `${payload.name}(${payload.value})`
    const splitted = label.match(/(.{1,10})/g)
    return <text 
      stroke={stroke}
      fill={fill}
      color={color}
      x={x}
      y={y}
      alignmentBaseline="middle"
      className="recharts-text recharts-pie-label-text"
      textAnchor={textAnchor}
    >
      {splitted.map((s, index) => <tspan x={x} dy={index === 0 ? '0em' : '1.2em'} key={index}>{s}</tspan>)}
    </text>
    //return `${p.payload.name}(${p.payload.value})`
  }

  render() {
    const {countByTopic } = this.state
    if (!countByTopic) {
      return null
    }

    const data = Object.values(countByTopic).map((t, index) => {
      return {
        name: t.name,
        value: t.count,
        color: RANDOM_COLORS[index]
      }
    })

    //label={({ payload }) => <tspan>{`${payload.name}(${payload.value})`}</tspan>}
    return <PieChart width={320} height={285}>
      <Pie 
        data={data} 
        dataKey="value" 
        legendType="circle"
        innerRadius={60} 
        outerRadius={100} 
        label={({ payload }) => `${payload.value} ข้อ`}
        labelLine
      >
        {data.map((d, index) => <Cell key={index} fill={d.color} />)}
      </Pie>
      <Tooltip />
    </PieChart>
  }
}

QuestionCountByTopic.defaultProps = {
  topicLevel: 1
}

export default QuestionCountByTopic