import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { COLOR } from '../../common/const';

import Text from '../base/styled/Text';

const Input = styled('input')`
  border-style: none;
  border-radius: 8px;
  border: 2px solid
    ${({ isCorrect, theme }) =>
      isCorrect === true
        ? theme.colors.correct
        : isCorrect === false
        ? theme.colors.incorrect
        : theme.colors.greys.grey4} !important;
  padding: 0.75rem 1.5rem;
  box-shadow: none;
  background-color: ${({ isCorrect, theme }) =>
    isCorrect === true
      ? theme.colors.correct
      : isCorrect === false
      ? theme.colors.incorrect
      : 'white'};
  color: ${({ isCorrect, theme }) =>
    isCorrect !== null && isCorrect !== undefined ? 'white' : 'black'};
  &:focus {
    border: ${({ theme }) => `2px solid ${theme.colors.primaries.light} !important`};
  }

  &::after {
    content: #f00c;
    color: white;
  }
`;

const ProblemTextInput = ({ onChange, answer, isCorrect, answerKey }) => {
  const correctAnswer =
    isCorrect === false ? (
      <Text green>
        คำตอบที่ถูกคือ: <span style={{ fontWeight: '500', fontSize: '1.2rem' }}>{answerKey}</span>
      </Text>
    ) : null;

  const isDone = isCorrect !== null && isCorrect !== undefined;
  const color =
    isCorrect === true ? COLOR.DARK_GREEN : isCorrect === false ? COLOR.DARK_RED : COLOR.LIGHT_GREY;

  return (
    <div>
      <div style={{ position: 'relative' }} className="w-100 hp-mb-1">
        <Input
          className="w-100"
          type="text"
          value={answer}
          onChange={onChange}
          disabled={isDone}
          placeholder="พิมพ์คำตอบ"
          isCorrect={isCorrect}
        />
        <i
          className={`fa ${
            isCorrect === true ? 'fa-check' : isCorrect === false ? 'fa-times' : ''
          }`}
          style={{
            fontSize: '1.3rem',
            color: 'white',
            position: 'absolute',
            right: '24px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </div>
      {correctAnswer}
    </div>
  );
};

export default ProblemTextInput;
