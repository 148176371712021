import React from 'react';
import styled from 'styled-components';

import { AnalyticContext } from '../../../contexts/AnalyticContext';
import Loading from '../../../components/common/Loading';
import { Text, Card, RouterLink } from '../../../components/base';
import apiClient from '../../../api';

const SubjectListLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr;
  }
`;

const SubjectCard = ({ subjectName, onClick }) => {
  return (
    <Card onClick={onClick} clickable={true}>
      <Text.Title.Sm>{subjectName}</Text.Title.Sm>
    </Card>
  );
};

const SubjectList = () => {
  const [subjects, setSubjects] = React.useState([]);

  const { logAction } = React.useContext(AnalyticContext);

  React.useEffect(() => {
    const getSubjects = async () => {
      const subjects = await apiClient.tag.getAllTags();
      const sortedSubjects = subjects.sort((a, b) => {
        return a.index - b.index;
      });

      setSubjects(sortedSubjects);
    };
    getSubjects();
  }, []);

  const handleClick = ({ id, name }) => {
    logAction({
      category: 'Browse Questions',
      action: 'Select Subject',
      label: `Subject: ${name}`,
    });
  };

  return (
    <SubjectListLayout>
      {subjects.length === 0 ? (
        <Loading />
      ) : (
        subjects.map(({ name, id }) => {
          return (
            <RouterLink to={`/subjects/${id}`} key={id} onClick={() => handleClick({ id, name })}>
              <SubjectCard subjectName={name} />
            </RouterLink>
          );
        })
      )}
    </SubjectListLayout>
  );
};

export default SubjectList;
