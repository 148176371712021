import React, { Component } from 'react'
import { connect } from 'react-redux'
import { browserHistory } from 'react-router'

import { startTest, startExercise, killSession } from '../../actions/solve'
import { SESSION_TYPE_EXERCISE, SESSION_TYPE_TEST } from '../../common/const'
import { ModalContent, ModalTitle } from '../common/Modal'
import Loading from '../common/Loading'
import Text from '../base/styled/Text'
import Flex from '../base/styled/Flex'
import Button from '../base/styled/Button'
import DifficultyTag from '../common/DifficultyTag'
import { inModal } from '../../hoc/inModal'
import apiClient from '../../api'
import { getSubtopics } from '../../common/util'

class PracticeInfo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      questionCount: 0,
      relatedTopics: null,
    }
  }

  componentWillMount() {
    this._updateRelatedTopics(this.props.sessionInfo)
  }

  _updateRelatedTopics = (sessionInfo) => {
    switch (sessionInfo.type) {
      case SESSION_TYPE_TEST:
        apiClient.test.getTestTopics(sessionInfo.testId)
          .then(topics => {
            this.setState({
              relatedTopics: topics
            })
          })
        break
      case SESSION_TYPE_EXERCISE:
        let relatedTopics
        if (sessionInfo.topicId.constructor === Array) {
          relatedTopics = sessionInfo.topicId.reduce((acc, id) => {
            return [...acc, ...getSubtopics(id)]
          }, [])
        } else {
          relatedTopics = getSubtopics(sessionInfo.topicId)
        }

        this.setState({
          relatedTopics
        })

        break
      default:
        console.error('wrong-session-type')
    }
  }

  startSession = () => { 
    const { sessionInfo, startTest, startExercise } = this.props
    switch (sessionInfo.type) {
      case SESSION_TYPE_TEST:
        startTest(
          sessionInfo.testId, 
          sessionInfo.title
        )
        break
      case SESSION_TYPE_EXERCISE:
        startExercise(
          sessionInfo.topicId, 
          sessionInfo.difficultyId, 
          sessionInfo.questionCount, 
          sessionInfo.title,
          sessionInfo.exerciseId,
        )
        break
      default:
        console.error('PracticeModal: default case unexpected')
    }

    browserHistory.push('/session')
  }

  renderPracticeFocus() {
    if (this.state.relatedTopics === null) {
      return <Loading />
    }

    return <Flex col alignCenter className="hp-mb-2">
      <Text bold className="hp-mb-1">เนื้อหาที่เกี่ยวข้อง:</Text>
      <div className="row no-gutters" style={{ width:"45%", minWidth:"275px"}}>
        { this.state.relatedTopics.map((topic, index) => {
          const isEven = index % 2 === 0
          const align = isEven ? 'text-right' : 'text-left'
          return <div 
            key={ topic.id } 
            className={ `col-6 hp-px-05 hp-pb-02 ${align}` }
          >
            <Text small hippo key={ topic.id }>{ topic.name }</Text>
          </div>
        })}
      </div>
    </Flex>
  }

  renderDifficulty = difficultyId => {
    if (!difficultyId) {
      return <Text>ไม่ระบุ</Text>
    }

    if (!(difficultyId instanceof Array)) {
      difficultyId = [difficultyId, ]
    }

    return difficultyId.map(d => <DifficultyTag difficultyId={d} floatRight />)
  }

  render() {
    const { sessionInfo, logo, closeModal } = this.props
    if (!sessionInfo) {
      return null
    }

    return (
      <Flex col alignCenter style={{ width: "100%", minHeight: "60vh" }}>
        <ModalTitle 
          title={sessionInfo.title} 
          closeButton={ closeModal }
        />
        <ModalContent>
          <Flex col around alignCenter style={{ flexGrow: "1" }}>
            <Flex widthAuto col alignCenter className="hp-mb-2" style={{ width: '20rem' }}>
              <div className="hidden-sm-down">
                { logo 
                    ? React.cloneElement(logo, {
                      done: false,
                      image: true,
                      onClick: null
                    })
                    : null
                }
              </div>
              <Flex col between className="hp-mt-15">
                <Flex between>
                  <Text>จำนวน</Text>
                  <Text>{sessionInfo.questionCount} ข้อ</Text>
                </Flex>
                <Flex between>
                  <Text>ระดับความยาก</Text>
                  <Flex widthAuto>
                    {this.renderDifficulty(sessionInfo.difficultyId)}
                  </Flex>
                </Flex>
              </Flex>
              {/* <Flex between>
                                <Text>เวลา</Text>
                                <Text>{ sessionInfo.duration != null ? (`, ${ window.moment.duration(sessionInfo.duration,'HH:mm:ss').humanize() }`):(null)} นาที</Text>
                            </Flex> */}
                          </Flex>
                          { this.renderPracticeFocus() }
                          <StartButton 
                            type={ sessionInfo.type } 
                            startSession={ this.startSession } 
                          />
                        </Flex>
                      </ModalContent>
                    </Flex>
    )
  }
}

function mapStateToProps(state) {
  return {
    sessionInfo: state.data.sessionInfo,
  }
}

const PracticeInfoContainer = connect(mapStateToProps, {
  startTest,
  startExercise,
  killSession,
}, null, { withRef: true })(PracticeInfo)

const PracticeInfoModal = inModal(PracticeInfoContainer)
export default PracticeInfoModal

const StartButton = ({ type, startSession }) => {
  const text = type === SESSION_TYPE_TEST
    ? 'เริ่มทำข้อสอบ'
    : 'เริ่มทำแบบฝึกหัด'

  return <Button onClick={ () => startSession() } width="11rem">
    {text}
  </Button>
}
