import React from 'react';
import styled from 'styled-components';

import apiClient from '../../../../api';
import theme from '../../../../theme';

import { Box, Flex, Text, Loading, Button, RouterLink, Icon, Card } from '../../../base';
import VimeoPlayer from '../../../common/VimeoPlayer';

const NotAPremiumUserModal = () => {
  return (
    <Card>
      <Flex flexDirection={'column'} alignItems={'center'} bg={'white'} maxWidth={'360px'}>
        <Icon name={Icon.ICON_LIST.WARNING} size={32} color={theme.colors.warning} mb={2} />
        <Text.Paragraph.Lg>ขออภัย</Text.Paragraph.Lg>
        <Text.Paragraph.Lg textAlign={'center'} mb={3}>
          คุณยังไม่ได้ซื้อแพ็คเกจที่สามารถดูวิดีโอนี้ได้
        </Text.Paragraph.Lg>
        <RouterLink to={'/account-info'}>
          <Button.Primary.Main>ซื้อแพ็คเกจ</Button.Primary.Main>
        </RouterLink>
      </Flex>
    </Card>
  );
};

const CannotSeeSolutionModal = () => {
  return (
    <Card>
      <Flex flexDirection={'column'} alignItems={'center'} bg={'white'} width={'320px'}>
        <Icon name={Icon.ICON_LIST.WARNING} size={32} color={theme.colors.warning} mb={2} />
        <Text.Paragraph.Lg>ขออภัย</Text.Paragraph.Lg>
        <Text.Paragraph.Lg textAlign={'center'} mb={3}>
          ไม่สามารถดูวิดีโอเฉลยได้ในขณะนี้
        </Text.Paragraph.Lg>
      </Flex>
    </Card>
  );
};

const VideoPlayerAspectRatioBoxWrapper = styled(Box)`
  max-width: 640px;
  margin: 0 auto;
`;
const VideoPlayerAspectRatioBox = styled(Box)`
  width: 100%;
  height: 0%;
  /* 16:9 */
  padding-top: 56.25%;
  position: relative;
`;

const VideoPlayerBox = styled(Box)`
  background-color: grey;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

const VideoWrapper = styled(Box)`
  background-color: black;
  width: 100%;
`;
const VideoSolution = ({ questionId, available, premiumUser }) => {
  const [videoId, setVideoId] = React.useState(null);
  React.useEffect(() => {
    const getVideoId = async () => {
      const videos = await apiClient.practice.getVideoSolution(questionId);
      const video = videos[0];
      const { video_id: id } = video || {};
      setVideoId(id);
    };
    getVideoId();
  }, [questionId]);
  return (
    <VideoWrapper>
      <VideoPlayerAspectRatioBoxWrapper>
        <VideoPlayerAspectRatioBox>
          {!available ? (
            <VideoPlayerBox p={3}>
              {!premiumUser ? <NotAPremiumUserModal /> : <CannotSeeSolutionModal />}
            </VideoPlayerBox>
          ) : (
            <VideoPlayerBox>
              {videoId ? <VimeoPlayer videoId={videoId} /> : <Loading />}
            </VideoPlayerBox>
          )}
        </VideoPlayerAspectRatioBox>
      </VideoPlayerAspectRatioBoxWrapper>
    </VideoWrapper>
  );
};

export default VideoSolution;
