import React, { Component } from 'react';
import styled from 'styled-components';

import apiClient from '../api';
import { Card, AppContainer, Flex, Text, RouterLink, Button } from '../components/base';
import MESSAGES from '../common/messages';

class ForgetPasswordView extends Component {
  state = {
    email: '',
    pending: false,
    error: null,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ pending: true });
    apiClient.student
      .requestResetPassword(this.state.email)
      .then((res) => {
        if (res.errorCode === 'invalid-email') {
          this.setState({ pending: false, error: MESSAGES.ACCOUNT_NOT_FOUND });
        } else if (res.errorCode) {
          this.setState({ pending: false, error: MESSAGES.INTERNAL_SERVER_ERROR });
        } else {
          this.props.router.push('/forget-password-success');
        }
      })
      .catch((error) => {
        this.setState({ pending: false, error: MESSAGES.INTERNAL_SERVER_ERROR });
      });
  };

  handleEmailChange = (email) => {
    this.setState({ email, error: null });
  };

  render() {
    return (
      <AppContainer>
        <Card maxWidth={'480px'} mx={'auto'}>
          <Flex flexDirection={'column'} alignItems={'center'}>
            <Text.Title.Md mb={4}>ลืมรหัสผ่าน</Text.Title.Md>
            <form
              className="form-horizontal"
              onSubmit={this.handleSubmit}
              style={{ width: '100%' }}
            >
              <Text.Paragraph.Lg light={true}>
                กรุณาใส่อีเมลที่ใช้กับบัญชีผู้ใช้ของคุณ
              </Text.Paragraph.Lg>
              <Text.Paragraph.Lg light={true} mb={4}>
                รายละเอียดวิธีแก้ไขรหัสผ่านจะถูกส่งไปยังไปอีเมลของคุณ
              </Text.Paragraph.Lg>
              {this.state.error ? (
                <div className="c5 red text-center hp-mb-05 w-100">{this.state.error}</div>
              ) : null}
              <Flex flexDirection={'column'} alignItems={'center'} width={'100%'}>
                <label className="w-100 hp-mb-15">
                  อีเมล
                  <input
                    name="email"
                    type="email"
                    placeholder="อีเมล"
                    value={this.state.email}
                    className="form-control hp-mb-1"
                    onChange={(e) => this.handleEmailChange(e.target.value)}
                    required
                  />
                </label>
                <Button.Primary.Main
                  forwardedAs={'button'}
                  type="submit"
                  style={{ width: '100%', maxWidth: '16rem' }}
                  loading={this.state.pending}
                >
                  ยืนยัน
                </Button.Primary.Main>
              </Flex>
            </form>
          </Flex>
        </Card>
      </AppContainer>
    );
  }
}

const SuccessView = () => {
  return (
    <AppContainer>
      <Card maxWidth={'480px'} mx={'auto'}>
        <Flex flexDirection={'column'} alignItems={'center'}>
          <Text.Title.Md mb={4}>อีเมลได้ถูกส่งหาคุณแล้ว</Text.Title.Md>
          <Text.Paragraph.Lg light={true} mb={4}>
            ทางเราได้ส่งวิธีแก้ไขรหัสผ่านไปให้ทางอีเมลของคุณแล้ว
            กรุณาตรวจสอบอีเมลของคุณสำหรับขั้นตอนต่อไป(โปรดตรวจสอบกล่อง spam
            ในอีเมลของคุณเพราะอีเมลจากเราอาจจะอยู่ในนั้น) หากไม่ได้รับอีเมล กรุณาติดต่อเราที่
            hello@hippo.cc
          </Text.Paragraph.Lg>
          <RouterLink to="/login">
            <Button.Primary.Main>กลับไปหน้าเข้าสู่ระบบ</Button.Primary.Main>
          </RouterLink>
        </Flex>
      </Card>
    </AppContainer>
  );
};

export default ForgetPasswordView;
export { SuccessView };
