import React from 'react';
import PropTypes from 'prop-types';

import { COLOR } from '../../../../../common/const';
import ChoiceBase from './ChoiceBase';

import Text from '../../../../base/styled/Text';
import Flex from '../../../../base/styled/Flex';

import './choice.css';

const Choice = ({ selected, correct, wrong, rightanswer, disabled, index, content, onClick }) => {
  const style = selected
    ? 'choice-selected'
    : correct
    ? 'choice-correct'
    : wrong
    ? 'choice-wrong'
    : rightanswer
    ? 'choice-rightanswer'
    : disabled
    ? 'choice-disabled'
    : 'choice-default';

  return (
    <Flex alignCenter className="hp-mb-1">
      <Text className="hp-mr-05" style={{ width: '1.25rem' }}>
        {index}
      </Text>
      <ChoiceBase
        className={style}
        between
        alignCenter
        onClick={style === 'choice-default' ? onClick : null}
      >
        {content}
        {correct || rightanswer ? (
          <i
            className="fa fa-check"
            style={{
              fontSize: '1.3rem',
              color: correct ? 'white' : null,
            }}
          />
        ) : null}
        {wrong ? (
          <i className="fa fa-times" style={{ fontSize: '1.3rem', color: COLOR.WHITE }} />
        ) : null}
      </ChoiceBase>
    </Flex>
  );
};

Choice.propTypes = {
  selected: PropTypes.bool,
  correct: PropTypes.bool,
  wrong: PropTypes.bool,
  rightanswer: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Choice;
