import React from 'react';

import SubjectDetailContainer from './SubjectDetail.container';
import SubjectDetailView from './SubjectDetail.view';

const SubjectDetail = (props) => {
  return (
    <SubjectDetailContainer {...props}>
      {({
        loading,
        subjectInfo,
        tests,
        topics,
        queryType,
        handleSetQueryType,
        handleSelectTest,
        handleStartTestSession,
        handleSelectTopic,
      }) => {
        const { text: subjectName } = subjectInfo || {};
        return (
          <SubjectDetailView
            loading={loading}
            subject={subjectName}
            tests={tests}
            topics={topics}
            queryType={queryType}
            onChangeTab={handleSetQueryType}
            onSelectTest={handleSelectTest}
            onStartTestSession={handleStartTestSession}
            onSelectTopic={handleSelectTopic}
          />
        );
      }}
    </SubjectDetailContainer>
  );
};

export default SubjectDetail;
