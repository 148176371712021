import styled from 'styled-components'

import IconBase from './IconBase'
import ActionIcon from './ActionIcon'
import CloseIcon from './CloseIcon'

const Icon = styled(IconBase)`
`

export default Icon
export {
    ActionIcon,
    CloseIcon
}