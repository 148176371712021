import { combineReducers } from 'redux'

import data from './data'

import auth from './auth'
import stat from './stat'
import bookmark from './bookmark'
import topic from './topic'
import test from './test'
import exercise from './exercise'
import user from './user'

const rootReducer = combineReducers({
    auth,
    data,
    stat,
    bookmark,
    topic,
    test,
    exercise,
    user,
})

export default rootReducer
