import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'

const CardBase = styled.div`
    margin: 0;
    border: 0;
    background-color: ${props => props.dark ? COLOR.DARK_HIPPO : 
        props.grey ? COLOR.LIGHT_GREY : COLOR.WHITE}; 
`

export default CardBase

CardBase.propTypes = {
    hippo: PropTypes.bool, // DarkHippo background
}