import React, { Component } from 'react';
import PropTypes from 'prop-types';

import apiClient from '../../../../api';
import { Flex, Text, Box } from '../../../base';

import MyNote from './MyNote';
import VideoSolution from './VideoSolution';

const PREMIUM_SOLUTION_TYPE = {
  TEXT: 0,
  VIDEO: 1,
};

class Solution extends Component {
  state = {
    isSolutionVisible: false,
    userNotes: null,
    premiumContents: null,
  };

  componentWillMount() {
    this.updatePremiumContents(this.props.questionId);
  }

  componentWillReceiveProps(nextProps) {
    this.updatePremiumContents(nextProps.questionId);
  }

  updatePremiumContents = (questionId) => {
    apiClient.practice.getPremiumContents(questionId).then((premiumContents) => {
      this.setState({ premiumContents });
    });
  };

  updateUserNotes = (questionId) => {
    apiClient.practice.getQuestionNotes(questionId).then((userNotes) => {
      this.setState({ userNotes });
    });
  };

  toggleSolutionVisible = () => {
    this.setState({ isSolutionVisible: !this.state.isSolutionVisible });
  };

  closeSolution = () => {
    this.setState({ isSolutionVisible: false });
  };

  render() {
    const { questionId, config, isPremiumUser } = this.props;

    if (!this.state.premiumContents) {
      return null;
    }

    return (
      <Flex flexDirection={'column'}>
        <Box mb={4}>
          {this.state.premiumContents.length === 0 ? (
            <Text.Paragraph color={'warning'}>ยังไม่มีเฉลย</Text.Paragraph>
          ) : (
            <Flex flexdirection={'column'}>
              {this.state.premiumContents.map((s, index) => {
                const { type, creator, questionId, available } = s;
                return type === PREMIUM_SOLUTION_TYPE.VIDEO ? (
                  <Box width={'100%'} key={questionId}>
                    <Text.Title.Sm mb={3}>เฉลยโดย {creator}</Text.Title.Sm>
                    <VideoSolution
                      questionId={questionId}
                      available={available}
                      premiumUser={isPremiumUser}
                    />
                  </Box>
                ) : null;
              })}
            </Flex>
          )}
        </Box>
        <MyNote questionId={questionId} />
      </Flex>
    );
  }
}

Solution.propTypes = {
  questionId: PropTypes.string,
};

export default Solution;
