import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'

import apiClient from '../../api'
import { updateQuestionsInfo } from '../../common/util'
import Card, { CardHeader } from '../../components/base/styled/Card'
import { ProblemTable } from '../../components/tables'

class BookmarkProblems extends Component {
  state = {
    bookmarkedQuestions: null,
  }

  componentWillMount() {
    this._updateBookmarkedQuestions()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.bookmarksFromServer) {
      this._updateBookmarkedQuestions()
    }
  }

  // This is to fake the disappearance of problems that we unbookmark.
  // To wait until, the process of unbookmark is done and get the new set is too long.
  getBookmarkedQuestions = () => {
    if (!this.state.bookmarkedQuestions) {
      return null
    }

    const { questionStat, bookmarks, bookmarkIds } = this.props
    const questions = this.state.bookmarkedQuestions.filter(q => {
      return _.includes(bookmarks, q.id)
    })
    
    updateQuestionsInfo(questions, questionStat, bookmarkIds)
    return questions
  }

  _updateBookmarkedQuestions = () => {
    apiClient.other.getBookmarks(true)
      .then(bookmarks => {
        const bookmarkedQuestions = _.chain(bookmarks)
          .map(b => b.question)
          .map(this._handleTopic)
          .value()
        this.setState({
          bookmarkedQuestions
        })
      })
  }

  _handleTopic = q => {
      const topic = q.category.filter(c => c.level === 1)
      q.topic = topic.length > 0 ? topic[0].name : 'n/a'
      return q
  }

  render() {
    return <Card>
      <div style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
        <CardHeader 
          className="mb-0 mb-sm-4" 
          title="โจทย์ที่บุ๊คมาร์ก"
        />
      </div>
      <ProblemTable problems={this.getBookmarkedQuestions() || []} topicLevel={1} />
    </Card>
  }
}

function mapStateToProps(state) {
  return {
    questionStat: state.stat.userQuestionStat,
    bookmarkIds: state.bookmark.bookmarkIds,
    bookmarks: state.bookmark.bookmarks,
    bookmarksFromServer: state.bookmark.bookmarksFromServer,
  }
}

export default connect(mapStateToProps, null)(BookmarkProblems)
