import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { ToggleableProblemBubbleList } from '../ProblemBubbleList'
import AnswerSheetItem from './AnswerSheetItem'
import AnswerSheetChoiceLabel from './AnswerSheetChoiceLabel'

class AnswerSheet extends Component {
    render() {
        const { index, problems, setProblemIndex } = this.props

        if (!problems) {
            return null
        }

        const [splittedProblems, splitIndex] = splitProblems(problems)
        return <div>
            <div className="hidden-lg-up">
                <ToggleableProblemBubbleList 
                    index={ index }
                    problems={ problems }
                    setProblemIndex={ setProblemIndex }
                />
            </div>
            <div className="hidden-md-down">
                <div className="row no-gutters">
                    <div className="col-6" style={{ paddingRight:"0.125rem" }}>
                        <AnswerSheetChoiceLabel count={ 5 }/>
                        { splittedProblems[0].map((p, problemIndex) => {
                            return <AnswerSheetItem 
                                key={ problemIndex }
                                setProblemIndex={ setProblemIndex }
                                problemIndex={ problemIndex }
                                problem={ problems[problemIndex] }
                                index={ index }
                            />
                        })}
                    </div>

                    <div className="col-6" style={{ paddingLeft:"0.125rem" }}>
                        { !splittedProblems[1]
                            ? null
                            : <AnswerSheetChoiceLabel count={ 5 }/>
                        }
                        { splittedProblems[1].map((p, problemIndex) => {
                            const realIndex = problemIndex + splitIndex
                            return <AnswerSheetItem 
                                key={ realIndex }
                                setProblemIndex={ setProblemIndex }
                                problemIndex={ realIndex }
                                problem={ problems[realIndex] }
                                index={ index }
                            />
                        })}
                    </div>
                </div>
            </div>
        </div>
    }
}

AnswerSheet.propTypes = {
    index: PropTypes.number,
    problems: PropTypes.array,
    setProblemIndex: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        // Hack to make it rerender ...
        session: state.data.session,
    }
}

AnswerSheet = connect(mapStateToProps, null)(AnswerSheet)

export default AnswerSheet

const MINIMUM_PROBLEMS_PER_ROW = 15

const splitProblems = (problems) => {
    const count = problems.length
    if (count < MINIMUM_PROBLEMS_PER_ROW) {
        return [[problems, null], null]
    } else {
        const splitIndex = Math.ceil(count/2)
        return [[problems.slice(0, splitIndex), problems.slice(splitIndex, count)], splitIndex]
    }
}
