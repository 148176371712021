import _ from 'lodash'

import {
    GET_EXERCISE_RESULTS,
    GET_STUDENT,
    START_SESSION,
    KILL_SESSION,
    GET_PROBLEM,
    SUBMIT_RESPONSE,
    SUBMIT_TEST,
    SELECT_CHOICE,
    POST_SESSION_RESULT,
    SET_SESSION_INFO,
    GET_KNOWLEDGE,
    GET_PRACTICE_INFO_KNOWLEDGES,
    GET_PRACTICE_INFO_TOPICS,
    GET_TEST_PROGRESS,
    SET_PROBLEM_INDEX,
    GO_TO_SOLUTION,
    RANDOM_TEST_ANSWERS,
    START_SOLUTION_SESSION
} from '../actions/actionTypes'
import { SOLVE_MODE } from '../common/const'

const LOGOUT = 'LOGOUT'

const INITIAL_STATE = {
    exams: [],
    topics: [],
    topic: null,
    studentObj: {},
    exerciseResults: {},
    testResults: {},
    sessionResult: {},
    knowledges: {},
    practice_info_knowledges: [],
    practice_info_topics: [],
    testProgress: {},
    subtopics: [],
    sessionInfo: null,
}

export default function(state = INITIAL_STATE, action) {
    let session
    let problem
    switch (action.type) {
        case GET_STUDENT:
            return { ...state, studentObj: action.payload.data }
        case GO_TO_SOLUTION:
            session = { ...state.session }
            session.index = 0
            session.solutionMode = true
            return { ...state, session: session }
        case START_SESSION:
            session = {
                id: action.payload.data.sessionId,
                title: action.meta.title,
                mode: action.meta.mode,
                solutionMode: false,
                index: 0,
                problems: action.payload.data.questions.map((q, index) => {
                    return {
                        questionId: q.id,
                        questionText: null,
                        completed: false,
                        index: index,
                        type: q.type
                    }
                })
            }
            return { ...state, session: session }
        case START_SOLUTION_SESSION:
            session = {
                id: action.sessionId,
                title: action.title,
                mode: SOLVE_MODE.TEST,
                solutionMode: true,
                index: 0,
                problems: _.sortBy(action.questions, 'index').map((q, index) => {
                    return {
                        questionId: q.questionId,
                        questionText: null,
                        completed: true,
                        index: index,
                        type: q.questionType,
                        selectedChoiceId: q.answer,
                        result: {
                            answerKey: q.answerKey,
                            isCorrect: q.answer === q.answerKey
                        },
                        choices: q.choices
                    }
                })
            }
            return { ...state, session: session }
        case SET_PROBLEM_INDEX:
            session = { ...state.session }
            session.index = action.index < 0 ? 0 : action.index
            return { ...state, session: session }
        case KILL_SESSION:
            return { ...state, session: null }
        case GET_PROBLEM:
            session = { ...state.session }
            problem = session.problems[action.meta.index]

            problem.questionText = action.payload.data.text
            problem.choices = action.payload.data.choice
            problem.type = action.payload.data.type
            problem.difficultyId = action.payload.data.difficulty
            problem.topicIds = action.payload.data.category
            problem.testName = action.payload.data.exam[0]

            // TODO: Get rid of this. May be this is not needed.
            // if (problem.type === QUESTION_TYPE.MULTIPLE_CHOICE) {
            //     problem.selectedChoiceId = null
            // } else if (problem.type === QUESTION_TYPE.SHORT_ANSWER) {
            //     problem.shortAnswer = null
            // }

            return { ...state, session: session }
        case SUBMIT_TEST:
            session = { ...state.session }
            // session.timespent = action.meta.timespent
            for (var problemIndex in action.payload.data) {
                var r = action.payload.data[problemIndex]
                var p = session.problems[problemIndex]
                p.completed = true
                p.result = {
                    answerKey: r.answerkeyId,
                    isCorrect: r.correct
                }
            }

            return { ...state, session: session }
        case SUBMIT_RESPONSE:
            session = { ...state.session }

            problem = session.problems[action.meta.index]
            problem.completed = true
            problem.result = {
                answerKey: action.payload.data.answerkeyId,
                isCorrect: action.payload.data.correct
            }

            // problem.timespent = action.meta.timespent
            return { ...state, session: session }
        case POST_SESSION_RESULT:
            const response = action.payload.data

            if (response.newBest) {} 

            return { ...state, sessionResult: response }
        case SELECT_CHOICE:
            session = { ...state.session }
            problem = session.problems[action.problemIndex]

            problem.selectedChoiceId = action.answerId
            return { ...state, session: session }
        case RANDOM_TEST_ANSWERS:
            session = { ...action.session }
            session.problems = session.problems.map(p => {
                const choiceIds = p.choices.map(c => c.id)
                if (!p.selectedChoiceId) {
                    p.selectedChoiceId = _.sample(choiceIds)
                }

                return p
            })

            return { ...state, session: session }
        case GET_EXERCISE_RESULTS:
            return { ...state, exerciseResults: action.payload.data }
        case LOGOUT:
            return INITIAL_STATE
        case SET_SESSION_INFO:
            return { ...state, sessionInfo: action.payload }
        case GET_KNOWLEDGE:
            const new_state = { ...state, knowledges: { ...state.knowledges, [action.payload.data.id]: action.payload.data }}
            return new_state
        case GET_PRACTICE_INFO_KNOWLEDGES:
            return { ...state, practiceInfoKnowledges: action.payload.data }
        case GET_PRACTICE_INFO_TOPICS:
            return { ...state, practiceInfoTopics: action.payload.data }
        case GET_TEST_PROGRESS:
            return { ...state, testProgress: action.payload.data }
        default:
            return state
    }
}
