import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { AnalyticContext } from '../../../contexts/AnalyticContext';
import { getUserQuestionStat } from '../../../reducers/stat';
import apiClient from '../../../api';
// import Flex from '../../base/styled/Flex';
import { Modal, Button, Text, Box, Flex } from '../../base';
import QuestionFeedbackButton from '../../QuestionFeedbackButton';

import SingleProblem from '../SingleProblem';

const DEFAULT_STATE = {
  problem: null,
  selectedChoiceId: null,
  completed: false,
  isCorrect: null,
  answerKey: null,
};

const MainButton = ({ completed, isAnswered, submitProblem, leaveSession }) => {
  if (!completed) {
    return (
      <Button.Primary.Main width="10rem" disabled={!isAnswered} onClick={submitProblem}>
        ตรวจคำตอบ
      </Button.Primary.Main>
    );
  }

  return (
    <Button.Secondary.Outline width="10rem" onClick={leaveSession}>
      ปิด
    </Button.Secondary.Outline>
  );
};

class ContentModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...DEFAULT_STATE,
    };
  }

  componentWillMount() {
    this.updateProblem(this.props.questionId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.questionId !== nextProps.questionId) {
      this.updateProblem(nextProps.questionId);
    }
  }

  updateProblem = (questionId) => {
    this.setState({
      ...DEFAULT_STATE,
    });

    apiClient.practice.getProblem(questionId).then((problem) => {
      this.setState({
        problem: problem,
      });
    });
  };

  submitProblem = () => {
    // this.refs.timer.stopTimer()
    // var timespent = this.refs.timer.state.elaspedSeconds
    apiClient.practice
      .submitStandaloneResponse(this.props.questionId, this.state.selectedChoiceId)
      .then((res) => {
        this.props.logAction({
          category: 'Single Question',
          action: 'Submit Answer',
          label: `Question - ${this.props.testName} ข้อ ${this.props.questionIndex}`,
        });
        this.setState({
          isCorrect: res.correct,
          answerKey: res.answerkeyId,
          completed: true,
        });
      })
      .then(() => {
        this.props.getUserQuestionStat();
      });
  };

  selectChoice = (index, answerId) => {
    this.setState({
      selectedChoiceId: answerId,
    });
  };

  leaveSession = () => {
    this.props.onClose();
  };

  render() {
    const { questionIndex, testName, ...restProps } = this.props;
    if (!this.state.problem) {
      return null;
    }

    return (
      <Flex flexDirection={'column'} alignItems={'center'}>
        <SingleProblem
          questionName={`${testName} ข้อ ${questionIndex}`}
          questionId={this.state.problem.id}
          questionText={this.state.problem.text}
          choices={this.state.problem.choice}
          selectedChoiceId={this.state.selectedChoiceId}
          completed={this.state.completed}
          isCorrect={this.state.isCorrect}
          answerKey={this.state.answerKey}
          type={this.state.problem.type}
          difficultyId={this.state.problem.difficulty}
          topicIds={this.state.problem.category}
          testName={testName}
          alwaysShowSolution={true}
          selectChoice={this.selectChoice}
          locationType={'MODAL'}
          mainButton={
            <MainButton
              solutionMode={this.state.solutionMode}
              completed={this.state.completed}
              submitProblem={this.submitProblem}
              leaveSession={this.leaveSession}
              isAnswered={this.state.selectedChoiceId != null}
            />
          }
          {...restProps}
        />
        <Flex width={'100%'} mt={4} justifyContent={['center', 'flex-start']}>
          <QuestionFeedbackButton
            questionId={this.props.questionId}
            questionName={`${testName} ข้อ ${questionIndex}`}
            location={'MODAL'}
          />
        </Flex>
      </Flex>
    );
  }
}

ContentModal.propTypes = {
  questionId: PropTypes.string,
  // These props are for title purpose only.
  questionIndex: PropTypes.number,
  testName: PropTypes.string,
};

const SingleProblemModal = ({ open, onClose, ...restProps }) => {
  const { logAction } = React.useContext(AnalyticContext);
  return (
    <Modal open={open} onClose={onClose} size={'md'}>
      <ContentModal onClose={onClose} logAction={logAction} {...restProps} />
    </Modal>
  );
};

export default connect(null, { getUserQuestionStat }, null, { withRef: true })(SingleProblemModal);
