import { Link } from 'react-router';
import styled from 'styled-components';

const RouterLink = styled(Link)`
  text-decoration: none;
  color: unset;
  display: inline-block;

  &:hover {
    text-decoration: none;
  }
`;

export default RouterLink;
