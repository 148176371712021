import { getRequest } from '../common/util'

export function getKnowledge(knowledgeId) {
    return getRequest(`/knowledges/${ knowledgeId }/`)
}

export function getKnowledgeMessage(messageId) {
    return getRequest(`/knowledge-messages/${ messageId }/`)
}

export function getSolutionKnowledges(solutionId) {
    return getRequest('/knowledges/', { solutionId: solutionId })
}

export function getTopicKnowledges(topicId) {
    return getRequest('/knowledges/', { topicId: topicId })
}