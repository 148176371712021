import React from 'react';

import StartSessionContainer from './StartSession.container';
import StartSessionModalView from './StartSessionModal.view';

const MOCK_GENERAL_DESCRIPTIONS = [
  'ทำโจทย์ข้อสอบทั้งชุดพร้อมจับเวลา เสมือนการทำข้อสอบจริง',
  'คุณสามารถดูคำตอบที่ถูกต้อง และเฉลยได้เมื่อทำการ “จบการสอบ” แล้วเท่านั้น',
];

const MOCK_TEST_DESCRIPTIONS = [
  'เลือกคำตอบที่มั่นใจในโจทย์แต่ละข้อ และ กดปุ่ม "ข้อถัดไป" เพื่อทำโจทย์ข้อถัดไป หรือกดเลือกโจทย์ข้อที่ต้องการทำใน กระดาษคำตอบทางด้านซ้าย',
  'คุณสามารถเลือกข้ามโจทย์ข้อที่ไม่มั่นใจ แล้วย้อนกลับมาทำในภายหลังได้',
  'คุณสามารถกดปุ่ม “จบการสอบ” ได้เมื่อตอบโจทย์ครบทุกข้อ หรือ เมื่อเวลาหมด',
  'หากออกจากหน้าทำข้อสอบก่อนกด "จบการสอบ" คะแนนของคุณจะไม่ถูกบันทึก',
  'หากพบปัญหาเกี่ยวกับโจทย์ บอกเราผ่านปุ่ม "รายงานปัญหา"',
];

const StartSessionModal = ({ open, onClose, testId, onStartSession }) => {
  return (
    <StartSessionContainer testId={testId} onStartSession={onStartSession}>
      {({ loading, handleStartSession, testInfo }) => {
        const { id, name, duration, questionCount, highScore } = testInfo || {};
        return (
          <StartSessionModalView
            open={open}
            onClose={() => onClose(id, name)}
            onStartSession={handleStartSession}
            title={name}
            duration={duration}
            questionCount={questionCount}
            highScore={highScore}
            generalDescriptions={MOCK_GENERAL_DESCRIPTIONS}
            testDescriptions={MOCK_TEST_DESCRIPTIONS}
            loading={loading}
          />
        );
      }}
    </StartSessionContainer>
  );
};

export default StartSessionModal;
