import { getRequest, postRequest } from '../common/util';

import { getAllTags } from './tag';
import { transformQuestionInfo, transformTestInfo } from './transformData';

export function getAllTests(params) {
  return getRequest('/tests/', params);
}

export async function getTestsBySubjectId(subjectId) {
  const allTests = await getAllTests();
  const filteredTests = allTests.filter((test) => {
    // Currently, we use tag as subject because there was no subject when we first design the database.
    // TODO: Change Tag to Subject
    const tags = test.tag;
    const tagIds = tags.map((t) => `${t.id}`);

    // TODO: This should be tags.includes(subjectId) when API return tags as ids instead of name
    return tagIds && tagIds.includes(subjectId.toString());
  });

  return filteredTests;
}

export async function getTestInfoById(testId) {
  const testInfo = await getRequest(`/tests/${testId}/`);
  return transformTestInfo(testInfo);
}

/**
 * Get list of questionInfo in the specified test.
 */
export async function getTestQuestionsById(testId) {
  const [questions, allTags] = await Promise.all([
    getRequest(`/tests/${testId}/questions/`, null),
    getAllTags(),
  ]);

  return questions.map((q) => transformQuestionInfo(q, allTags));
}

export function getTest(testId) {
  return getRequest(`/tests/${testId}/`, null);
}

export function getTestQuestions(testId) {
  return getRequest(`/tests/${testId}/questions/`, null);
}

export function getTestTopics(testId) {
  return getRequest(`/tests/${testId}/topics/`, null);
}

export function startTest(testId, sessionTitle) {
  return postRequest('action/start-test/', { testId: testId });
}
