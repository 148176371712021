import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../common/const'

const ListItemBase = styled.div`
    margin: 0rem;
    padding: 1.25rem 1.5rem;
    border-bottom: solid 1px;
    border-bottom-color: ${props => props.white ? COLOR.WHITE : COLOR.LIGHT_GREY};
`

export default ListItemBase

ListItemBase.propTypes = {
}