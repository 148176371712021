import React from 'react';
import styled from 'styled-components';

import { AppContainer, Text } from '../../components/base';
import ProblemsStat from './components/ProblemsStat';
import RecentActivites from './components/RecentActivities';
import SubjectList from './components/SubjectList';

const LandingLayout = styled(AppContainer)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'assessment-list assessment-list'
    'stats stats'
    'recent-activities recent-activities';

  grid-column-gap: 16px;
  grid-row-gap: 48px;

  align-items: stretch;
`;

const Section = styled.section`
  grid-area: ${({ gridArea }) => gridArea};
  display: flex;
  flex-direction: column;
`;

const Title = styled(Text.Title.Lg)`
  margin-bottom: 16px;
`;

const LandingView = ({ loading, assessments }) => {
  return (
    <LandingLayout>
      <Section gridArea={'assessment-list'}>
        <Title as={'h2'}>เลือกประเภทข้อสอบ</Title>
        <SubjectList />
      </Section>
      <Section gridArea={'stats'}>
        <Title as={'h2'}>สถิติ</Title>
        <ProblemsStat />
      </Section>
      <Section gridArea={'recent-activities'}>
        <Title as={'h2'}>ประวัติการทำข้อสอบล่าสุด</Title>
        <RecentActivites />
      </Section>
    </LandingLayout>
  );
};

LandingView.defaultProps = {
  assessments: [],
};

export default LandingView;
