import { getRequest, postRequest } from '../common/util'

export function validateCode(code, type) {
    return getRequest(`/codes/${code}/`, { type }) // type0 = PREMIUM CODE, type1 = PROMO CODE
}

export function postPayment(amount, code) {
    return postRequest('/payments/', { amount: amount, code: code })
}

export function payPayment(id, receipt) {
    return postRequest(`/payments/${id}/pay/`,{ receipt_url: receipt })
}

// export function getKnowledgeMessage(messageId) {
//     return getRequest(`/knowledge-messages/${ messageId }/`)
// }

// export function getSolutionKnowledges(solutionId) {
//     return getRequest('/knowledges/', { solutionId: solutionId })
// }

// export function getTopicKnowledges(topicId) {
//     return getRequest('/knowledges/', { topicId: topicId })
// }