import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import TextBase from './TextBase'

const Title = styled(TextBase)`
    font-size: ${ props => props.small ? '1.25rem' : '1.5rem'};
    font-weight: 400;
`

export default Title

Title.propTypes = {
    small: PropTypes.bool, // Title with font-size=1.25rem
}