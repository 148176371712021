import styled from 'styled-components';
import { typography } from 'styled-system';

import Box from './Box';

const TextBase = styled(Box)`
  ${typography};
  /* font-family: 'Montserrat', 'Kanit', 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial',
    'Sans-serif' !important; */
  font-family: ${({ readable }) =>
    readable
      ? `'Bai Jamjuree', sans-serif`
      : `'Kanit', 'Raleway', 'Helvetica Neue', 'Helvetica', 'Arial', 'Sans-serif' !important`};
  font-size: 16px;
`;
TextBase.defaultProps = {
  mb: 0,
  mt: 0,
  color: 'black',
};

const TitleLg = styled(TextBase)`
  font-size: 26px;
  font-weight: 400;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 28px;
  }
`;

const SubtitleLg = styled(TextBase)`
  font-size: 22px;
  font-weight: 300;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 24px;
  }
`;

const TitleMd = styled(TextBase)`
  font-size: 22px;
  font-weight: 400;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 24px;
  }
`;

const SubtitleMd = styled(TextBase)`
  font-size: 18px;
  font-weight: 400;
`;

const TitleSm = styled(TextBase)`
  font-size: 20px;
`;
TitleSm.defaultProps = {
  fontWeight: 400,
};

const SubtitleSm = styled(TextBase)`
  font-size: 16px;
  font-weight: 300;
`;

const TitleXs = styled(TextBase)`
  font-size: 16px;
  font-weight: 500;
`;

const SubtitleXs = styled(TextBase)`
  font-size: 14px;
  color: #858585;
  font-weight: 400;
`;

const ParagraphLg = styled(TextBase)`
  font-size: 18px;
  font-weight: ${({ light }) => (light ? 300 : 400)};
`;

const ParagraphMd = styled(TextBase)`
  font-size: 16px;
  font-weight: ${({ light }) => (light ? 300 : 400)};
`;

ParagraphMd.defaultProps = {
  light: false,
};

const ParagraphSm = styled(TextBase)`
  font-size: 14px;
  font-weight: ${({ light }) => (light ? 300 : 400)};
`;

const Caption = styled(TextBase)`
  font-size: 12px;
  font-weight: ${({ light }) => (light ? 300 : 400)};
`;

const Strong = styled(TextBase)`
  font-size: inherit;
  font-weight: 600;
`;

Strong.defaultProps = {
  as: 'strong',
};

const Span = styled(TextBase)`
  font-size: inherit;
`;
Span.defaultProps = {
  as: 'span',
};

const Text = TextBase;

Text.Title = TitleMd;
Text.Subtitle = SubtitleMd;

Text.Title.Lg = TitleLg;
Text.Title.Md = TitleMd;
Text.Title.Sm = TitleSm;
Text.Title.Xs = TitleXs;

Text.Subtitle.Lg = SubtitleLg;
Text.Subtitle.Md = SubtitleMd;
Text.Subtitle.Sm = SubtitleSm;
Text.Subtitle.Xs = SubtitleXs;

Text.Paragraph = ParagraphMd;
Text.Paragraph.Lg = ParagraphLg;
Text.Paragraph.Md = ParagraphMd;
Text.Paragraph.Sm = ParagraphSm;

Text.Caption = Caption;

Text.Strong = Strong;

Text.Span = Span;

export default Text;
