import React from 'react'

const Loading = () => {
    return (
        <div className="w-100 text-center hp-my-2">
            <img src="/img/Ripple.svg" style={{ width:'80px' }} />
        </div>
    )
}

export default Loading