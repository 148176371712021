import { getRequest, postRequest } from '../common/util';

import { transformPackages } from './transformData';

export async function getPackages() {
  const packages = await getRequest('/packages/');
  return packages.map((singlePackage) => transformPackages(singlePackage));
}

export async function validatePackageCode(code) {
  return getRequest(`/package-codes/${code}/`);
}

export async function activatePackage(code) {
  return postRequest(`/action/activate-package/${code}/`);
}
