import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router'
import { connect } from 'react-redux'

import Flex from '../components/base/styled/Flex'
import { COLOR } from '../common/const'
import Wrapper from './Wrapper'
import Text from '../components/base/styled/Text'

class TopHeader extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            navbarToggleOpened: false
        }
    }

    hideNavbar = () => {
        this.refs.toggler.click()
    }

    NavbarLink = ({ to, children, className }) => (
        <Link to={ to } onClick={ this.hideNavbar } className={ className }>
            { children }
        </Link>
    )

    render() {
        const { userInfo, dark } = this.props
        const backgroundColor = dark ? COLOR.HIPPO : COLOR.WHITE
        const borderColor = dark ? COLOR.WHITE : ''
        const linkClassname = dark ? "navbar-link-white" : "navbar-link"
        const logo = dark ? "/img/mm-logo-white.svg" : "/img/mm-logo.svg"
        const hamburgerClassName = dark ? "hamburger-icon-white" : "hamburger-icon"
        const _Flex = styled(Flex)`
          @media (max-width: 991px) {
            min-height: 60px;
            padding: 0 1rem;
          }
          min-height: 72px;
          background-color: ${ backgroundColor };
          padding: 0;
        `

        if (userInfo == null) {
            return (
                null
            )
        }

        return <_Flex alignCenter>
            <Wrapper>
                <nav className="hp-p-0 navbar navbar-toggleable-md w-100">
                {/* <div style={{ minHeight: "60px"}}> */}
                <div>
                    <button 
                        className="navbar-toggler navbar-toggler-right" 
                        style={{ height:"60px" }} 
                        type="button" 
                        data-toggle="collapse" 
                        data-target="#navbartoggler" 
                        aria-controls="navbartoggler" 
                        aria-expanded="false" 
                        aria-label="Toggle navigation"
                        ref="toggler"
                    >
                        <i className={ `fa fa-align-right ${hamburgerClassName}` }></i>
                    </button>
                    <Link className="navbar-brand hp-m-0 hp-p-0" to='/'><img height='53px' src={ logo } alt="" /><Text bold orange small className="hp-ml-02" style={{ display:"inline-block", position: "relative", top: "-0.55rem" }}>BETA</Text></Link> 
                </div>
                <div className="collapse navbar-collapse h-100 navbar-toggler-style" id="navbartoggler">
                    <ul className="navbar-nav ml-auto mt-lg-0">
                    <li className="nav-item">
                        <this.NavbarLink to="/" className={ linkClassname }><i className="fa fa-home hp-mr-05"/>หน้าแรก</this.NavbarLink>
                    </li>
                    <li className="nav-item dropdown">
                        <Link to="#" className={ linkClassname } data-toggle="dropdown">
                            <i className="fa fa-file-text-o hp-mr-05"/>ทำโจทย์<i className="fa fa-caret-down hp-ml-05"/>
                        </Link>
                        <div className="dropdown-menu">
                        <Flex col alignStart>
                            <this.NavbarLink to='/select-tests' className="navbar-dropdown w-100">
                                ทำโจทย์แนวข้อสอบ
                            </this.NavbarLink>
                            <this.NavbarLink to="/select-topics" className="navbar-dropdown w-100">
                                ทำโจทย์แยกบท
                            </this.NavbarLink>
                            {/* <this.NavbarLink to='/custom-exercise' className="navbar-dropdown w-100">
                                สร้างแบบฝึกหัด
                            </this.NavbarLink> */}
                        </Flex>
                        </div>
                    </li>
                    {/* <li className="nav-item">
                        <this.NavbarLink to="/profile" className={ linkClassname }><i className="fa fa-bar-chart-o hp-mr-05"/>โปรไฟล์</this.NavbarLink>
                    </li> */}
                    <li className="dropdown nav-item user-nav-item" style={{ borderColor: borderColor }}>
                        { !userInfo
                            ? <this.NavbarLink to="/login" className={ linkClassname }>Login</this.NavbarLink>
                            : <Link 
                                to="#" 
                                className={ linkClassname } 
                                data-toggle="dropdown"
                            >
                                <i className="fa fa-user-circle-o hp-mr-05"/>{userInfo.email}<i className="fa fa-caret-down hp-ml-05"/>
                            </Link>
                        }
                        <div className="dropdown-menu">
                            <Flex col alignStart>
                                <this.NavbarLink to="/account-info" className="navbar-dropdown w-100">
                                    <i className="fa fa-cog hp-mr-05"></i>ข้อมูลผู้ใช้
                                </this.NavbarLink>
                                <this.NavbarLink to="/logout" className="navbar-dropdown w-100">
                                    <i className="fa fa-sign-out hp-mr-05"></i>ออกจากระบบ
                                </this.NavbarLink>
                            </Flex>
                        </div>
                    </li>
                    </ul>
                </div>
                </nav>
            </Wrapper>
        </_Flex>
    }
}

function mapStateToProps(state) {
    return {
        userInfo: state.user.userInfo,
    }
}

export default connect(mapStateToProps, null)(TopHeader)