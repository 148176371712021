import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Player from '@vimeo/player';

const Wrapper = styled('div')`
  width: 100%;
  height: 100%;
  iframe {
    width: 100%;
    height: 100%;
  }
`;

const DEFAULT_WIDTH = 640;
export default class VimeoPlayer extends Component {
  player = null;
  componentWillReceiveProps(nextProps) {
    const { videoId } = nextProps;
    if (videoId == this.props.videoId) {
      return;
    }

    if (this.player) {
      this.player.loadVideo(videoId);
    }
  }

  componentDidMount() {
    const { videoId, onTimeUpdate } = this.props;
    const width = window.innerWidth > DEFAULT_WIDTH + 70 ? DEFAULT_WIDTH : window.innerWidth - 70;

    if (this.refs.player) {
      this.player = new Player(this.refs.player, {
        id: videoId,
        width: width,
      });
      this.player.on('timeupdate', (data) => {
        onTimeUpdate(data);
      });
    }
  }

  componentDidUpdate() {
    const { videoId, onTimeUpdate } = this.props;
    const width = window.innerWidth > DEFAULT_WIDTH + 70 ? DEFAULT_WIDTH : window.innerWidth - 70;

    if (this.refs.player) {
      this.player = new Player(this.refs.player, {
        id: videoId,
        width: width,
        //maxwidth: window.innerWidth,
      });
      this.player.on('timeupdate', (data) => {
        onTimeUpdate(data);
      });
    }
  }

  render() {
    return (
      <Wrapper>
        <div ref="player" style={{ width: '100%', height: '100%' }}></div>
      </Wrapper>
    );
  }
}

VimeoPlayer.propTypes = {
  videoId: PropTypes.number.isRequired,
  onTimeUpdate: PropTypes.func,
};
