import styled from 'styled-components';
import PropTypes from 'prop-types';
import { darken, lighten, opacify } from 'polished';

import Flex from './Flex';

const ButtonBaseContainer = styled(Flex)`
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
  border: 1px solid;
  border-radius: 100px;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  white-space: nowrap;

  font-size: 14px;
  padding: 12px 18px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 16px;
    padding: 12px 24px;
  }
  ${({ dense, square, theme }) => {
    if (square && dense) {
      return `
        font-size: 14px;
        padding: 8px 8px;
        @media screen and (min-width: ${theme.breakpoints[0]}) {
          padding: 8px 8px;
        }
      `;
    } else if (dense) {
      return `
        font-size: 14px;
        padding: 8px 14px;
        @media screen and (min-width: ${theme.breakpoints[0]}) {
          padding: 8px 20px;
        }
      `;
    } else if (square) {
      return `
        padding: 12px;
      `;
    }
  }}
`;

ButtonBaseContainer.propTypes = {
  dense: PropTypes.bool,
};
ButtonBaseContainer.defaultProps = {
  dense: false,
  width: 'fit-content',
};

const DisabledButton = styled(ButtonBaseContainer)`
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: white;
  cursor: not-allowed;
`;

const LoadingButton = ({ ...restProps }) => {
  return (
    <DisabledButton {...restProps}>
      <img src="/img/Ripple.svg" style={{ width: '16px' }} />
    </DisabledButton>
  );
};

const ButtonBase = ({ disabled, loading, children, width, ...restProps }) => {
  if (disabled) {
    return (
      <DisabledButton disabled={true} width={width}>
        {children}
      </DisabledButton>
    );
  } else if (loading) {
    return <LoadingButton disabled={true} style={{ minWidth: '120px' }} width={width} />;
  } else {
    return (
      <ButtonBaseContainer width={width} {...restProps}>
        {' '}
        {children}
      </ButtonBaseContainer>
    );
  }
};

const PrimaryMainButton = styled(ButtonBase)`
  background-color: #00afaf;
  color: white;

  @media (hover: hover) {
    &:hover {
      background-color: ${darken(0.15, '#00afaf')};
    }
  }
  &:active {
    background-color: ${darken(0.3, '#00afaf')};
  }
`;

const PrimaryDefaultButton = styled(ButtonBase)`
  background-color: #ddf4f4;
  border-color: #ddf4f4;
  color: #1d9494;

  @media (hover: hover) {
    &:hover {
      background-color: ${darken(0.05, '#ddf4f4')};
      border-color: ${darken(0.05, '#ddf4f4')};
    }
  }
  &:active {
    background-color: ${darken(0.1, '#ddf4f4')};
    border-color: ${darken(0.1, '#ddf4f4')};
  }
`;

const PrimaryOutlineButton = styled(ButtonBase)`
  border-color: #2db5b5;
  background-color: transparent;
  color: #1d9494;

  @media (hover: hover) {
    &:hover {
      border-color: ${darken(0.15, '#2db5b5')};
    }
  }
  &:active {
    border-color: ${darken(0.3, '#2db5b5')};
  }
`;

const PrimaryFlatButton = styled(ButtonBase)`
  border-color: transparent;
  background-color: transparent;
  color: #2db5b5;

  @media (hover: hover) {
    &:hover {
      border-color: ${lighten(0.03, '#ddf4f4')};
      background-color: ${lighten(0.03, '#ddf4f4')};
    }
  }
  &:active {
    border-color: #ddf4f4;
    background-color: #ddf4f4;
  }
`;

const PrimaryTextButton = styled(ButtonBase)`
  border-color: transparent;
  background-color: transparent;
  padding: 0px;

  ${({ theme }) => {
    return `
    color: ${theme.colors.primary};
    @media (hover: hover) {
      &:hover {
        color: ${darken(0.15, theme.colors.primary)};
      }
    }
    &:active {
      color: ${darken(0.25, theme.colors.primary)};
    }
    `;
  }}
`;

const SecondaryMainButton = styled(ButtonBase)`
  background-color: #282828;
  border-color: #282828;
  color: black;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(32, 181, 181, 0.25);
      border-color: rgba(32, 181, 181, 0.25);
    }
  }
  &:active {
    background-color: rgba(32, 181, 181, 0.35);
    border-color: rgba(32, 181, 181, 0.35);
  }
`;

const SecondaryDefaultButton = styled(ButtonBase)`
  background-color: #f0f0f0;
  border-color: #f0f0f0;
  color: black;

  @media (hover: hover) {
    &:hover {
      background-color: ${darken(0.15, '#f0f0f0')};
      border-color: ${darken(0.15, '#f0f0f0')};
    }
  }
  &:active {
    background-color: ${darken(0.3, '#f0f0f0')};
    border-color: ${darken(0.3, '#f0f0f0')};
  }
`;

const SecondaryOutlineButton = styled(ButtonBase)`
  border-color: #282828;
  background-color: transparent;
  color: black;

  @media (hover: hover) {
    &:hover {
      border-color: ${darken(0.15, '#282828')};
    }
  }
  &:active {
    border-color: ${darken(0.3, '#282828')};
  }
`;

const SecondaryFlatButton = styled(ButtonBase)`
  border-color: transparent;
  background-color: transparent;
  color: black;

  @media (hover: hover) {
    &:hover {
      border-color: ${lighten(0.03, '#f0f0f0')};
      background-color: ${lighten(0.03, '#f0f0f0')};
    }
  }

  &:active {
    border-color: #f0f0f0;
    background-color: #f0f0f0;
  }
`;

const SecondaryTextButton = styled(ButtonBase)`
  border-color: transparent;
  background-color: transparent;
  padding: 0px;

  ${({ theme }) => {
    return `
    color: ${lighten(0.3, theme.colors.secondary)};
    @media (hover: hover) {
      &:hover {
        color: ${lighten(0.15, theme.colors.secondary)};
      }
    }
    &:active {
      color: ${theme.colors.secondary};
    }
    `;
  }}
`;

const ButtonGrey = styled(ButtonBase)`
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
  color: black;

  @media (hover: hover) {
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      border-color: rgba(0, 0, 0, 0.3);
    }
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.4);
    border-color: rgba(0, 0, 0, 0.4);
  }
`;

const Button = ButtonBase;

Button.Primary = PrimaryMainButton;
Button.Primary.Main = PrimaryMainButton;
Button.Primary.Default = PrimaryDefaultButton;
Button.Primary.Outline = PrimaryOutlineButton;
Button.Primary.Flat = PrimaryFlatButton;
Button.Primary.Text = PrimaryTextButton;

Button.Secondary = SecondaryMainButton;
Button.Secondary.Main = SecondaryMainButton;
Button.Secondary.Default = SecondaryDefaultButton;
Button.Secondary.Outline = SecondaryOutlineButton;
Button.Secondary.Flat = SecondaryFlatButton;
Button.Secondary.Text = SecondaryTextButton;

Button.Grey = ButtonGrey;

export default Button;
