import React from 'react';
import styled from 'styled-components';

import { Modal, Button, Text, Flex, Box, Icon, List } from '../../base';
import { fromSecondsToMinutes, fromHHMMSS } from '../../../common/util';

const HeaderLayout = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas:
    'title '
    'high-score'
    'info ';

  grid-template-rows: auto;

  grid-column-gap: 32px;
  grid-row-gap: 4px;

  align-items: stretch;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'info';
  }
`;

const StartSessionModalView = ({
  open,
  onClose,
  onStartSession,
  title,
  duration,
  questionCount,
  generalDescriptions,
  testDescriptions,
  highScore,
  loading,
}) => {
  return (
    <Modal open={open} onClose={onClose} size={'sm'}>
      <HeaderLayout mb={4}>
        <Text.Title.Md mb={1} pr={3} gridArea={'title'}>
          {title}
        </Text.Title.Md>
        <Flex gridArea={'info'}>
          <Flex mr={3} alignItems={'center'}>
            <Icon name={Icon.ICON_LIST.QUESTION_COUNT} mr={2} />
            <Text.Paragraph>{questionCount} ข้อ</Text.Paragraph>
          </Flex>
          <Flex alignItems={'center'}>
            <Icon name={Icon.ICON_LIST.TEST_DURATION} mr={2} />
            <Text.Paragraph>{fromSecondsToMinutes(fromHHMMSS(duration))} นาที</Text.Paragraph>
          </Flex>
        </Flex>
        {/* <Flex
          flexDirection={['row', 'column']}
          justifyContent={'flex-start'}
          gridArea={'high-score'}
        >
          {highScore && (
            <>
              <Text.Title.Sm textAlign={'right'}>{highScore}</Text.Title.Sm>
              <Text.Subtitle.Sm textAlign={'right'}>คะแนนสูงสุดของคุณ</Text.Subtitle.Sm>
            </>
          )}
        </Flex> */}
      </HeaderLayout>

      <List mb={4}>
        {generalDescriptions.map((desc) => {
          return <List.Item key={desc}>{desc}</List.Item>;
        })}
      </List>
      <Text.Title.Xs mb={3}>เมื่อเริ่มทำข้อสอบ</Text.Title.Xs>
      <List mb={5}>
        {testDescriptions.map((desc) => {
          return <List.Item key={desc}>{desc}</List.Item>;
        })}
      </List>
      <Flex justifyContent={'center'}>
        <Button.Primary.Main onClick={onStartSession} loading={loading} width={'180px'}>
          เริ่มทำข้อสอบทั้งชุด
        </Button.Primary.Main>
      </Flex>
    </Modal>
  );
};

export default StartSessionModalView;
