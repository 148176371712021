import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR } from '../../../common/const'

import Flex from '../../../components/base/styled/Flex'
import SessionSidebarExercise from './SessionSidebarExercise'
import SessionSidebarTest from './SessionSidebarTest'
import { SOLVE_MODE } from '../../../common/const'

class SessionSidebar extends Component {
    render() {
        // const { mode, problems, index, setProblemIndex, submitTest, openSummaryModal } = this.props
        return (
            <_Flex col>
                <_SessionSidebar 
                    { ...this.props }
                />
            </_Flex>
        )
    }
}

SessionSidebar.propTypes = {
    mode: PropTypes.oneOf(Object.keys(SOLVE_MODE).map(k => SOLVE_MODE[k])),
    index: PropTypes.number, 
    problems: PropTypes.array,
    setProblemIndex: PropTypes.func,
    finishSession: PropTypes.func,
    submitTest: PropTypes.func,
    solutionMode: PropTypes.bool
}

export default SessionSidebar

const _SessionSidebar = ({ mode, problems, index, setProblemIndex, submitTest, openSummaryModal, solutionMode, finishSession }) => {
    switch(mode) {
        case SOLVE_MODE.MASTERY:
        case SOLVE_MODE.EXERCISE:
            return <SessionSidebarExercise
                problems={ problems }
                index={ index }
                setProblemIndex={ setProblemIndex }
                solutionMode={ solutionMode }
                finishSession={ finishSession }
            />
        case SOLVE_MODE.TEST:
        case SOLVE_MODE.TEST_TIMER:
        default:
            return <SessionSidebarTest
                problems={ problems }
                index={ index }
                setProblemIndex={ setProblemIndex }
                submitTest={ submitTest }
                solutionMode={ solutionMode }
            />
    }
}

const _Flex = styled(Flex)`
    @media (max-width: 991px) {
        margin-left: 0;
        padding: 0 1.5rem;
        width: 100%;
        border-bottom: 1px solid ${COLOR.LIGHTER_GREY};
        border-radius: 0;
        flex-grow: 1;
        flex-basis: auto;
        box-shadow: none;
    }
    padding: 1.5rem;
    margin-right: 0.75rem;
    align-items: center;
    flex-basis: 30%;
    background-color: ${ COLOR.WHITE };
    border-radius: 0px 0px 10px 10px;
    box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);
    height: fit-content;
    
`
