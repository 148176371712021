import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../common/const';
import { Modal, Text, Image, Flex, Button } from '../../components/base';
import Timer from '../../components/practice/CountdownTimer';

const TimeUpModal = ({ open, onSeeSolution, onLeaveSession }) => {
  return (
    <Modal open={open} closeIcon={false} disableBackdropClick={true}>
      <Flex flexDirection={'column'} alignItems={'center'}>
        <Image src={'/img/hippoThanks.svg'} width={'120px'} mb={4} />
        <Text.Title.Md mb={4}>หมดเวลา!</Text.Title.Md>
        <Button.Primary.Main onClick={onSeeSolution} width={'220px'} mb={3}>
          ส่งกระดาษคำตอบและดูเฉลย
        </Button.Primary.Main>
        <Button.Secondary.Default onClick={onLeaveSession} width={'220px'}>
          ออกจากห้องสอบ
        </Button.Secondary.Default>
      </Flex>
    </Modal>
  );
};
export default class Subheader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeUpModalOpen: false,
      warningTime: false,
    };
    this.timer = React.createRef();
  }

  getElapsedTime = () => {
    return this.timer.current.getElapsedTime();
  };

  stopTimer = () => {
    return this.timer.current.stopTimer();
  };

  handleOpenTimeUpModal = () => {
    this.setState({ timeUpModalOpen: true });
  };

  handleCloseTimeUpModal = () => {
    this.setState({ timeUpModalOpen: false });
  };
  handleTimeUp = () => {
    this.props.onTimeUp();
    this.handleOpenTimeUpModal();
  };

  handleSeeSolution = () => {
    this.props.onSeeSolution();
    this.handleCloseTimeUpModal();
  };

  handleLeaveSession = () => {
    this.props.leaveSession();
  };

  handleWarnTimeUp = () => {
    this.setState({ warningTime: true });
  };

  render() {
    const { leaveSession, duration, solutionMode, isReviewSession } = this.props;
    return (
      <SubheaderBase alignCenter className="justify-content-end justify-content-lg-between">
        {!isReviewSession ? (
          <>
            <Timer
              ref={this.timer}
              style={{
                fontSize: '1.25rem',
                color: this.state.warningTime ? COLOR.RED : COLOR.HIPPO,
              }}
              duration={duration}
              solutionMode={solutionMode}
              onTimeUp={this.handleTimeUp}
              warningTime={5}
              onWarning={this.handleWarnTimeUp}
            />
            <TimeUpModal
              open={this.state.timeUpModalOpen}
              onSeeSolution={this.handleSeeSolution}
              onLeaveSession={this.handleLeaveSession}
            />
          </>
        ) : null}

        {/* <ActionIcon size="1.675rem" className="fa fa-times ml-3 ml-lg-0" onClick={leaveSession} /> */}
      </SubheaderBase>
    );
  }
}

const SubheaderBase = styled(Flex)`
  background-color: ${COLOR.WHITE};
  min-height: 4.5rem;
  padding: 0 1.5rem;
  margin-left: 0.75rem;
  align-items: center;
  flex-basis: 70%;
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid ${COLOR.LIGHTER_GREY};
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.2);

  @media (max-width: 991px) {
    margin-left: 0;
    max-width: 50%;
    border-radius: 0;
    box-shadow: none;
    min-height: 4rem;
  }
`;
