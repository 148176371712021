import React from 'react'
import PropTypes from 'prop-types'

import Choice from './Choice'

const ProblemChoice = ({ choiceId, selectedChoiceId, completed, isCorrect, answerKey, ...others }) => {
    if (completed) {
        return isCorrect && choiceId === selectedChoiceId ? <Choice correct { ...others } />
            : !isCorrect && choiceId === selectedChoiceId ? <Choice wrong { ...others } />
            : !isCorrect && choiceId === answerKey ? <Choice rightanswer { ...others } />
            : <Choice disabled { ...others } />
    }

    return choiceId === selectedChoiceId ? <Choice selected { ...others } /> : <Choice { ...others } />
}

Choice.propTypes = {
    choiceId: PropTypes.string,
    selectedChoiceId: PropTypes.string,
    completed: PropTypes.bool,
    isCorrect: PropTypes.bool,
    answerKey: PropTypes.string,
}

export default ProblemChoice

