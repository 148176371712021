import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../common/const'

const Flex = styled.div`
    display:-webkit-flex;
    display: flex;
    width: ${props => props.widthAuto ? "auto" : "100%"};
    max-width: 100vw;

    -webkit-flex-direction: ${props => props.col ? "column" : " "};
    flex-direction: ${props => props.col ? "column" : " "};

    -webkit-justify-content: ${props => props.end ? "flex-end" : 
        props.center ? "center" : 
        props.between ? "space-between":
        props.around ? "space-around" : 'flex-start'};
    justify-content: ${props => props.end ? "flex-end" : 
        props.center ? "center" : 
        props.between ? "space-between":
        props.around ? "space-around" : 'flex-start'};
    
    -webkit-align-items: ${props => props.alignEnd ? "flex-end" : 
        props.alignCenter ? "center" : 
        props.alignStart ? "flex-start":
        props.alignBase ? "baseline" : 'stretch'};
    align-items: ${props => props.alignEnd ? "flex-end" : 
        props.alignCenter ? "center" : 
        props.alignStart ? "flex-start":
        props.alignBase ? "baseline" : 'stretch'};
`

export default Flex

Flex.propTypes = {
    col: PropTypes.bool, //Flex-column
    end: PropTypes.bool, //Justify-content-end
    center: PropTypes.bool, //Justify-content-center
    between: PropTypes.bool, //Justify-content-between
    around: PropTypes.bool, //Justify-content-around
    alignEnd: PropTypes.bool, //align-items-end
    alignCenter: PropTypes.bool,  //align-items-center
    alignStart: PropTypes.bool, //align-items-start
    alignBase: PropTypes.bool, //align-items-baseline
}