import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import moment from 'moment';

import apiClient from '../../api';
import Card, { CardHeader } from '../../components/base/styled/Card';
import { SESSION_TYPE_TEST } from '../../common/const';

import PropTypes from 'prop-types';
import { hackTestName } from '../../common/util';
import Table from '../../components/tables/Table';
import { setSessionInfo, startSolutionSession } from '../../actions/solve';

class Sessions extends Component {
  state = {
    sessions: [],
  }

  componentWillMount() {
    apiClient.practice.getSessions()
      .then(sessions => {
        this.setState({ sessions });
      })
  } 
  
  initSession = sessionId => {
    this.props.setSessionInfo({
        type: SESSION_TYPE_TEST,
    })

    browserHistory.push('/session');
    apiClient.practice.getSession(sessionId)
      .then(session => {
        this.props.startSolutionSession(
          session.id, 
          session.title,
          session.questions
        )
      })
  }

  render() {
    const { sessions } = this.state;
    return <Card>
      <div style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
        <CardHeader 
          className="mb-0 mb-sm-4" 
          title="ข้อสอบที่เคยทำ"
        />
      </div>
      <SessionTable
        sessions={sessions}
        initSession={this.initSession}
        itemsPerPage={10}
      />
    </Card>
  }
}

function mapStateToProps(state) {
  return {
    questionStat: state.stat.userQuestionStat,
  }
}

export default connect(mapStateToProps, { startSolutionSession, setSessionInfo })(Sessions)

class SessionTable extends Component {
  static propTypes = {
    sessions: PropTypes.array.isRequired,
    initSession: PropTypes.func.isRequired,
    itemsPerPage: PropTypes.number
  }

  // TODO: Fix sorting by score.
  columns = [
    {
      header: 'วันที่ทำ',
      accessor: 'datetime',
      width: 1.5,
      render: dt => moment(dt).format("YYYY-MM-DD, h:mmA")
    },
    {
      header: 'ชุดแนวข้อสอบ',
      accessor: 'title',
      width: 1.5,
      render: title => title ? hackTestName(title) : 'ไม่ระบุ'
    },
    {
      header: 'คะแนน',
      accessor: 'score',
      width: 0.5,
      render: score => `${score.correct} / ${score.total}`
    },
  ]

  handleSelectSession = sessionId => {
    this.props.initSession(sessionId);
  }

  render() {
    const { sessions, itemsPerPage } = this.props
    
    return <div>
      <Table 
        columns={this.columns}
        values={sessions}
        rowClickCallback={s => this.handleSelectSession(s.id)}
        itemsPerPage={itemsPerPage}
      />
    </div>
  }
}