import React from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import _sortBy from 'lodash/sortBy';

import apiClient from '../../api';

const translateUserType = (userType) => {
  // TODO: This should be from server?
  switch (userType) {
    case 0:
      return 'นักเรียน';
    case 1:
      return 'ครู';
    case 2:
      return 'ติวเตอร์';
    case 3:
      return 'ผู้ปกตรอง';
    case 4:
    default:
      return 'บุคคลทั่วไป';
  }
};

const formatUserInfo = (userInfo) => {
  return {
    displayName: `${userInfo.firstname} ${userInfo.lastname}`,
    email: userInfo.email,
    jobTitle: translateUserType(userInfo.user_type),
    packages: userInfo.packages,
  };
};

const formatPackages = (packages, userInfo) => {
  const { packages: userPackages } = userInfo;
  return packages.map((singlePackage) => {
    const {
      name,
      description,
      expiryDate,
      includedTests,
      price,
      displayPrice,
      id,
      detail,
      index,
    } = singlePackage;
    const accessibleTestNames = includedTests.map((test) => test.name);
    const formattedExpiryDate = dayjs(expiryDate).format('DD MMM YYYY');
    const isEnrolled = userPackages.includes(id);
    return {
      name,
      description,
      expiryDate: formattedExpiryDate,
      accessibleTestNames,
      price,
      displayPrice,
      id,
      detail,
      isEnrolled,
      index,
    };
  });
};

const AccountInfoViewV1Container = ({ children, router, userInfo, ...restProps }) => {
  const [packages, setPackages] = React.useState(null);
  React.useEffect(() => {
    const getPackages = async () => {
      const packages = await apiClient.packages.getPackages();
      const formattedPackages = formatPackages(packages, userInfo);

      const enrolledPackages = formattedPackages.filter((p) => p.isEnrolled === true);
      const notEnrolledPackages = formattedPackages.filter((p) => !p.isEnrolled);
      const sortedEnrolledPackages = _sortBy(enrolledPackages, ['index']);
      const sortedNotEnrolledPackages = _sortBy(notEnrolledPackages, ['index']);

      const sortedPackages = [...sortedEnrolledPackages, ...sortedNotEnrolledPackages];

      setPackages(sortedPackages);
    };

    getPackages();
  }, []);

  const handlePackageActivated = () => {
    location.reload();
  };

  return children({
    userInfo: formatUserInfo(userInfo),
    packages,
    onPackageActivated: handlePackageActivated,
  });
};

function mapStateToProps(state) {
  return {
    userInfo: state.user.userInfo,
  };
}

export default connect(mapStateToProps, null)(AccountInfoViewV1Container);
