import React, { Component } from 'react';
import moment from 'moment';
import { PieChart, Pie, Cell, Legend, Tooltip } from 'recharts';

import Card, { CardHeader } from '../../components/base/styled/Card';
import SidebarListItem from '../../components/base/List/SidebarList/SidebarListItem';
import SidebarList from '../../components/base/List/SidebarList';
import Flex from '../../components/base/styled/Flex';
import { COLOR } from '../../common/const';

const RADIAN = Math.PI / 180;
class TotalProblems extends Component {
  state = {
    problemsCompleted: null,
    correctCount: null,
    weekProblemsCompleted: null,
  };

  renderLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  componentWillMount() {
    this.calculateProblemsCompleted(this.props.problemsCount);
  }

  componentWillReceiveProps(nextProps) {
    this.calculateProblemsCompleted(nextProps.problemsCount);
  }

  calculateProblemsCompleted = problemsCount => {
    if (!problemsCount) {
      return;
    }

    const problemsCompleted = Object.keys(problemsCount).reduce((sum, d) => {
      sum += problemsCount[d].completed_count;
      return sum;
    }, 0);

    const correctCount = Object.keys(problemsCount).reduce((sum, d) => {
      sum += problemsCount[d].correct_count;
      return sum;
    }, 0);

    const lastWeek = moment().subtract(7, 'day');
    const today = moment();

    let weekProblemsCompleted = Object.keys(problemsCount).reduce((sum, d) => {
      const curDate = moment(d);
      if (curDate <= today && curDate >= lastWeek) {
        sum += problemsCount[d].completed_count;
      }
      return sum;
    }, 0);

    this.setState({
      problemsCompleted,
      correctCount,
      weekProblemsCompleted,
    });
  };

  render() {
    const { problemsCompleted, correctCount, weekProblemsCompleted } = this.state;
    // if (!problemsCompleted) {
    //   return null;
    // }

    const wrongCount = problemsCompleted - correctCount;
    const data = [
      { name: 'ข้อที่ถูก', value: correctCount, color: COLOR.GREEN },
      { name: 'ข้อที่ผิด', value: wrongCount, color: COLOR.RED },
    ];

    return (
      <Card className="hp-p-15">
        <CardHeader title="สถิติ" noMargin />
        <div className="row">
          <div className="col-lg-12 col-md-6 col-sm-12">
            <SidebarList>
              <div className="row">
                <div className="col-lg-12 col-12">
                  <SidebarListItem
                    title="จำนวนโจทย์ที่ทำทั้งหมด"
                    value={problemsCompleted}
                    unit="ข้อ"
                  />
                </div>
                <div className="col-lg-12 col-12">
                  <SidebarListItem
                    title="จำนวนโจทย์ที่ทำสัปดาห์นี้"
                    value={weekProblemsCompleted}
                    unit="ข้อ"
                  />
                </div>
                <div className="col-lg-12 col-12">
                  <SidebarListItem title="จำนวนโจทย์ที่ทำถูก" value={correctCount} unit="ข้อ" />
                </div>
                <div className="col-lg-12 col-12">
                  <SidebarListItem title="จำนวนโจทย์ที่ทำผิด" value={wrongCount} unit="ข้อ" />
                </div>
              </div>
            </SidebarList>
          </div>
          {problemsCompleted ? <ProblemsCompletedChart data={data} /> : null}
        </div>
      </Card>
    );
  }
}

export default TotalProblems;

const ProblemsCompletedChart = ({ data }) => {
  return (
    <div className="col-lg-12 col-md-6 col-sm-12">
      <Flex col alignCenter>
        <PieChart width={285} height={285}>
          <Pie
            data={data}
            dataKey="value"
            legendType="circle"
            innerRadius={60}
            outerRadius={100}
            labelLine={false}
          >
            {data.map((d, index) => (
              <Cell key={index} fill={d.color} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </Flex>
    </div>
  );
};
