import apiClient from '../api';

const GET_ALL_TOPICS = 'GET_ALL_TOPICS';
const GET_ALL_DIFFICULTIES = 'GET_ALL_DIFFICULTIES';
const GET_ALL_TAGS = 'GET_ALL_TAGS';

export function getAllTopics() {
  return (dispatch) => {
    apiClient.topic.getAllTopics().then((topics) => {
      dispatch({
        type: GET_ALL_TOPICS,
        topics: topics,
      });
    });
  };
}

export function getAllDifficulties() {
  return (dispatch) => {
    apiClient.topic.getAllDifficulties().then((difficulties) => {
      dispatch({
        type: GET_ALL_DIFFICULTIES,
        difficulties: difficulties,
      });
    });
  };
}

export function getAllTags() {
  return (dispatch) => {
    apiClient.tag.getAllTagsV0().then((tags) => {
      dispatch({
        type: GET_ALL_TAGS,
        tags: tags,
      });
    });
  };
}

const ACTION_HANDLERS = {
  [GET_ALL_TOPICS]: handleGetAllTopics,
  [GET_ALL_DIFFICULTIES]: handleGetAllDifficulties,
  [GET_ALL_TAGS]: handleGetAllTags,
};

function _generateIdNameMap(topic, map) {
  map[topic.id] = topic.name;
  topic.children.forEach((t) => {
    _generateIdNameMap(t, map);
  });

  return map;
}

function handleGetAllTopics(state, action) {
  // Generate topic's name-id mapping.
  const topicIdNameMap = {};
  action.topics.forEach((t) => {
    _generateIdNameMap(t, topicIdNameMap);
  });

  return { ...state, topics: action.topics, topicIdNameMap };
}

function handleGetAllDifficulties(state, action) {
  const difficulties = {};
  action.difficulties.forEach((d) => {
    difficulties[d.id] = d;
  });
  return { ...state, difficulties: difficulties };
}

function handleGetAllTags(state, action) {
  return { ...state, tags: action.tags };
}

const INITIAL_STATE = {
  topics: null,
  difficulties: null,
  tags: null,
};

export default function (state = INITIAL_STATE, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
