import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import apiClient from '../api';
import { COLOR } from '../common/const';
import FeedbackButton from './base/styled/Button';
import { Text, Button, Flex, Modal } from './base';

const FeedbackArea = styled('textarea')`
  border: 1px solid ${({ theme }) => theme.colors.greys.grey4};
  border-radius: 8px;
  min-height: 120px;
  box-shadow: none;
  padding: 24px;
  width: 100%;
  font-family: 'Bai Jamjuree', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 32px;
  transition: border-color 0.5s;

  &::placeholder {
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: ${({ theme }) => theme.colors.greys.grey2};
  }

  &:focus {
    border-color: ${({ theme }) => theme.colors.greys.grey1} !important;
  }
`;

const FeedbackCreator = ({ url }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpenModal = () => {
    setOpen(true);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <FeedbackButton
        feedback
        onClick={handleOpenModal}
        style={{ padding: '12px', fontSize: '15px' }}
      >
        <i
          className="fa fa-comment-o hp-mr-05 hp-pr-05 "
          style={{ borderRight: `1px solid ${COLOR.WHITE}`, fontWeight: 'bold' }}
        />
        แสดงความคิดเห็น
      </FeedbackButton>
      <FeedbackModal open={open} onClose={handleCloseModal} url={url} />
    </>
  );
};

FeedbackCreator.propTypes = {
  url: PropTypes.string,
};

export default FeedbackCreator;

class FeedbackModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      isSubmitted: false,
    };
  }

  onChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  submitFeedback = () => {
    apiClient.other.postFeedback(this.props.url, this.state.message);
    this.setState({
      isSubmitted: true,
    });
  };

  confirmFeedback = () => {
    this.props.closeModal();
    this.setState({
      isSubmitted: false,
    });
  };

  render() {
    const disabled = !this.state.message;

    return (
      <Modal open={this.props.open} onClose={this.props.onClose} size={'xs'}>
        <Text.Title.Md mb={4}>แสดงความคิดเห็นของคุณ!</Text.Title.Md>
        {this.state.isSubmitted === true ? (
          <Flex
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className="hp-pb-15 hp-pt-1 h-100"
          >
            <Flex
              flexDirection={'column'}
              alignItems={'center'}
              className="hp-mb-2 hp-p-05"
              style={{ height: '16rem' }}
            >
              <img src="/img/hippoThanks.svg" style={{ height: '9rem' }} />
              <Title.Paragraph mt={4} mb={2} className="hp-mb-05 hp-mt-2">
                ขอบคุณสำหรับคำแนะนำ!
              </Title.Paragraph>
              <Text.Paragraph>
                คำแนะนำของคุณจะถูกนำไปใช้ในการพัฒนาระบบของเราเพื่อการใช้งานที่ดียิ่งขึ้นสำหรับคุณ
              </Text.Paragraph>
            </Flex>
            <Button.Primary.Main width="120px" mt={'auto'} onClick={this.confirmFeedback}>
              ตกลง
            </Button.Primary.Main>
          </Flex>
        ) : (
          <Flex flexDirection={'column'} justifyContent={'space-between'} alignItems={'center'}>
            <FeedbackArea
              placeholder="รายละเอียดความคิดเห็น (ใช้งานยาก, ฟีเจอร์ที่อยากได้, ...)"
              value={this.state.message}
              onChange={this.onChange}
            />

            <Button.Primary.Main isDisabled={disabled} onClick={this.submitFeedback}>
              ส่งความคิดเห็น
              {disabled ? (
                <ReactTooltip id="submit" place="top" type="error">
                  พิมพ์คำแนะนำก่อนนะ!
                </ReactTooltip>
              ) : null}
            </Button.Primary.Main>
          </Flex>
        )}
      </Modal>
    );
  }
}

FeedbackModal.propTypes = {
  url: PropTypes.string,
};
