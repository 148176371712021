import _sortBy from 'lodash/sortBy';

export const transformQuestionInfo = (questionInfo, allTags) => {
  if (!questionInfo || !allTags) {
    throw new Error('No questionInfo or tags provided!');
  }
  const { category, difficulty, exam, has_video, id, index, tag } = questionInfo;
  const formattedTags = tag.map((tagId) => {
    const tagInfo = allTags.find((t) => t.id === tagId);
    return tagInfo;
  });

  return {
    topics: category,
    difficulty,
    exam,
    hasVideo: has_video,
    id,
    index,
    tag: formattedTags,
  };
};

export const transformTestInfo = (testInfo) => {
  const {
    date_created,
    description,
    duration,
    editor,
    id,
    image,
    is_new,
    last_modified,
    name,
    question_count,
    questions,
    status,
    tag,
    year,
    index,
  } = testInfo;
  return {
    createdDate: date_created,
    description,
    duration,
    id,
    image,
    name,
    questionCount: question_count,
    questions,
    status,
    tag,
    year,
    index,
  };
};

export const transformPackages = (singlePackage) => {
  const {
    name,
    date_created,
    date_expired,
    description,
    id,
    included_tests,
    display_price,
    true_price,
    detail,
    index,
  } = singlePackage;
  const includedTests =
    Array.isArray(included_tests) && included_tests.map((test) => transformTestInfo(test));

  // This is a workaround to quickly sort tests.
  // TODO: Refactor This
  const includedTestsWithTagIndex = includedTests.map((test) => {
    return { tagIndex: test.tag[0].index, ...test };
  });
  const sortedIncludedTests = _sortBy(includedTestsWithTagIndex, ['tagIndex', 'index']);
  const reversedSortedIncludedTests = sortedIncludedTests.reverse();

  const detailArr = detail ? JSON.parse(detail) : [];
  return {
    name,
    createdDate: date_created,
    expiryDate: date_expired,
    description,
    id,
    includedTests: reversedSortedIncludedTests,
    displayPrice: display_price,
    price: true_price,
    detail: detailArr,
    index,
  };
};
