import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AppContainer,
  Box,
  Button,
  Flex,
  Card,
  Text,
  Loading,
  Tabs,
  Image,
} from '../../components/base';
import StartSessionModal from '../../components/session/StartSessionModal';
import { AnalyticContext } from '../../contexts/AnalyticContext';

import { QUERY_TYPE } from './constants';

const QueryTabs = ({ queryType, onClickTabItem, ...restProps }) => {
  const handleClickTabItem = (queryType) => {
    onClickTabItem(queryType);
  };
  return (
    <Tabs {...restProps}>
      <Tabs.Item
        selected={queryType === QUERY_TYPE.BY_TEST}
        onClick={() => handleClickTabItem(QUERY_TYPE.BY_TEST)}
      >
        เลือกจากชุดข้อสอบ
      </Tabs.Item>
      <Tabs.Item
        selected={queryType === QUERY_TYPE.BY_TOPIC}
        onClick={() => handleClickTabItem(QUERY_TYPE.BY_TOPIC)}
      >
        เลือกจากบทเรียน
      </Tabs.Item>
    </Tabs>
  );
};

const TestList = ({ tests, onSelectTest, onStartTestSession }) => {
  const handleClickTest = (id, name) => {
    onSelectTest(id, name);
  };
  const handleClickStartSession = (id, name) => {
    onStartTestSession(id, name);
  };
  return (
    <>
      {tests.map(({ name, id, highScore, fullScore }, index) => (
        <Flex
          key={id}
          flexDirection={['column', 'row']}
          justifyContent={'space-between'}
          py={3}
          style={{
            borderBottom: index + 1 !== tests.length ? '1px solid rgba(0,0,0,0.15)' : '0px solid',
          }}
        >
          <Box mb={[2, 0]}>
            <Text.Title.Xs>{name}</Text.Title.Xs>
            <Text.Subtitle.Xs>
              {highScore && fullScore ? `คะแนนสูงสุด: ${highScore}/${fullScore}` : 'ยังไม่เคยทำ'}
            </Text.Subtitle.Xs>
          </Box>

          <Flex justifyContent={['flex-start', 'flex-end']} py={1}>
            <Button.Secondary.Default
              dense={true}
              onClick={() => handleClickStartSession(id, name)}
              mr={[2, 3]}
            >
              ทำข้อสอบทั้งชุด
            </Button.Secondary.Default>
            <Button.Secondary.Outline dense={true} onClick={() => handleClickTest(id, name)}>
              ดูโจทย์ทุกข้อ
            </Button.Secondary.Outline>
          </Flex>
        </Flex>
      ))}
    </>
  );
};

const TopicList = ({ topics, onSelectTopic }) => {
  const handleClickTopic = (id) => {
    onSelectTopic(id);
  };
  return (
    <>
      {topics.map(({ name, id, imageUrl }) => (
        <Flex
          key={id}
          py={3}
          justifyContent={'space-between'}
          alignItems={'center'}
          borderBottom={'1px solid rgba(0,0,0,0.2)'}
        >
          <Flex alignItems={'center'} flexWrap={'wrap'}>
            {imageUrl && imageUrl !== '' ? (
              <Image src={imageUrl} width={'40px'} height={'40px'} borderRadius={'1000px'} mr={3} />
            ) : (
              <Box
                width={'40px'}
                height={'40px'}
                borderRadius={'1000px'}
                mr={3}
                bg={'primaries.dark'}
              />
            )}
            <Text.Title.Xs pr={3}>{name}</Text.Title.Xs>
          </Flex>
          <Button.Secondary.Default dense={true} onClick={() => handleClickTopic(id, name)}>
            ดูโจทย์ทุกข้อ
          </Button.Secondary.Default>
        </Flex>
      ))}
    </>
  );
};

const SubjectDetailView = ({
  loading,
  tests,
  topics,
  queryType,
  onChangeTab,
  onSelectTest,
  onSelectTopic,
  subject,
}) => {
  const [selectedTestId, setSelectedTestId] = React.useState(null);
  const [startSessionModalOpen, setStartSessionModalOpen] = React.useState(false);

  const { logAction } = React.useContext(AnalyticContext);

  const handleStartSession = (id, name) => {
    logAction({
      category: 'Start Session',
      action: 'Start - TestList page',
      label: `Test: ${name}}`,
    });
  };

  const handleStartTestSession = (id, name) => {
    setSelectedTestId(id);
    setStartSessionModalOpen(true);
    logAction({
      category: 'Start Session',
      action: 'Open Modal - TestList page',
      label: `Test: ${name}}`,
    });
  };

  const handleCloseStartSessionModal = (id, name) => {
    setSelectedTestId(null);
    setStartSessionModalOpen(false);
    logAction({
      category: 'Start Session',
      action: 'Close Modal - TestList page',
      label: `Test: ${name}}`,
    });
  };

  const handleSelectTopic = (id, name) => {
    onSelectTopic(id);
    logAction({
      category: 'Browse Questions',
      action: 'See Topic Questions',
      label: `Topic: ${name}}`,
    });
  };

  const handleSelectTest = (id, name) => {
    onSelectTest(id);
    logAction({
      category: 'Browse Questions',
      action: 'See Test Questions',
      label: `Test: ${name}}`,
    });
  };

  const handleChangeTab = (tab) => {
    onChangeTab(tab);
    logAction({
      category: 'Browse Questions',
      action: 'Group By',
      label: `${queryType === QUERY_TYPE.BY_TEST ? 'Test' : 'Topic'}`,
    });
  };

  return (
    <>
      <AppContainer>
        <Text.Title.Lg mb={4} as={'h1'}>
          {subject}
        </Text.Title.Lg>
        <Card>
          {loading ? (
            <Loading />
          ) : (
            <>
              <Text.Title.Md mb={4} as={'h2'}>
                เลือกโจทย์ที่ต้องการทำ
              </Text.Title.Md>
              <QueryTabs queryType={queryType} onClickTabItem={handleChangeTab} mb={4} />
              <Box display={queryType === QUERY_TYPE.BY_TEST ? 'block' : 'none'}>
                <Text.Title.Sm pb={2} mb={3} borderBottom={'1px solid black'}>
                  ชุดข้อสอบใน {subject}
                </Text.Title.Sm>
                <TestList
                  tests={tests}
                  onSelectTest={handleSelectTest}
                  onStartTestSession={handleStartTestSession}
                />
              </Box>
              <Box display={queryType === QUERY_TYPE.BY_TOPIC ? 'block' : 'none'}>
                <Text.Title.Sm pb={2} mb={3} borderBottom={'1px solid black'}>
                  หัวข้อบทเรียนใน {subject}
                </Text.Title.Sm>
                <TopicList topics={topics} onSelectTopic={handleSelectTopic} />
              </Box>
            </>
          )}
        </Card>
      </AppContainer>
      <StartSessionModal
        open={startSessionModalOpen}
        onClose={handleCloseStartSessionModal}
        onStartSession={handleStartSession}
        testId={selectedTestId}
      />
    </>
  );
};

SubjectDetailView.defaultProps = {
  tests: [],
  topics: [],
};

export default SubjectDetailView;
