import _ from 'lodash'

import apiClient from '../api'

const GET_ALL_EXERCISES = 'GET_ALL_EXERCISES'
const GET_EXERCISE_QUESTIONS = 'GET_EXERCISE_QUESTIONS'

export function getAllExercises() {
    return dispatch => {
        apiClient.exercise.getAllExercises()
            .then(exercises => {
                dispatch({
                    type: GET_ALL_EXERCISES,
                    exercises: exercises
                })
            })
    }
}

export function getExerciseQuestions(exerciseId) {
    return dispatch => {
        apiClient.exercise.getExerciseQuestions(exerciseId)
            .then(questions => {
                dispatch({
                    type: GET_EXERCISE_QUESTIONS,
                    questions: questions,
                    exerciseId: exerciseId
                })
            })
    }
}

const ACTION_HANDLERS = {
    [GET_ALL_EXERCISES]: handleGetAllExercises,
    [GET_EXERCISE_QUESTIONS]: handleGetExerciseQuestions,
}

function handleGetAllExercises(state, action) {
    const formatted = action.exercises.reduce((acc, e) => {
        const topicId = e.meta.topicid

        if (!acc[topicId]) {
            acc[topicId] = []
        }

        acc[topicId].push(e)
        return acc
    }, {})

    return { ...state, exercises: formatted }
}

function handleGetExerciseQuestions(state, action) {
    const exercises = [...state.exercises]
    const target = _.find(exercises, ['id', action.exerciseId])
    if (target) {
        target.questions = action.questions
    }

    return { ...state, exercises: exercises }
}

const INITIAL_STATE = {
    exercises: null,
}

export default function(state = INITIAL_STATE, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}