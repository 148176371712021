import React from 'react';
import styled from 'styled-components';

import Box from './Box';
import Text from './Text';

const List = styled(Box)`
  padding: 0;
  list-style-type: none;
`;
List.defaultProps = {
  as: 'ul',
};

const ListItem = styled(Text.Paragraph)`
  font-weight: 300;
  padding-left: 20px;
  margin-bottom: 4px;
  position: relative;

  &::before {
    content: '-';
    position: absolute;
    left: 4px;
  }
`;
ListItem.defaultProps = {
  as: 'li',
};

List.Item = ListItem;
export default List;
