import _ from 'lodash'

import apiClient from '../api'

const GET_ALL_TESTS = 'GET_ALL_TESTS'
const GET_TEST_QUESTIONS = 'GET_TEST_QUESTIONS'

export function getAllTests() {
    return dispatch => {
        apiClient.test.getAllTests()
            .then(tests => {
                dispatch({
                    type: GET_ALL_TESTS,
                    tests: tests
                })
            })
    }
}

export function getTestQuestions(testId) {
    return dispatch => {
        apiClient.test.getTestQuestions(testId)
            .then(questions => {
                dispatch({
                    type: GET_TEST_QUESTIONS,
                    questions: questions,
                    testId: testId
                })
            })
    }
}

const ACTION_HANDLERS = {
    [GET_ALL_TESTS]: handleGetAllTests,
    [GET_TEST_QUESTIONS]: handleGetTestQuestions,
}

function handleGetAllTests(state, action) {
    const sorted = _.chain(action.tests)
        .sortBy('name')
        .reverse()
        .value()

    return { ...state, tests: sorted }
}

function handleGetTestQuestions(state, action) {
    const tests = [...state.tests]
    const target = _.find(tests, ['id', action.testId])
    if (target) {
        target.questions = action.questions
    }

    return { ...state, tests: tests }
}

const INITIAL_STATE = {
    tests: null,
}

export default function(state = INITIAL_STATE, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}