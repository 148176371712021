import React from 'react';
import ReactGA from 'react-ga';

import AnalyticContext from './context';

const AnalyticProvider = ({ children, gaId }) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (!ready) {
      ReactGA.initialize('UA-96996537-4');
      ReactGA.pageview(window.location.pathname + window.location.search);
      setReady(true);
    }
  }, [ready]);

  const logAction = ({ category, action, label, value, ...restParams }) => {
    if (ready) {
      ReactGA.event({ category, action, label, value, ...restParams });
    }
  };
  return <AnalyticContext.Provider value={{ logAction }}>{children}</AnalyticContext.Provider>;
};

export default AnalyticProvider;
