import React, { Component } from 'react'

export default class ModalFooter extends Component {
    
    render() {
        const { actionButton, actionButtonText, cancelButton, cancelButtonText} = this.props
 
        return (
            <div className="hp-px-15">
                <hr className= "hp-m-0 hp-hr"/>
                <div className= "d-flex flex-row justify-content-end align-items-center hp-py-1">
                    <button className='btn btn-outline-hippo' onClick={ cancelButton }> { cancelButtonText } </button>
                    <button className='btn btn-hippo hp-ml-05' onClick={ actionButton }> { actionButtonText } </button>
                </div>
                
            </div>
        )
    }
}