import { getRequest, postRequest } from '../common/util';

export function bookmark(questionId) {
  return postRequest(`/action/bookmark/${questionId}/`);
}

export function unbookmark(questionId) {
  return postRequest(`/action/unbookmark/${questionId}/`);
}

export function getBookmarks(withDetail = false) {
  const params = { withDetail };
  return getRequest('/bookmarks/', params);
}

export function postFeedback(url, message) {
  const data = {
    url,
    type: 'OVERALL',
    message,
  };

  return postRequest('/feedbacks/', data);
}

export function postQuestionFeedback(questionId, message) {
  const data = {
    questionId,
    type: 'QUESTION',
    message,
  };

  return postRequest('/feedbacks/', data);
}
