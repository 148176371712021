import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../../common/const';
import Flex from '../../../components/base/styled/Flex';
import AnswerSheet from '../../../components/practice/AnswerSheet';

import { AnalyticContext } from '../../../contexts/AnalyticContext';

const SessionSidebarTest = ({ index, problems, testName, setProblemIndex, solutionMode }) => {
  const { logAction } = React.useContext(AnalyticContext);

  const handleSetProblemIndex = (newIndex) => {
    setProblemIndex(newIndex);
    logAction({
      category: solutionMode ? 'Review' : 'Session',
      action: 'Route',
      label: `From ${index} to ${newIndex} - ${testName}`,
    });
  };
  return (
    <_Flex col start>
      <AnswerSheet index={index} problems={problems} setProblemIndex={handleSetProblemIndex} />
    </_Flex>
  );
};

SessionSidebarTest.propTypes = {
  index: PropTypes.number,
  problems: PropTypes.array,
  setProblemIndex: PropTypes.func,
};

export default SessionSidebarTest;

const _Flex = styled(Flex)`
  background-color: ${COLOR.WHITE};
  min-height: calc(100vh - 281px);

  @media (max-width: 991px) {
    margin: 0;
    width: 100%;
    border-radius: 0 0 0 0;
    flex-basis: auto;
    min-height: fit-content;
  }
`;
