import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ContentContainer = styled('span')`
  & > p {
    margin-bottom: 5px;
    line-height: 1.4;
  }
`;

class MathJaxBox extends Component {
  createMarkup(text) {
    return {
      __html: text,
    };
  }

  componentDidMount() {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }

  componentDidUpdate(prevProps, prevState) {
    window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub]);
  }

  render() {
    return <ContentContainer dangerouslySetInnerHTML={this.createMarkup(this.props.content)} />;
  }
}

MathJaxBox.propTypes = {
  content: PropTypes.string,
};

export default MathJaxBox;
