import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { COLOR } from '../../../../common/const';
import CardBase from './CardBase';

const CardHeaderBase = styled(CardBase)`
  padding: 0rem 0 0.5rem 0rem;
  margin-bottom: ${(props) => (props.noMargin ? 0 : '1.5rem')};
  border-bottom: solid 1px;
  border-bottom-color: ${(props) => (props.dark ? COLOR.WHITE : COLOR.LIGHT_GREY)};
  background-color: ${(props) => (props.dark ? COLOR.GREY : 'transparent')};
  font-size: 20px;
  font-weight: 400;
  color: black;
`;

const CardHeader = ({ title, dark, className, noMargin }) => (
  <CardHeaderBase dark={dark} noMargin={noMargin} className={className}>
    {title}
  </CardHeaderBase>
);

export default CardHeader;

CardHeader.propTypes = {
  dark: PropTypes.bool, //Turn card into hippo background
  noMargin: PropTypes.bool,
  title: PropTypes.string, //Title of header
  lineMargin: PropTypes.string,
};
