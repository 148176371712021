import React from 'react';

import SubjectTopicDetailContainer from './SubjectTopicDetail.container';
import SubjectTopicDetailView from './SubjectTopicDetail.view';

const SubjectTopicDetail = (props) => {
  return (
    <SubjectTopicDetailContainer {...props}>
      {({ loading, topicInfo, questions, handleSelectQuestion, subjectInfo, handleBookmark }) => {
        return (
          <SubjectTopicDetailView
            loading={loading}
            topicInfo={topicInfo}
            questions={questions}
            onSelectQuestion={handleSelectQuestion}
            subjectInfo={subjectInfo}
            onBookmark={handleBookmark}
          />
        );
      }}
    </SubjectTopicDetailContainer>
  );
};

export default SubjectTopicDetail;
