import React from 'react';

import NavBarWrapper from './components/NavBarWrapper';
import NavBarContainer from './components/NavBarContainer';
import NavLogo from './components/NavLogo';

const EmptyNavBar = ({ mainButton }) => {
  return (
    <NavBarWrapper>
      <NavBarContainer style={{ maxWidth: '1108px' }}>
        <NavLogo />
        {mainButton}
      </NavBarContainer>
    </NavBarWrapper>
  );
};

export default EmptyNavBar;
