import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from './Button';
import Flex from './Flex';

const TabItem = ({ selected, onClick, ...restProps }) => {
  if (selected) {
    return <Button.Primary.Default onClick={onClick} {...restProps} />;
  } else {
    return <Button.Secondary.Default onClick={onClick} {...restProps} />;
  }
};
TabItem.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
};
TabItem.defaultProps = {
  onClick: () => {},
};

const Tabs = styled(Flex)`
  & > *:not(:last-child) {
    margin-right: ${({ theme }) => `${theme.space[2]}px`};
  }
`;

Tabs.Item = TabItem;

export default Tabs;
