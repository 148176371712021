import styled from 'styled-components';

import { AppContainer } from '../../base';

const NavBarContainer = styled(AppContainer)`
  padding-top: 0;
  padding-bottom: 0;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default NavBarContainer;
