const commonColors = {
  primary: '#00afaf',
  primaries: {
    light: '#80D7D7',
    main: '#00afaf',
    dark: '#008686',
    contrastText: '#fff',
  },
  secondary: '#282828',
  secondaries: {
    light: '',
    main: '',
    dark: '',
    contrastText: '#000',
  },
  warning: '#FAAF00',
  warnings: {
    light: '#FDD780',
    main: '#FAAF00',
    dark: '#008636',
    contrastText: '#000',
  },
  success: '#00AF64',
  successes: {
    light: '#DDF3E8',
    main: '#00AF64',
    dark: '#008636',
    contrastText: '#000',
  },
  danger: '#D32F2F',
  dangers: {
    light: '#E99797',
    main: '#D32F2F',
    dark: '#B71212',
    contrastText: ' #000',
  },
  info: '#0064AF',
  infos: {
    light: '#4D93C7',
    main: '#0064AF',
    dark: '#003686',
    contrastText: '#000',
  },
  grey: 'rgba(0,0,0,0.4)',
  greys: {
    grey1: 'rgba(0,0,0,0.8)',
    grey2: 'rgba(0,0,0,0.6)',
    grey3: 'rgba(0,0,0,0.4)',
    grey4: 'rgba(0,0,0,0.2)',
    grey5: 'rgba(0,0,0,0.05)',
  },
  black: '#000',
  white: '#fff',
  disabled: '#e6e6e6',
};

const customColors = {
  correct: '#00AF64',
  wrong: '#D32F2F',
  incorrect: '#D32F2F',
  text: commonColors.black,
  textLight: commonColors.greys.grey3,
};

const colors = { ...commonColors, ...customColors };
export default colors;
