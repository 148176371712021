import { getRequest, postRequest } from '../common/util'

export function getAllExercises() {
    return getRequest('/exercises/')
}

export function getExerciseQuestions(exerciseId) {
    return getRequest(`/exercises/${exerciseId}/questions/`, null)
}

export function getExerciseTopics(exerciseId) {
    return getRequest(`/exercises/${exerciseId}/topics/`, null)
}

// export function startExercise(exerciseId, sessionTitle) {
//     return postRequest('action/start-test/', { "testId": testId })
// }