import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR } from '../../common/const'

import { Title } from '../../components/base/styled/Text'
import Flex from '../../components/base/styled/Flex'

export default class SessionHeader extends Component {
    render() {
        const { children, sessionTitle } = this.props
        const _Header = styled(Flex)`
            background-color: ${ COLOR.WHITE};
            min-height: 4.5rem;
            padding: 0 1.5rem;
            margin-right: 0.75rem;
            flex-basis: 30%;
            border-radius: 10px 10px 0 0;
            border-bottom: 1px solid ${ COLOR.LIGHTER_GREY };
            box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.2);

            @media (max-width: 991px) {
                margin: 0;
                min-width: 50%;
                border-radius: 0 0 0 0;
                box-shadow: none;
                min-height: 4rem;
            }
        `

        return (
            <_Header alignCenter >
                <Title small className="text-overflow-1">{ sessionTitle }</Title>
            </_Header>
        )
    }
}
