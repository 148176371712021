import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'

import PracticeButton from '../../components/practice/PracticeButton'

const TestPracticeButton = ({ test, onClick, testResults, ...others }) => {
    let score = 0
    let fullScore = 0
    let done = false

    const result = testResults[test.id]
    if (result) {
        score = result.highscore
        fullScore = result.fullScore
        done = true
    }

    const [name, year] = test.name.split(' ')

    const pat = test.tag.includes('pat')
    const onet = test.tag.includes('onet')
    const saman = test.tag.includes('saman')

    return <PracticeButton
        onClick={ onClick }
        title={ name }
        subtitle={ year }
        pat={ pat }
        onet={ onet }
        saman={ saman }
        points={ `${ score }/${ fullScore }` }
        test
        done={ done }
        { ...others }
    />
}

TestPracticeButton.propTypes = {
    test: PropTypes.object,
    onClick: PropTypes.func,
}

function mapStateToProps(state) {
    return {
        testResults: state.stat.testResults,
    }
}

export default connect(mapStateToProps, null)(TestPracticeButton)