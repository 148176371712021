import React from 'react';
import { Route, IndexRoute } from 'react-router';
import cookie from 'react-cookie';

import { setToken } from './reducers/auth';
import { killSession } from './actions/solve';

import App from './layouts/App';
import PublicApp from './layouts/PublicApp';
import SessionAppV1 from './layouts/SessionAppV1';
import AppV1 from './layouts/AppV1';

import LoginView from './views/login';
import LogoutView from './views/logout';
import SignupView from './views/signup';
import ComingSoonView from './views/comingSoon';
import NotFoundView from './views/notfound';
import AccountInfoView from './views/AccountInfoV1';
import ProfileView from './views/profile';
import SelectTopicView from './views/selectTopics';
import TopicInfoView from './views/topicInfo';
import SelectTestsView from './views/selectTests';
import TestInfoView from './views/testInfo';
import CustomExerciseView from './views/customExercise';
import SessionView from './views/session';
import ActivateAccountView from './views/activateAccount';
import ForgetPasswordView, {
  SuccessView as ForgetPasswordSuccessView,
} from './views/forgetPassword';
import ResetPasswordView from './views/resetPassword';
import LandingRoute from './views/LandingV1';
import SubjectDetailRoute from './views/SubjectDetailV1';
import SubjectTestDetailRoute from './views/SubjectTestDetailV1';
import SubjectTopicDetailRoute from './views/SubjectTopicDetailV1';

import { postRequest } from './common/util';

function requireAuth(store, dispatch) {
  return (nextState, replace, callback) => {
    let storeToken = store.getState().auth.jwt;
    const cookieToken = cookie.load('token');

    if (!storeToken && !cookieToken) {
      replace('/login');
      callback();
    } else {
      const refreshToken = storeToken || cookieToken;
      postRequest('/action/refresh-token?mode=user', { token: refreshToken }, true).then((res) => {
        if (res.error) {
          replace('/login');
          callback();
        } else {
          store.dispatch(setToken(res.token));
          callback();
        }
      });
    }
  };
}

function killCurrentSession(store, dispatch) {
  return (nextState, replace, callback) => {
    store.dispatch(killSession());
    return callback;
  };
}

export default function getRoutes(store) {
  return (
    <Route path="/">
      <Route component={SessionAppV1} onEnter={requireAuth(store)}>
        <Route path="session" component={SessionView} onLeave={killCurrentSession(store)} />
        <Route
          path="session/review"
          component={(props) => <SessionView isReviewSession={true} {...props} />}
          onLeave={killCurrentSession(store)}
        />
      </Route>
      <Route component={AppV1} onEnter={requireAuth(store)}>
        <IndexRoute component={LandingRoute} />
        <Route path="subjects/:subjectId" component={SubjectDetailRoute} />
        <Route path="subjects/:subjectId/tests/:testId" exact component={SubjectTestDetailRoute} />
        <Route
          path="subjects/:subjectId/topics/:topicId"
          exact
          component={SubjectTopicDetailRoute}
        />
        <Route path="account-info" component={AccountInfoView} />
      </Route>
      <Route component={App} onEnter={requireAuth(store)}>
        {/* <Route path="select-topics" component={SelectTopicView} /> */}
        {/* <Route path="select-topics/:topicId" component={TopicInfoView} /> */}
        {/* <Route path="select-tests" component={SelectTestsView} /> */}
        {/* <Route path="select-tests/:testId" component={TestInfoView} /> */}
        {/* <Route path="custom-exercise" component={CustomExerciseView} /> */}
        {/* <Route path="comingSoon" component={ComingSoonView} /> */}
        {/* <Route path="profile" component={ProfileView} /> */}
      </Route>
      <Route component={PublicApp}>
        <Route path="login" component={LoginView} />
        <Route path="signup" component={SignupView} />
        <Route path="logout" component={LogoutView} />
        <Route path="activate-account" component={ActivateAccountView} />
        <Route path="forget-password" component={ForgetPasswordView} />
        <Route path="forget-password-success" component={ForgetPasswordSuccessView} />
        <Route path="reset-password" component={ResetPasswordView} />
        <Route path="*" component={NotFoundView} />
      </Route>
    </Route>
  );
}
