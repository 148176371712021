import { getRequest, postRequest } from '../common/util';

export function getTestResults() {
  return getRequest('/test-results/');
}

export async function getTestResultById(testId) {
  const testResults = await getRequest('/test-results/');
  const testResult = testResults ? testResults[testId] : null;
  return testResult;
}

// ...
export function getUserStat() {
  return getRequest('/user-stat/');
}

export function getUserQuestionStat() {
  return getRequest('/user-question-stat/');
}

export function aggregateStat() {
  return postRequest('/aggregate/', null);
}
