import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router'

import { COLOR } from '../../common/const'
import Flex from './styled/Flex'
import Text, { Title } from './styled/Text'
import Icon from './styled/Icon'

class Select extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: null
    }
  }

  componentWillMount() {
    this.setState({
      title: this.props.placeholder
    })
  }

  _onClick = item => {
    const onClick = item.onClick || this.props.onClick
    if (!onClick) {
      return () => { console.error("onClick is not ready.") }
    }

    return () => {
      this.setState({
        title: item.title
      })
      onClick(item.value)
    }
  }

  render() {
    const { className, IconClassName, items, title } = this.props
    const text = title ? <Title inherit>{ this.state.title }</Title> :
      <Text inherit>{ this.state.title }</Text>

      return <div className={ `${ className }` }>
        <SelectButton to="#" data-toggle="dropdown">
          <Icon inherit className={`hp-mr-05 ${ IconClassName }`}/>{ text }<Icon inherit className="fa fa-caret-down hp-ml-05"/>
        </SelectButton>
        <div className="select-menu" style={{ zIndex: "1010" }}>
          <SelectMenu col alignStart>
            { items.map((item, index) => (
              <Link 
                onClick={ this._onClick(item) } 
                className="select-item w-100"
                key={ index }
              >
                { item.title }
              </Link>
            ))}
          </SelectMenu>
        </div>
      </div>

  }
}

export default Select

Select.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array,
  onClick: PropTypes.func,
  className: PropTypes.string,
  IconClassName: PropTypes.string
}

const SelectMenu = styled(Flex)`
`

const SelectButton = styled.div`
    width: ${props => props.width || 'fit-content'};
    min-height: 48px;
    cursor: pointer;
    color: ${COLOR.GREY};
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
        color: ${COLOR.LIGHT_HIPPO};
    }

    &:active {
        color: ${COLOR.DARK_HIPPO};
    }

    &:focus {
        color: ${COLOR.HIPPO}
    }
`
