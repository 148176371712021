import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import _ from 'lodash';
import TextField from '@material-ui/core/TextField';

import apiClient from '../api';
import { setToken } from '../reducers/auth';
import { LOGIN_MODE } from '../common/const';
import MESSAGES from '../common/messages';
import { Card, Logo, Flex, AppContainer, RouterLink, Box, Button, Text } from '../components/base';

const InputSpacer = () => {
  return <Box pt={'24px'} />;
};

class LoginView extends Component {
  state = {
    pending: false,
    loginMode: LOGIN_MODE.EMAIL,
    email: '',
    password: '',
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ pending: true });
    const { email, password } = this.state;

    apiClient.auth.getToken({ email, password }, LOGIN_MODE.EMAIL).then((res) => {
      if (res.errorCode === 'invalid-email-or-password') {
        this.setState({ pending: false, error: MESSAGES.INVALID_EMAIL_OR_PASSWORD });
      } else if (res.errorCode) {
        this.setState({ pending: false, error: MESSAGES.INTERNAL_SERVER_ERROR });
      } else {
        this.props.setToken(res.token);
        _.delay(() => this.props.router.push('/'), 500);
      }
    });
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ error: null, [name]: value });
  };

  render() {
    return (
      <AppContainer>
        <Card maxWidth={'480px'} mx={'auto'}>
          <Flex flexDirection={'column'} alignItems={'center'}>
            <Logo variant={Logo.VARIANTS.SQUARE} width={'72px'} mb={4} />

            <Text.Title.Md mb={5}>ยินดีต้อนรับเข้าสู่ marchmellow</Text.Title.Md>
            <form
              className="form-horizontal"
              onSubmit={this.handleSubmit}
              style={{ width: '100%' }}
            >
              <Flex flexDirection={'column'} alignItems={'stretch'} width={'100%'} px={3}>
                {this.state.error && (
                  <Text.Paragraph
                    light={true}
                    color={'danger'}
                    width={'100%'}
                    textAlign={'center'}
                    height={'0px'}
                    style={{ transform: 'translateY(-16px)' }}
                    mb={2}
                  >
                    {this.state.error}
                  </Text.Paragraph>
                )}
                <InputSpacer />

                <TextField
                  name="email"
                  label="อีเมล"
                  type="email"
                  autoComplete="email"
                  required
                  variant={'outlined'}
                  onChange={this.handleChange}
                  value={this.state.email}
                />
                <InputSpacer />
                <TextField
                  name="password"
                  label="รหัสผ่าน"
                  type="password"
                  autoComplete="current-password"
                  required
                  variant={'outlined'}
                  onChange={this.handleChange}
                  value={this.state.password}
                />

                <Box mt={2} width={'fit-content'}>
                  <RouterLink to="/forget-password">
                    <Button.Primary.Text>ลืมรหัสผ่าน?</Button.Primary.Text>
                  </RouterLink>
                </Box>
                <Flex justifyContent={'center'} width={'100%'} my={4}>
                  <Button.Primary.Main
                    disabled={this.state.pending}
                    loading={this.state.pending}
                    width={'180px'}
                    type={'submit'}
                    forwardedAs={'button'}
                  >
                    เข้าสู่ระบบ
                  </Button.Primary.Main>
                </Flex>
                <Flex justifyContent={'center'} alignItems={'center'} width={'100%'}>
                  <Text.Title.Xs color={'greys.grey3'} mr={1}>
                    ไม่มีบัญชีผู้ใช้?
                  </Text.Title.Xs>
                  <RouterLink to="/signup">
                    <Button.Primary.Text>ลงทะเบียน</Button.Primary.Text>
                  </RouterLink>
                </Flex>
              </Flex>
            </form>
          </Flex>
        </Card>
      </AppContainer>
    );
  }
}

export default connect(null, { setToken })(LoginView);
