import React, { Component } from 'react'
import { connect } from 'react-redux'

import apiClient from '../api'
import PracticeInfoModal from '../components/practice/PracticeInfoModal'
import { SESSION_TYPE_EXERCISE, COLOR } from '../common/const'
import { setSessionInfo } from '../actions/solve'

import Text from '../components/base/styled/Text'
import Button from '../components/base/styled/Button'
import Flex from '../components/base/styled/Flex'
import Card from '../components/base/styled/Card'

class CustomExercise extends Component {
  state = {
    topics: [],
    selectedTopics: {},
    selectedDifficulties: {},
    questionCount: 10
  }

  componentWillMount() {
    this.props.setHeaders('สร้างแบบฝึกหัด')
    apiClient.topic.getTopicsTop()
      .then(res => {
        this.setState({ topics: res })
      })
  }

  handleDifficultyCheckboxChange = (difficultyId, checked)  => {
    const { selectedDifficulties } = this.state
    selectedDifficulties[difficultyId] = checked
    this.setState({
      selectedDifficulties
    })
  }

  handleTopicCheckboxChange = (topicId, checked)  => {
    const { selectedTopics } = this.state
    selectedTopics[topicId] = checked
    this.setState({
      selectedTopics
    })
  }

  handleCountChange = questionCount => {
    this.setState({ 
      questionCount 
    })
  }

  submit = () => {
    const selectedTopics = Object.entries(this.state.selectedTopics)
      .filter(([_, checked]) => checked)
      .map(([topicId, _]) => topicId)

    const selectedDifficulties = Object.entries(this.state.selectedDifficulties)
      .filter(([_, checked]) => checked)
      .map(([topicId, _]) => topicId)

    this.props.setSessionInfo({
        type: SESSION_TYPE_EXERCISE,
        title: "สร้างแบบฝึกหัด",
        topicId: selectedTopics,
        difficultyId: selectedDifficulties,
        questionCount: this.state.questionCount,
    })

    this.refs.infoModal.openModal()
  }

  render() {
    if (!this.props.difficulties) {
      return null
    }

    return <div className='row no-gutters justify-content-center animated fadeInRight '>
      <Card className="hp-p-15">
        <Flex col alignCenter>
          <Flex col alignStart className="hp-mb-15">
            <Text bold className="hp-mb-05">จำนวนข้อสอบที่ต้องการทำ</Text>
            <select 
              className="custom-select" 
              name="questionCount" 
              value={this.state.questionCount} 
              onChange={e => this.handleCountChange(e.target.value)} 
              style={{ width: "fit-content" }}
            >
              <option value={5}>5 ข้อ</option>
              <option value={10}>10 ข้อ</option>
              <option value={20}>20 ข้อ</option>
              <option value={40}>40 ข้อ</option>
            </select>
          </Flex>
          <Flex col className="hp-mb-1">
            <Text bold className="hp-mb-1">เลือกระดับที่ต้องการ 
              <span style={{color: COLOR.GREYSCALE.GREY3, fontWeight: 400}}> (เลือกได้มากกว่า 1 ระดับ)</span>
            </Text>
            <div className="row">
              {Object.values(this.props.difficulties).map(d =>
                <div key={d.id} className="col-sm-4" style={{ minHeight:"40px" }}>
                  <label className="custom-control custom-checkbox">
                    <input 
                      className="custom-control-input" 
                      type="checkbox" 
                      name={d.id} 
                      value={this.state.selectedDifficulties[d.id]} 
                      onChange={e => this.handleDifficultyCheckboxChange(d.id, e.target.checked)} 
                    />
                    <span className="custom-control-indicator"></span>
                    <Text className="custom-control-description">{d.version}</Text>
                  </label>
                </div>
              )}
            </div>
          </Flex>
          <Flex col className="hp-mb-2">
            <Text bold className="hp-mb-1">เลือกหัวข้อที่ต้องการ
              <span style={{color: COLOR.GREYSCALE.GREY3, fontWeight: 400}}> (เลือกได้มากกว่า 1 หัวข้อ)</span>
            </Text>
            <div className="row">
              {this.state.topics.map(t =>
                <div key={t.id} className="col-sm-4" style={{ minHeight:"40px" }}>
                  <label className="custom-control custom-checkbox">
                    <input 
                      className="custom-control-input" 
                      type="checkbox" 
                      name={t.id} 
                      value={this.state.selectedTopics[t.id]} 
                      onChange={e => this.handleTopicCheckboxChange(t.id, e.target.checked)} 
                    />
                    <span className="custom-control-indicator"></span>
                    <Text className="custom-control-description">{t.name}</Text>
                  </label>
                </div>
              )}
            </div>
          </Flex>
          <Button 
            disabled={
              !Object.values(this.state.selectedTopics).includes(true)
              || !Object.values(this.state.selectedDifficulties).includes(true)
            } 
            onClick={this.submit}
          >
            เรื่มทำแบบฝึกหัด
          </Button>
        </Flex>
      </Card>
      <PracticeInfoModal ref="infoModal" />
    </div>
  }
}

function mapStateToProps(state) {
  return {
    difficulties: state.topic.difficulties,
  }
}

export default connect(mapStateToProps, { setSessionInfo })(CustomExercise)
