import { getRequest, postRequest, getTokenInfo } from '../common/util';

export function createStudent(email, password, firstname, lastname, userType, grade) {
  const data = {
    email,
    password,
    firstname,
    lastname,
    user_type: userType,
    grade,
  };
  return postRequest('/post-student/', data);
}

export function getStudentInfo() {
  const studentId = getTokenInfo().user_id;
  return getRequest(`/students/${studentId}/`);
}

// TODO: Fix this to be the real POST. We need to work on the permission on server.
export function postFirstLogin() {
  const studentId = getTokenInfo().user_id;
  return getRequest(`/students/${studentId}/first_login/`);
}

export function activatePremium(code) {
  return postRequest(`/action/activate-premium/${code}/`);
}

// ... OLD ...
export function activateAccount(code) {
  return getRequest(`/activate-student/`, { code: code });
}

export function requestResetPassword(email) {
  const data = { email };
  return postRequest('/request-reset-password/', data);
}

export function checkResetPasswordCode(code) {
  const data = { code };
  return postRequest('/check-reset-password-code/', data);
}

export function resetPassword(password, code) {
  const data = { password, code };
  return postRequest('/reset-password/', data);
}
