import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Flex from '../../../components/base/styled/Flex'
import ProblemBubbleList, { ToggleableProblemBubbleList } from '../../../components/practice/ProblemBubbleList'

class SessionSidebarExercise extends Component {
    render() {
        const { index, problems, setProblemIndex } = this.props
        return (
            <_Flex col>
                <div className="hidden-md-down">
                    <ProblemBubbleList 
                        index={ index }
                        problems={ problems }
                        setProblemIndex={ setProblemIndex }
                    />
                </div>
                <div className="hidden-lg-up">
                    <ToggleableProblemBubbleList 
                        index={ index }
                        problems={ problems }
                        setProblemIndex={ setProblemIndex }
                    />
                </div>
            </_Flex>
        )
    }
}

SessionSidebarExercise.propTypes = {
    index: PropTypes.number,
    problems: PropTypes.array,
    setProblemIndex: PropTypes.func,
    finishSession: PropTypes.func,
    solutionMode: PropTypes.bool
}

export default SessionSidebarExercise

const _Flex = styled(Flex)`
    @media (max-width: 991px) {
        padding: 0.5rem 0rem;
        margin: 0;
        width: 100%;
        flex-basis: auto;
        border-radius: 0 0 0 0;
        flex-basis: auto;
    }
    padding: 0rem 0 0rem 0;
`