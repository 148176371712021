import styled from 'styled-components';

import Box from './Box';

const Table = styled.table`
  width: 100%;
`;

const TableHead = styled.thead`
  border-bottom: 1px solid black;
`;

const TableHeadCell = styled(Box)`
  padding: 8px 0;
`;
TableHeadCell.defaultProps = {
  as: 'th',
};

const TableBody = styled.tbody``;

const TableRow = styled.tr``;

const TableCell = styled(Box)`
  padding: 8px 0;
`;
TableCell.defaultProps = {
  as: 'td',
};

Table.Head = TableHead;
Table.HeadCell = TableHeadCell;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Cell = TableCell;

export default Table;
