import React, { Component } from 'react';
import styled from 'styled-components';

import { Modal, Flex, Text, Button, Image, RouterLink } from './base';
import { AnalyticContext } from '../contexts/AnalyticContext';

class FirstTimeModal extends Component {
  postFirstLoginAndCloseModal = () => {
    this.props.postFirstLogin();
    this.props.onClose();
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.postFirstLoginAndCloseModal}>
        <Flex flexDirection={'column'} alignItems={'center'}>
          {/* <ModalTitle title="" closeButton={this.postFirstLoginAndCloseModal} /> */}
          <Text.Title.Md mb={3}>ยินดีต้อนรับเข้าสู่</Text.Title.Md>
          <Image src={'/img/mm-logo.png'} height={'60px'} mb={4} />
          <Text mb={3} style={{ fontSize: '20px' }} fontWeight={300} textAlign={'center'}>
            เริ่มฝึกฝนทำโจทย์ทันที จากคลังข้อสอบของเราทั้งหมดกว่า 500 ข้อ
            และดูเฉลยวิดีโออย่างละเอียดของชุดข้อสอบย้อนหลังฟรี 1 ปี
          </Text>

          <Text style={{ fontSize: '20px' }} fontWeight={300} textAlign={'center'} mb={4}>
            หากคุณต้องการดูเฉลยวิดีโอในข้อสอบชุดอื่นๆ กด{' '}
            <Text.Span display={'inline'} fontWeight={500}>
              "เลือกดูแพ็คเกจ"
            </Text.Span>{' '}
            เพื่อเลือกดูและซื้อแพ็คเกจสำหรับดูเฉลยวิดีโอที่ต้องการ
          </Text>

          <Text
            style={{ fontSize: '18px' }}
            fontWeight={300}
            mb={3}
            py={3}
            px={4}
            borderRadius={'16px'}
            bg={'greys.grey5'}
          >
            หากคุณมีโค้ดสำหรับซื้อแพ็คเกจอยู่แล้ว สามารถนำโค้ดไปปลดล็อคแพ็คเกจได้{' '}
            <RouterLink to={'/account-info'}>
              <Button.Primary.Text
                display={'inline-block'}
                style={{ fontSize: '18px' }}
                onClick={() => {
                  this.props.logAction({
                    category: 'Package',
                    action: 'Route to Package Page',
                    label: `FirstTimeModal - 1`,
                  });
                  this.postFirstLoginAndCloseModal;
                }}
              >
                ที่นี่
              </Button.Primary.Text>
            </RouterLink>
          </Text>

          <Flex>
            <RouterLink to={'/account-info'}>
              <Button.Primary.Main
                mr={2}
                onClick={() => {
                  this.props.logAction({
                    category: 'Package',
                    action: 'Route to Package Page',
                    label: `FirstTimeModal - 2`,
                  });
                  this.postFirstLoginAndCloseModal;
                }}
              >
                เลือกดูแพ็คเกจ
              </Button.Primary.Main>
            </RouterLink>
            <Button.Secondary.Default onClick={this.postFirstLoginAndCloseModal}>
              ปิด
            </Button.Secondary.Default>
          </Flex>
        </Flex>
      </Modal>
    );
  }
}

const FirstTimeModalWrapper = ({ ...props }) => {
  const { logAction } = React.useContext(AnalyticContext);
  return <FirstTimeModal logAction={logAction} {...props} />;
};

export default FirstTimeModalWrapper;
