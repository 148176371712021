import React from 'react';
import { connect } from 'react-redux';

import HorizontalNavBar from './HorizontalNavBar';
import DrawerNavBar from './DrawerNavBar';

const NavBar = ({ userInfo }) => {
  const { firstname } = userInfo || {};
  return (
    <>
      <HorizontalNavBar
        display={['none', 'block']}
        userInfo={{ firstName: firstname, ...userInfo }} // This is a workaround to keep using camelCase in frontend while the API is using snakecase without modifiying exisiting userInfo code.
      />
      <DrawerNavBar display={['block', 'none']} userInfo={{ firstName: firstname, ...userInfo }} />
    </>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.user.userInfo,
  };
}

export default connect(mapStateToProps, null)(NavBar);
