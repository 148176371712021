import axios from 'axios'
import cookie from 'react-cookie'
import { store } from '../index'
import { BASE_API_URL } from '../common/const';

function getJwt(fromCookie=false) {
  if (fromCookie) {
    return cookie.load('token')
  } else {
    return store.getState().auth.jwt
  }
}

export function getRequest(urlPath, params={}, ignoreJwt=false) {
  if (!ignoreJwt) {
    var jwt = `Bearer ${getJwt()}`
    return axios.get(`${ BASE_API_URL }/${ urlPath }`, { headers: { 'Authorization': jwt }, params: params }).catch(function (error) {
      console.error(error.response);
      throw error;
    });
  } else {
    return axios.get(`${ BASE_API_URL }/${ urlPath }`).catch(function (error) {
      console.error(error.response);
      throw error;
    });
  }
}

export function postRequest(urlPath, data, ignoreJwt=false) {
  if (!ignoreJwt) {
    var jwt = `Bearer ${getJwt()}`
    return axios.post(`${ BASE_API_URL }/${ urlPath }`, data, { headers: { 'Authorization': jwt }}).catch(function (error) {
      console.error(error.response);
      throw error;
    });
  } else {
    return axios.post(`${ BASE_API_URL }/${ urlPath }`, data).catch(function (error) {
      console.error(error.response);
      throw error;
    });
  }
}
