import React from 'react'

import Card from '../components/base/styled/Card'
import Text, { Title } from '../components/base/styled/Text'
import Flex from '../components/base/styled/Flex'

const NotFoundView = () => 
    <div className="animated fadeInRight">
        <Flex center>
            <Card className="hp-p-3" style={{ maxWidth:"100vw", minHeight:"100%" }}> 
                <Flex col alignCenter>
                    <img width="240px" src="/img/hippoGraphic.svg" />
                    <Title className="hp-mt-2">404! ไม่พบหน้าที่ต้องการ</Title>
                    <Text>ไม่พบหน้าที่คุณกำลังหาอยู่ กรุณาตรวจสอบลิงก์อีกครั้ง</Text>
                    <a href="\" className="hp-mt-2"> กลับหน้าแรก ></a>
                </Flex>
            </Card>
        </Flex>
    </div>

export default NotFoundView
