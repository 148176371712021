import styled from 'styled-components';

import Flex from '../../../../base/styled/Flex';

const ChoiceBase = styled(Flex)`
  padding: 0.75rem 1.5rem;

  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s;
  width: 100%;
`;
export default ChoiceBase;
