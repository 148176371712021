import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { COLOR } from '../../../../common/const'
import TextBase from './TextBase'

const Text = styled(TextBase)`
    font-size: ${props => props.small ? '0.75rem' : '1rem'};
    font-weight: ${props => props.bold ? '500' : '300'};
`

export default Text

Text.propTypes = {
    small: PropTypes.bool, // Text with font-size=0.75rem
    bold: PropTypes.bool,
}
