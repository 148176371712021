/**
 * 0: 0px;
 * 1: 4px;
 * 2: 8px;
 * 3: 16px;
 * 5: 32px;
 * 7: 64px;
 * 8: 128px;
 * 9: 256px;
 * 10: 512px
 */

const spaces = [0, 4, 8, 16, 32, 48, 64, 128, 256, 512];

export default spaces;
