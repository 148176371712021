import { getRequest, postRequest } from '../common/util'
import { LOGIN_MODE } from '../common/const'

export function refreshToken(data) {
  return postRequest('/action/refresh-token/', data)
}

export function getToken(data, mode) {
  const loginData = {
    ...data,
    mode: mode || LOGIN_MODE.EMAIL
  }

  return postRequest('/action/get-token/', loginData, true)
}

export function getStudentInfo(studentId) {
  return getRequest(`/students/${studentId}/`)
}
