import { getRequest, postRequest } from '../common/util';

export async function getAllTags() {
  const tags = await getRequest('/tags/');
  const formattedTags = tags.map((tag) => {
    const { text, id, index } = tag;
    return { name: text, id, index };
  });
  return formattedTags;
}

export async function getTagInfoById(tagId) {
  const tagInfo = await getRequest(`/tags/${tagId}/`);
  return tagInfo;
}
// Used for exisiting api in reducer's getAllTags
// TODO: Remove this.
export function getAllTagsV0() {
  return getRequest(`/tags/`);
}
