
export const circle = (color) => {
    return {
        position: 'relative',
        color: color,
        fontSize: '1.25rem',
    }
}

export const bg = (index) => {
    return {
        padding: '0.2rem 0',
        textAlign: 'center',
        height: "1.75rem",
        backgroundColor: 
            (index % 2 === 0) ? 'rgba(0,0,0,0.05)' : '',
    }
}