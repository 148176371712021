import React from 'react';
import PropTypes from 'prop-types';

import { COLOR } from '../../../../common/const';
import MainButton from './MainButton';
import OutlineButton from './OutlineButton';
import FlatButton from './FlatButton';
import ProblemButton from './ProblemButton';
import FeedbackButton from './FeedbackButton';
import Link from './Link';
import ButtonBase from './ButtonBase';

const Button = ({ main, outline, flat, feedback, ...others }) => {
  if (main) {
    return <MainButton {...others} />;
  }

  if (outline) {
    return <OutlineButton {...others} />;
  }

  if (flat) {
    return <FlatButton {...others} />;
  }

  if (feedback) {
    return <FeedbackButton {...others} />;
  }

  return <MainButton {...others} />;
};

Button.propTypes = {
  main: PropTypes.bool,
  outline: PropTypes.bool,
  flat: PropTypes.bool,
  feedback: PropTypes.bool,
};

export default Button;
export { MainButton, OutlineButton, FlatButton, Link, ProblemButton, FeedbackButton, ButtonBase };
