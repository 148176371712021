import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AppContainer,
  Button,
  Card,
  Text,
  Loading,
  Table,
  Icon,
  RouterLink,
} from '../../components/base';
import { COLOR } from '../../common/const';
import SingleProblemModal from '../../components/problem/SingleProblemModal';
import { AnalyticContext } from '../../contexts/AnalyticContext';

const BackLink = styled(Button.Secondary.Text)`
  font-size: 16px;
  font-weight: 400;
`;
const SubjectTopicDetailView = ({
  loading,
  topicInfo,
  questions,
  onSelectQuestion,
  subjectInfo,
  onBookmark,
}) => {
  const [selectedQuestion, setSelectedQuestion] = React.useState(null);
  const [singleProblemModalOpen, setSingleProblemModalOpen] = React.useState(false);
  const { name: topicName } = topicInfo || {};
  const { text: subjectName, id: subjectId } = subjectInfo || {};

  const { logAction } = React.useContext(AnalyticContext);

  const handleSelectQuestion = (questionId, questionIndex, test) => {
    const selectedQuestion = {
      questionId,
      questionIndex,
      testName: test,
    };
    logAction({
      category: 'Single Question',
      action: 'Open Modal',
      label: `Topic: ${topicName}`,
    });
    setSelectedQuestion(selectedQuestion);
    setSingleProblemModalOpen(true);
  };

  const handleCloseSingleProblemModal = () => {
    logAction({
      category: 'Single Question',
      action: 'Close Modal',
      label: `Topic: ${topicName}`,
    });
    setSingleProblemModalOpen(false);
  };

  return (
    <AppContainer>
      {loading ? (
        <Loading />
      ) : (
        <>
          <RouterLink to={`/subjects/${subjectId}`}>
            <BackLink>{subjectName} /</BackLink>
          </RouterLink>
          <Text.Title.Lg mt={2} mb={4}>
            {topicName}
          </Text.Title.Lg>
          <Card>
            <Text.Title.Md mb={4}>โจทย์ทั้งหมด</Text.Title.Md>
            <Table>
              <Table.Head>
                <Table.Row>
                  <Table.HeadCell display={['none', 'table-cell']}></Table.HeadCell>
                  <Table.HeadCell>
                    <Text.Title.Xs>ชื่อโจทย์</Text.Title.Xs>
                  </Table.HeadCell>

                  <Table.HeadCell></Table.HeadCell>
                </Table.Row>
              </Table.Head>

              <Table.Body>
                {questions.map(({ test, id, bookmarked, index, ...rest }) => {
                  return (
                    <Table.Row key={id}>
                      <Table.Cell display={['none', 'table-cell']} center={true}>
                        <Icon
                          name={Icon.ICON_LIST.BOOKMARK}
                          color={bookmarked ? COLOR.ORANGE : COLOR.GREYSCALE.GREY2}
                          fontSize={'20px'}
                        />
                      </Table.Cell>

                      <Table.Cell pr={3}>
                        <Text.Paragraph light={true}>
                          {test} ข้อ {index}
                        </Text.Paragraph>
                      </Table.Cell>
                      <Table.Cell>
                        <Button.Secondary.Default
                          dense={true}
                          onClick={() => handleSelectQuestion(id, index, test)}
                          style={{ float: 'right' }}
                        >
                          ทำโจทย์ข้อนี้
                        </Button.Secondary.Default>
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Card>
        </>
      )}
      <SingleProblemModal
        open={singleProblemModalOpen}
        onClose={handleCloseSingleProblemModal}
        questionId={selectedQuestion && selectedQuestion.questionId}
        questionIndex={selectedQuestion && selectedQuestion.questionIndex}
        testName={selectedQuestion && selectedQuestion.testName}
        onBookmark={onBookmark}
      />
    </AppContainer>
  );
};

SubjectTopicDetailView.defaultProps = {
  questions: [],
  testInfo: {},
};

export default SubjectTopicDetailView;
