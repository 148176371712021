import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { fromHHMMSS, toHHMMSS, fromSecondsToMMSS } from '../../common/util';

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      elapsedSeconds: 0,
      duration: 0,
    };
  }

  componentDidMount() {
    const { duration, solutionMode } = this.props;
    let d = fromHHMMSS(duration);
    d = d == 0 ? 60 * 60 * 2 : d;
    this.setState({ duration: d }, () => {
      if (!solutionMode) {
        this.startTimer();
      }
    });
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    var interval = setInterval(this.updateTimer.bind(this), 1000);
    this.setState({
      elapsedSeconds: 0,
      interval: interval,
    });
  }

  getElapsedTime = () => {
    return this.state.elapsedSeconds;
  };

  stopTimer() {
    clearInterval(this.state.interval);
  }

  updateTimer() {
    const elapsedSeconds = this.state.elapsedSeconds + 1;
    this.setState({ elapsedSeconds });

    if (this.state.duration - elapsedSeconds < this.props.warningTime) {
      this.props.onWarning();
    }
    if (elapsedSeconds >= this.state.duration) {
      this.props.onTimeUp();
      this.stopTimer();
    }
  }

  render() {
    const { elapsedSeconds, duration } = this.state;
    return (
      <div style={this.props.style} className={this.props.className}>
        {fromSecondsToMMSS(duration - elapsedSeconds)}
      </div>
    );
  }
}

Timer.propTypes = {
  duration: PropTypes.string,
  solutionMode: PropTypes.bool,
  onTimeUp: PropTypes.func,
  onWarning: PropTypes.func,
  warningTime: PropTypes.number,
};

Timer.defaultProps = {
  onTimerUp: () => {},
  onWarning: () => {},
  warningTime: 300,
};
