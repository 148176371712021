import React from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import styled from 'styled-components';
import dayjs from 'dayjs';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import { SESSION_TYPE_TEST } from '../../../common/const';
import { Card, Flex, Box, Text, Button, Loading } from '../../../components/base';
import { setSessionInfo, startSolutionSession } from '../../../actions/solve';
import apiClient from '../../../api';
import { AnalyticContext } from '../../../contexts/AnalyticContext';

const Divider = styled.hr`
  margin: 16px 0;
`;

const ActivityCard = ({ name, date, onClick, score }) => {
  return (
    <Flex alignItems={'center'} justifyContent={'space-between'}>
      <Box pr={3}>
        <Text.Paragraph mb={1} color={'greys.gery3'}>
          {date}
        </Text.Paragraph>
        <Text.Title.Sm mb={1} fontWeight={500}>
          {name}
        </Text.Title.Sm>
        <Text.Paragraph light={true}>คะแนนที่ได้: {score}</Text.Paragraph>
      </Box>
      <Button.Primary.Default dense={true} onClick={onClick}>
        ทบทวน
      </Button.Primary.Default>
    </Flex>
  );
};

const RecentActivities = ({ startSolutionSession, setSessionInfo }) => {
  const [sessions, setSessions] = React.useState(null);
  const { logAction } = React.useContext(AnalyticContext);

  React.useEffect(() => {
    const getData = async () => {
      const sessions = await apiClient.session.getSessions();
      const sortedSessions = _reverse(_sortBy(sessions, 'date'));
      setSessions(sortedSessions);
    };
    getData();
  }, []);

  const handleClickActivityCard = async (sessionId, timeSpent) => {
    logAction({
      category: 'Recent Activity',
      action: 'Select',
      label: `Click review recent activity from Landing page`,
    });
    setSessionInfo({
      type: SESSION_TYPE_TEST,
      duration: timeSpent,
    });

    browserHistory.push('/session/review');
    const session = await apiClient.practice.getSession(sessionId);
    startSolutionSession(session.id, session.title, session.questions);
  };

  return (
    <Card style={{ flexGrow: 1 }}>
      {!sessions ? (
        <Loading />
      ) : sessions.length <= 0 ? (
        <Text.Subtitle.Md color={'textLight'} textAlign={'center'}>
          ยังไม่มีประวัติการทำข้อสอบ
        </Text.Subtitle.Md>
      ) : (
        sessions.map(({ name, date, id, score, fullScore, timeSpent }) => {
          const formattedDate = dayjs(date).format('DD MMM YYYY');
          return (
            <Box key={id}>
              <ActivityCard
                name={name}
                date={formattedDate}
                reportId={id}
                score={`${score}/${fullScore}`}
                onClick={() => handleClickActivityCard(id, timeSpent)}
              />
              <Divider />
            </Box>
          );
        })
      )}
    </Card>
  );
};

function mapStateToProps(state) {
  return {
    questionStat: state.stat.userQuestionStat,
  };
}

export default connect(mapStateToProps, { startSolutionSession, setSessionInfo })(RecentActivities);
