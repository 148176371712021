import React, { Component } from 'react'
import SidebarListItemBase from './SidebarListItemBase'
import Text from '../../styled/Text'
import Flex from '../../styled/Flex'

export default class SidebarListItem extends Component {
    render() {
        const { title, value, unit } = this.props
        return ( 
            <SidebarListItemBase between alignCenter>
                <Text className="hp-mr-05">{ title }</Text>
                <Flex widthAuto>
                    <Text hippo>{ value }</Text>
                    <Text className="hp-ml-05">{ unit }</Text>
                </Flex>
            </SidebarListItemBase>
        )
    }
}