import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Text, MathJaxBox } from '../../../base';

const ProblemText = styled(Text)`
  font-size: 16px;
  font-weight: 400;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints[0]}) {
    font-size: 18px;
  }

  img {
    max-width: 100%;
  }
`;
ProblemText.defaultProps = {
  readable: true,
};

const ProblemBox = ({ content, ...restProps }) => {
  return (
    <ProblemText width={'100%'} {...restProps}>
      <MathJaxBox content={content} />
    </ProblemText>
  );
};

ProblemBox.propTypes = {
  content: PropTypes.string,
};

export default ProblemBox;
