import React from 'react';
import styled from 'styled-components';
import { space, color } from 'styled-system';
import Box from './Box';
import {
  MdCheckCircle,
  MdAccessTime,
  MdAccountBox,
  MdBookmark,
  MdRemoveRedEye,
  MdKeyboardArrowRight,
  MdCopyright,
  MdMenu,
  MdClose,
  MdWarning,
  MdAccountCircle,
  MdAddToPhotos,
  MdArrowBack,
  MdExitToApp,
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdFace,
  MdFeedback,
  MdFileDownload,
  MdPlayCircleFilled,
  MdFormatListBulleted,
  MdPhone,
  MdUnfoldMore,
  MdReplay10,
  MdForward10,
  MdVolumeUp,
  MdInfoOutline,
  MdAttachment,
  MdRadioButtonUnchecked,
  MdRadioButtonChecked,
  MdAdd,
  MdInfo,
  MdInsertChart,
  MdEdit,
} from 'react-icons/md';
import {
  FaFacebook,
  FaFacebookMessenger,
  FaMediumM,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
  FaQuestionCircle,
} from 'react-icons/fa';
import { IoMdCheckmark, IoMdBulb } from 'react-icons/io';
import { IoLogOutOutline } from 'react-icons/io5';

import { RiFile4Fill } from 'react-icons/ri';

const ICON_LIST = {
  ADD: MdAdd,
  ACCOUNT_CIRCLE: MdAccountCircle,
  ACCOUNT_BOX: MdAccountBox,
  ADD_TO_PHOTO: MdAddToPhotos,
  ARROW_LEFT: MdArrowBack,
  ARROW_RIGHT: MdKeyboardArrowRight,
  ARROW_DOWN: MdKeyboardArrowDown,
  ARROW_UP: MdKeyboardArrowUp,
  BOOKMARK: MdBookmark,
  CHECK: IoMdCheckmark,
  CHECK_CIRCLE: MdCheckCircle,
  CLOCK_CIRCLE: MdAccessTime,
  CLOSE: MdClose,
  COPYRIGHT: MdCopyright,
  DOWNLOAD: MdFileDownload,
  EYE: MdRemoveRedEye,
  FACE: MdFace,
  FEEDBACK: MdFeedback,
  LOG_OUT: IoLogOutOutline,
  LIST: MdFormatListBulleted,
  MENU_EXPAND_MORE: MdKeyboardArrowDown,
  MENU_EXPAND_LESS: MdKeyboardArrowUp,
  MENU_BURGER: MdMenu,
  WARNING: MdWarning,
  PHONE: MdPhone,
  SORT_UP: MdUnfoldMore,
  SORT_DOWN: MdUnfoldMore,
  STAT: MdInsertChart,
  INFO_OUTLINE: MdInfoOutline,
  INFO: MdInfo,
  ATTACHMENT: MdAttachment,
  QUESTION_CIRCLE: FaQuestionCircle,
  RADIO: MdRadioButtonUnchecked,
  RADIO_SELECTED: MdRadioButtonChecked,

  // Test
  QUESTION_COUNT: MdFormatListBulleted,
  TEST_DURATION: MdAccessTime,
  SELECT_TEST: RiFile4Fill,
  DO_PROBLEM: MdEdit,
  SEE_SOLUTION: IoMdBulb,

  // Video
  VIDEO_PLAY_CIRCLE: MdPlayCircleFilled,
  VOLUME: MdVolumeUp,
  REPLAY: MdReplay10,
  FORWARD: MdForward10,

  // Social
  FACEBOOK: FaFacebook,
  FACEBOOK_SQ: FaFacebook,
  MEDIUM: FaMediumM,
  MESSENGER: FaFacebookMessenger,
  INSTAGRAM: FaInstagram,
  TWITTER: FaTwitter,
  LINKEDIN: FaLinkedin,
};

const Icon = ({ name, color, ...props }) => {
  const IconComponent = name;
  if (!IconComponent) return null;
  return <IconComponent fill={color} {...props} />;
};

Icon.defaultProps = {
  color: 'currentColor',
};
const StyledIcon = styled(Icon)`
  ${space};
  ${color};
  display: block;
`;

StyledIcon.ICON_LIST = ICON_LIST;

export { ICON_LIST };
export default StyledIcon;
