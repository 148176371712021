import React from 'react';
import styled from 'styled-components';

import { Text, Card, Box, Loading } from '../../../components/base';
import apiClient from '../../../api';
import { getFormattedPercentage } from '../../../common/util';

const ListItemLayout = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LargeValueText = styled(Text)`
  font-size: 48px;
  font-weight: 500;
`;

const ProblemsStatLayout = styled(Card)`
  display: grid;
  grid-row-gap: 24px;
`;

const ListItem = ({ field, value }) => {
  return (
    <ListItemLayout>
      <Text.Paragraph.Lg>{field}</Text.Paragraph.Lg>
      <Text.Paragraph.Lg>{value}</Text.Paragraph.Lg>
    </ListItemLayout>
  );
};

const ProblemsStat = ({}) => {
  const [stats, setStats] = React.useState(null);
  const [correctPercentage, setCorrectPercentage] = React.useState(null);
  React.useEffect(() => {
    const getStats = async () => {
      const stats = await apiClient.stat.getUserStat();
      const correctPercentage = getFormattedPercentage(stats.total_correct / stats.count_response);
      setCorrectPercentage(correctPercentage);
      setStats(stats);
    };
    getStats();
  }, []);

  return !stats ? (
    <Loading />
  ) : (
    <ProblemsStatLayout>
      <Box>
        <Text.Paragraph.Lg>เปอร์เซ็นต์โจทย์ที่ทำถูก</Text.Paragraph.Lg>
        <LargeValueText>
          {parseInt(correctPercentage) >= 0 ? `${correctPercentage}%` : '-'}
        </LargeValueText>
      </Box>
      <ListItem field={'ทำโจทย์แนวข้อสอบทั้งชุดไปแล้ว'} value={`${stats.count_session} ชุด`} />
      <ListItem field={'ทำโจทย์ไปแล้วทั้งหมด'} value={`${stats.count_response} ข้อ`} />
      <ListItem field={'จำนวนโจทย์ที่ทำถูก'} value={`${stats.total_correct} ข้อ`} />
    </ProblemsStatLayout>
  );
};

export default ProblemsStat;
