import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';

import { Flex, Box, Text, RouterLink, Icon } from '../base';

import NavBarWrapper from './components/NavBarWrapper';
import NavBarContainer from './components/NavBarContainer';
import NavLogo from './components/NavLogo';

const MenuList = styled(Flex)`
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 4px;
  }
`;

const MenuItemContainer = styled(Text.Paragraph)`
  padding: 6px 12px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.primaries.main};
  }
  &:active {
    color: ${({ theme }) => theme.colors.primaries.dark};
  }
`;

const MenuItem = ({ children, ...restProps }) => {
  return (
    <RouterLink {...restProps}>
      <MenuItemContainer>{children}</MenuItemContainer>
    </RouterLink>
  );
};

const UserButtonContainer = styled(Flex)`
  background-color: #ddf4f4;
  color: black;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  cursor: pointer;

  /* Same as Button */
  &:hover {
    background-color: ${darken(0.05, '#ddf4f4')};
  }

  &:active {
    background-color: ${darken(0.1, '#ddf4f4')};
  }
`;

const UserMenuListContainer = styled(Box)`
  background-color: white;
  border-radius: 16px;
  width: 200px;
  box-shadow: 2px 2px 8px 2px rgba(0, 0, 0, 0.15);
`;

const UserMenuList = styled(Box)`
  & > *:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.greys.grey4};
  }

  &:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const UserMenuListItem = styled(RouterLink)`
  padding: 20px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const UserMenu = ({ open, anchorEl, onClose }) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorEl}
      transition
      placement={'bottom-end'}
      style={{ zIndex: 1100 }}
    >
      {({ TransitionProps, placement }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
          <UserMenuListContainer>
            <ClickAwayListener onClickAway={onClose}>
              <UserMenuList>
                <UserMenuListItem onClick={onClose} to={'/account-info'}>
                  <Icon name={Icon.ICON_LIST.ACCOUNT_BOX} size={24} mr={2} color={'black'} />
                  <Text.Paragraph>ข้อมูลผู้ใช้</Text.Paragraph>
                </UserMenuListItem>
                <UserMenuListItem onClick={onClose} to={'/logout'}>
                  <Icon name={Icon.ICON_LIST.LOG_OUT} size={24} mr={2} color={'black'} />
                  <Text.Paragraph>ออกจากระบบ</Text.Paragraph>
                </UserMenuListItem>
              </UserMenuList>
            </ClickAwayListener>
          </UserMenuListContainer>
        </Grow>
      )}
    </Popper>
  );
};

const UserButton = ({ displayName, ...restProps }) => {
  const [open, setOpen] = React.useState(false);
  const anchorElRef = React.useRef(null);
  const handleClick = (e) => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <UserButtonContainer onClick={handleClick} ref={anchorElRef} {...restProps}>
        <Icon name={Icon.ICON_LIST.FACE} size={24} mr={'6px'} />
        <Text.Title.Xs mr={2} mb={'2px'} style={{ textTransform: 'uppercase' }}>
          {displayName}
        </Text.Title.Xs>
        <Icon name={Icon.ICON_LIST.ARROW_DOWN} size={18} mr={1} />
      </UserButtonContainer>
      <UserMenu anchorEl={anchorElRef.current} open={open} onClose={handleClose} />
    </>
  );
};

const HorizontalNavBar = ({ display, userInfo }) => {
  const { firstName } = userInfo || {};
  return (
    <NavBarWrapper display={display}>
      <NavBarContainer>
        <NavLogo />
        <MenuList>
          <MenuItem to={'/'}>
            <Icon name={Icon.ICON_LIST.SELECT_TEST} mr={1} size={20} />
            เลือกทำโจทย์
          </MenuItem>
          {/* <MenuItem to={'/profile'}>
            <Icon name={Icon.ICON_LIST.STAT} mr={1} size={24} />
            สถิติ
          </MenuItem> */}
          <UserButton ml={3} displayName={firstName} />
        </MenuList>
      </NavBarContainer>
    </NavBarWrapper>
  );
};

export default HorizontalNavBar;
