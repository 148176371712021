import React from 'react'
import styled from 'styled-components'

import { COLOR } from '../../../../common/const'
import ButtonBase from './ButtonBase'

const MainButton = styled(ButtonBase)`
    color: ${ COLOR.HIPPO };
    background-color: transparent;
    border-color: ${ COLOR.HIPPO };

    &:hover {
        background-color: ${ COLOR.LIGHT_HIPPO };
        border-color: ${ COLOR.LIGHT_HIPPO };
        color: ${ COLOR.WHITE };
    }

    &:active {
        background-color: ${ COLOR.DARK_HIPPO };
        border-color: ${ COLOR.DARK_HIPPO };
    }
`

export default MainButton