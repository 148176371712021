import React, { Component } from 'react'
import { connect } from 'react-redux'

import apiClient from '../../api'
import { updateQuestionsInfo } from '../../common/util'
import Card, { CardHeader } from '../../components/base/styled/Card'
import { ProblemTable } from '../../components/tables'

class WrongProblems extends Component {
  state = {
    wrongQuestions: null,
  }

  getWrongQuestions = () => {
    if (!this.state.wrongQuestions) {
      apiClient.practice.getWrongQuestions()
        .then(questions => {
          const wrongQuestions = questions.map(this._handleTopic)
          this.setState({ wrongQuestions })
        })
    }

    updateQuestionsInfo(this.state.wrongQuestions, this.props.questionStat, this.props.bookmarks)
    return this.state.wrongQuestions
  }

  _handleTopic = q => {
    const topic = q.category.filter(c => c.level === 1)
    q.topic = topic.length > 0 ? topic[0].name : 'n/a'
    return q
  }

  render() {
    return <Card>
      <div style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
        <CardHeader 
          className="mb-0 mb-sm-4" 
          title="โจทย์ที่เคยทำผิด"
        />
      </div>
      <ProblemTable problems={this.getWrongQuestions() || []} topicLevel={1} itemsPerPage={10} />
    </Card>
  }
}

function mapStateToProps(state) {
  return {
    questionStat: state.stat.userQuestionStat,
    bookmarks: state.bookmark.bookmarkIds,
  }
}

export default connect(mapStateToProps, null)(WrongProblems)
