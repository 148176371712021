export const GET_TESTS = 'GET_TESTS'

export const GET_STUDENT = 'GET_STUDENT'

export const GET_EXERCISE_RESULTS = 'GET_EXERCISE_RESULTS'
export const GET_KNOWLEDGE = 'GET_KNOWLEDGE'

export const GET_PRACTICE_INFO_KNOWLEDGES = 'GET_PRACTICE_INFO_KNOWLEDGES'
export const GET_PRACTICE_INFO_TOPICS = 'GET_PRACTICE_INFO_TOPICS'
export const GET_TEST_PROGRESS = 'GET_TEST_PROGRESS'
// Solve actions
export const GO_TO_SOLUTION = 'GO_TO_SOLUTION'
export const START_SESSION = 'START_SESSION'
export const START_SOLUTION_SESSION = 'START_SOLUTION_SESSION'
export const KILL_SESSION = 'KILL_SESSION'
export const GET_PROBLEM = 'GET_PROBLEM'
export const SET_PROBLEM_INDEX = 'SET_PROBLEM_INDEX'
export const SUBMIT_RESPONSE = 'SUBMIT_RESPONSE'
export const SELECT_CHOICE = 'SELECT_CHOICE'
export const SUBMIT_TEST = 'SUBMIT_TEST'
export const POST_SESSION_RESULT = 'POST_SESSION_RESULT'
export const SET_SESSION_INFO = 'SET_SESSION_INFO'
export const RANDOM_TEST_ANSWERS = 'RANDOM_TEST_ANSWERS'

// Stat actions
export const GET_ALL_SKILLS = 'GET_ALL_SKILLS'
export const GET_SIDEBAR_STAT = 'GET_SIDEBAR_STAT'
export const GET_STAT = 'GET_STAT'
export const AGGREGATE_STAT = 'AGGREGATE_STAT'

// User actions
export const CREATE_USER = 'CREATE_USER'
export const DECODE_TOKEN = 'DECODE_TOKEN' 
