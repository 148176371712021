import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { darken } from 'polished';

import { COLOR } from '../../../../common/const';
import ButtonBase from './ButtonBase';

const FeedbackButton = styled(ButtonBase)`
  color: ${COLOR.WHITE};
  background-color: ${({ theme }) => theme.colors.warnings.main};
  border: none;
  position: fixed;
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: translateY(-75%) rotate(270deg);
  transform-origin: right bottom;
  right: 8px;
  bottom: 50vh;
  border-radius: 2px;
  cursor: pointer;
  padding: 0.5rem;
  width: 180px;

  @media (min-width: 576px) {
  }

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.colors.warnings.main)};
  }

  &:active {
    background-color: ${({ theme }) => darken(0.1, theme.colors.warnings.main)};
  }
`;

FeedbackButton.propTypes = {
  disabled: PropTypes.bool,
};

export default FeedbackButton;
