import apiClient from '../api'

const GET_TEST_RESULTS = 'GET_TEST_RESULTS'
const GET_USER_STAT = 'GET_USER_STAT'
const GET_USER_QUESTION_STAT = 'GET_USER_QUESTION_STAT'

// Currently, nobody is using this. Profile view uses apiClient directly.
export function getUserStat() {
    return dispatch => {
        apiClient.stat.getUserStat()
            .then(stat => {
                dispatch({
                    type: GET_USER_STAT,
                    stat: stat
                })
            })
    }
}

export function getUserQuestionStat() {
    return dispatch => {
        apiClient.stat.getUserQuestionStat()
            .then(stat => {
                dispatch({
                    type: GET_USER_QUESTION_STAT,
                    stat: stat
                })
            })
    }
}

export function getTestResults() {
    return dispatch => {
        apiClient.stat.getTestResults()
            .then(results => {
                dispatch({
                    type: GET_TEST_RESULTS,
                    results: results
                })
            })
    }
}

const ACTION_HANDLERS = {
    [GET_USER_STAT]: handleGetUserStat,
    [GET_USER_QUESTION_STAT]: handleGetUserQuestionStat,
    [GET_TEST_RESULTS]: handleGetTestResults,
}

function handleGetUserStat(state, action) {
    return { ...state, userStat: action.stat }
}

function handleGetUserQuestionStat(state, action) {
    return { ...state, userQuestionStat: action.stat }
}

function handleGetTestResults(state, action) {
    return { ...state, testResults: action.results }
}

const INITIAL_STATE = {
    userStat: null,
    userQuestionStat: null,
    testResults: null,
}

export default function(state = INITIAL_STATE, action) {
    const handler = ACTION_HANDLERS[action.type]
    return handler ? handler(state, action) : state
}