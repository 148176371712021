import React from 'react';
import styled from 'styled-components';

import { Text, Image, RouterLink } from '../../base';

const NavLogo = () => {
  return (
    <RouterLink to={'/'}>
      <Image src="/img/mm-logo.png" height={'42px'} />
    </RouterLink>
  );
};

export default NavLogo;
