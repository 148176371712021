import React, { Component } from 'react';
import styled from 'styled-components'
import { Link } from 'react-router';

import apiClient from '../api'
import MESSAGES from '../common/messages'
import Card from '../components/base/styled/Card'
import Button from '../components/base/styled/Button'
import Text, { Title } from '../components/base/styled/Text'
import Flex from '../components/base/styled/Flex';

class ResetPasswordView extends Component {
  state = {
    codeValid: null,
    success: false,
    pending: false,
    error: null
  }

  componentWillMount() {
    const code = this.props.location.query.code;
    if (!code) {
      this.setState({ codeValid: false });
    } else {
      apiClient.student
        .checkResetPasswordCode(code)
        .then(res => {
          this.setState({ codeValid: true });
        })
        .catch(err => {
          this.setState({ codeValid: false });
        });
    }
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ pending: true })
    const code = this.props.location.query.code
    const newPassword  = this.refs.password.value
    apiClient.student.resetPassword(newPassword, code)
      .then(res=>{
        if (res.errorCode === 'invalid-new-password') {
          this.setState({ pending: false, error: MESSAGES.PASSWORD_TOO_SHORT })
        } else if (res.errorCode) {
          this.setState({ pending: false, error: MESSAGES.INTERNAL_SERVER_ERROR })
        } else {
          this.setState({ success: true, pending: false })
        }
      }).catch(err => {
        console(err)
        this.setState({ pending: false, error:  MESSAGES.INTERNAL_SERVER_ERROR })
      })
      .catch(err => {
        console(err);
      });
  };

  checkRePassword = () => {
    if (this.refs.repassword.value !== this.refs.password.value) {
      this.refs.repassword.setCustomValidity(MESSAGES.PASSWORDS_NOT_MATCH);
    } else {
      // Once the password match, CustomValidity must be set back to "".
      // Otherwise, the error will still shows.
      this.refs.repassword.setCustomValidity('');
    }
  };

  render() {
    const { codeValid, success } = this.state;
    if (codeValid === null) {
      return null;
    }

    return <Flex center>
      {success && (
        <_Card>
        <Title className="text-center hp-mb-25">แก้ไขรหัสผ่านสำเร็จ</Title>
        <Text className="hp-mb-15">รหัสผ่านของคุณได้ถูกแก้ไขเรียบร้อยแล้ว</Text>
        <Link to="/login" className="text-left btn-link">
          <Button type="submit" style={{ width: '100%', maxWidth: '16rem' }}>
            กลับไปหน้าเข้าสู่ระบบ
          </Button>
        </Link>
        </_Card>
      )}
      {!success && codeValid && (
        <_Card>
        <Title className="text-center hp-mb-25">เปลี่ยนรหัสผ่าน</Title>
        <form onSubmit={this.handleSubmit} className={'w-100'}>
          <Text className="hp-mb-15 text-center">กรุณาใส่รหัสผ่านใหม่ที่คุณต้องการ</Text>
          <Flex col alignCenter className={'w-100'}>
            {this.state.error
              ? <div className="c5 red text-center hp-mb-05">
                {this.state.error}
              </div>
              : null
            }
            <label className="w-100 hp-mb-1">
              รหัสผ่านใหม่
              <input
                type="password"
                placeholder="รหัสผ่านใหม่"
                className="form-control"
                ref="password"
                required
              />
            </label>
            <label className="w-100 hp-mb-15">
              ยืนยันรหัสผ่านใหม่อีกครั้ง
              <input
                type="password"
                placeholder="ยืนยันรหัสผ่านใหม่อีกครั้ง"
                className="form-control"
                onChange={() => {
                  this.checkRePassword();
                }}
                ref="repassword"
                required
              />
            </label>
            <Button type="submit" style={{ width: '100%', maxWidth: '16rem' }}>
              ยืนยัน
            </Button>
          </Flex>
        </form>
        </_Card>
      )}
      {!success && !codeValid && (
        <_Card>
        <Title className="text-center hp-mb-25">ลิงก์ไม่ถูกต้อง</Title>
        <Text className="hp-mb-15">ลิงก์สำหรับแก้ไขรหัสผ่านไม่ถูกต้องหรือหมดอายุแล้ว</Text>
        <Link to="/login">
          <Button type="submit" style={{ width: '100%', maxWidth: '16rem' }}>
            กลับไปหน้าเข้าสู่ระบบ
          </Button>
        </Link>
        </_Card>
      )}
    </Flex>
  }
}

export default ResetPasswordView;

const _Card = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 3rem;
  @media (min-width: 992px) {
    width: 480px;
  }
`;
