import { SET_SESSION_INFO, START_SESSION, KILL_SESSION, GET_PROBLEM, SUBMIT_RESPONSE, SELECT_CHOICE, SUBMIT_TEST, POST_SESSION_RESULT, SET_PROBLEM_INDEX, GO_TO_SOLUTION, RANDOM_TEST_ANSWERS, START_SOLUTION_SESSION } from './actionTypes'
import { getRequest, postRequest, } from './index'
import { SOLVE_MODE } from '../common/const'
import { store } from '../index'
import { getUserQuestionStat } from '../reducers/stat'

export function getProblem(questionId, index) {
    // TODO: figure out why this one failed when missing trailing slash.
    //const request = axios.get(`${BASE_API_URL}/problems/${questionId}`, {headers: {'Authorization': jwt}});
    return {
        type: GET_PROBLEM,
        payload: getRequest(`questions/${questionId}/`),
        meta: { 
            index: index
        }
    };
}

export function randomTestAnswers() {
    const session = store.getState().data.session
    const unpulledProblems = session.problems.filter(p => !p.choices)

    const promises = unpulledProblems.map(p => {
        return getRequest(`questions/${p.questionId}/`)
            .then(res => res.data)
    })

    return dispatch => {
        Promise.all(promises)
            .then(problems => {
                problems.forEach(p => {
                    const problem = session.problems.find(v => v.questionId === p.id)
                    problem.questionText = p.text
                    problem.choices = p.choice
                    problem.type = p.type
                    problem.difficultyId = p.difficulty
                    problem.topicIds = p.category
                    problem.testName = p.exam[0]
                })
            })
            .then(() => {
                dispatch({
                    type: RANDOM_TEST_ANSWERS,
                    session: session
                })

            })
    }
}

export function goToSolution() {
    return {
        type: GO_TO_SOLUTION,
    }
}

export function setProblemIndex(index) {
    return {
        type: SET_PROBLEM_INDEX,
        index: index
    }
}

export function selectChoice(problemIndex, answerId) {
    return {
        type: SELECT_CHOICE,
        problemIndex: problemIndex,
        answerId: answerId
    };
}

export function submitResponse(data, timespent) {
    return {
        type: SUBMIT_RESPONSE,
        payload: postRequest('submit-response', data),
        meta: {
            index: data.index,
            timespent: timespent
        }
    };
}

export function submitTest(data, sessionId, timespent) {
    // TODO: This looks like a hack ... Try to clean this later.
    return (dispatch) => {
        postRequest('submit-test', data)
            .then((response) => {
                dispatch({
                    type: SUBMIT_TEST,
                    payload: response,
                    meta: {
                        timespent: timespent
                    }
                })
            })
            .then(() => {
                dispatch(postSessionResult(sessionId, timespent))
            })
    }
}

export function postSessionResult(sessionId, timespent) {
    const data = {
        timespent: timespent == null ? 0 : timespent
    }

    return (dispatch) => {
        postRequest(`session-result/${sessionId}`, data)
        .then((response) => {
            dispatch({
                type: POST_SESSION_RESULT,
                payload: response
            })
        })
        .then(() => {
            dispatch(getUserQuestionStat())
        })
    }
}

export function startExercise(topicId, difficultyId, questionCount, sessionTitle, exerciseId) {
    var data = {
        "topicId": topicId,
        "difficultyId": difficultyId,
        "questionCount": questionCount,
        "exerciseId": exerciseId,
        // "tag": 'onet'
    }
    return {
        type: START_SESSION,
        payload: postRequest('drills', data),
        meta: {
            title: sessionTitle,
            mode: SOLVE_MODE.EXERCISE
        }
    }
}

export function startTest(testId, sessionTitle) {
    return {
        type: START_SESSION,
        payload: postRequest('action/start-test', { "testId": testId }),
        meta: {
            title: sessionTitle,
            mode: SOLVE_MODE.TEST_TIMER
        }
    }
}

export function startSolutionSession(sessionId, title, questions) {
    return {
        type: START_SOLUTION_SESSION,
        sessionId,
        title,
        questions
    }
}

export function killSession() {
    return {
        type: KILL_SESSION
    }
}

export function setSessionInfo(sessionInfo) {
    return {
        type: SET_SESSION_INFO,
        payload: sessionInfo
    }
}
