import React, { Component } from 'react'
import PropTypes from 'prop-types'

import ProblemBubble from './ProblemBubble'
import ActionIcon from '../../base/styled/Icon/ActionIcon'
import { BUBBLE_STATUS } from '../../common/Bubble'

const ProblemBubbleList = ({ problems, index, setProblemIndex }) => {
    return (
        <div className="row no-gutters w-100">
            { problems.map((p, pindex) => {
                return <div key={ pindex } className="col-2 col-xl-1">
                    <ProblemBubble 
                        index={ pindex }
                        current={ index === pindex }
                        status={ getStatus(p, index === pindex) } 
                        setProblemIndex={ setProblemIndex }
                    />
                </div>
            })} 
        </div>
    )
}

ProblemBubbleList.propTypes = {
    problems: PropTypes.array,
    index: PropTypes.number,
    setProblemIndex: PropTypes.func
}

export default ProblemBubbleList

class ToggleableProblemBubbleList extends Component {
    constructor(props) {
        super(props)
        this.state = {
            hidden: true,
            bubblePerRow: null,
        }
    }

    componentWillMount() {
        this._updateBubblePerRow()
    }

    componentDidMount() {
        window.addEventListener('resize', this._updateBubblePerRow)
    }

    _updateBubblePerRow = () => {
        const width = window.innerWidth
        const rempx = width < 576 ? 14 : 16
        const bubblePerRow = Math.floor((width - 3 * rempx) * (11 / 12) / 48)
        this.setState({
            bubblePerRow
        })
    }

    toggleHidden = () => {
        this.setState({
            hidden: !this.state.hidden
        })
    }

    render() {
        const { problems, index, setProblemIndex } = this.props
        const filteredProblems = this.state.hidden
            ? problems.slice(0, this.state.bubblePerRow)
            : problems

        return (
            <div className="row no-gutters w-100">
                <div className="col-11">
                    <div className="row no-gutters ">
                        { filteredProblems.map((p, pindex) => {
                            return <div key={ pindex } className="col-auto col-xl-1">
                                <ProblemBubble 
                                    index={ pindex }
                                    current={ index === pindex }
                                    status={ getStatus(p, index === pindex) } 
                                    setProblemIndex={ setProblemIndex }
                                />
                            </div>
                        })} 
                    </div>
                </div>
                <div className="col-1 d-flex flex-row align-items-start justify-content-end">
                    <ActionIcon size="1.5rem" className={ `fa ${this.state.hidden ? 'fa-angle-down' : 'fa-angle-up'} hp-mt-15 hp-px-02` } onClick={ this.toggleHidden } /> 
                </div>
            </div>
        )
    }
}

export {
    ToggleableProblemBubbleList
}

// Status -> selected, completed, correct, wrong
function getStatus(problem, current) {
    if (problem.completed) {
        return problem.result.isCorrect 
            ? BUBBLE_STATUS.CORRECT
            : BUBBLE_STATUS.WRONG
    }

    return problem.selectedChoiceId
        ? BUBBLE_STATUS.ENABLED 
        : BUBBLE_STATUS.DISABLED
}
